import React, { useEffect } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { useUpdateState } from "../../../../base/hooks/useUpdateState";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import BookkeepingService from "../../../../services/BookkeepingService";

import BookkeepingHeader from "../../components/BookkeepingHeader";
import BookkeepingSettings from "../../components/BookkeepingSettings";
import BookkeepingTables from "../../components/BookkeepingTables";

import {
  ACCOUNTING_METHODS,
  MOMENT_MONTH,
  REPORT_PERIOD_OPTIONS_IDS,
  MOMENT_YEAR,
  MOMENT_QUARTER,
  DISPLAY_COLUMN_STATUSES,
  MOMENT_QUARTERS,
  BOOKKEEPING_DATE_FORMAT,
} from "../../constants";

import { MOMENT_DAY } from "../../../expenses/constants";

const BookkeepingList = () => {
  const reportPeriodValuesMap = {
    [REPORT_PERIOD_OPTIONS_IDS.today]: {
      start: moment().startOf(MOMENT_DAY).format(),
      end: moment().endOf(MOMENT_DAY).format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.thisWeek]: {
      start: moment().subtract(7, MOMENT_DAY).startOf(MOMENT_DAY).format(),
      end: moment().format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.thisMonth]: {
      start: moment().startOf(MOMENT_MONTH).format(),
      end: moment().endOf(MOMENT_MONTH).format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.thisYear]: {
      start: moment().subtract(1, MOMENT_YEAR).startOf(MOMENT_DAY).format(),
      end: moment().format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.thisYearToDate]: {
      start: moment().startOf(MOMENT_YEAR).format(),
      end: moment().format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.quarter1]: {
      start: moment()
        .quarter(MOMENT_QUARTERS.firstQuarter)
        .startOf(MOMENT_QUARTER)
        .format(),
      end: moment()
        .quarter(MOMENT_QUARTERS.firstQuarter)
        .endOf(MOMENT_QUARTER)
        .format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.quarter2]: {
      start: moment()
        .quarter(MOMENT_QUARTERS.secondQuarter)
        .startOf(MOMENT_QUARTER)
        .format(),
      end: moment()
        .quarter(MOMENT_QUARTERS.secondQuarter)
        .endOf(MOMENT_QUARTER)
        .format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.quarter3]: {
      start: moment()
        .quarter(MOMENT_QUARTERS.thirdQuarter)
        .startOf(MOMENT_QUARTER)
        .format(),
      end: moment()
        .quarter(MOMENT_QUARTERS.thirdQuarter)
        .endOf(MOMENT_QUARTER)
        .format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.quarter4]: {
      start: moment()
        .quarter(MOMENT_QUARTERS.fourthQuarter)
        .startOf(MOMENT_QUARTER)
        .format(),
      end: moment()
        .quarter(MOMENT_QUARTERS.fourthQuarter)
        .endOf(MOMENT_QUARTER)
        .format(),
    },
    [REPORT_PERIOD_OPTIONS_IDS.custom]: {
      start: moment().startOf(MOMENT_YEAR).format(),
      end: moment().format(),
    },
  };

  const INIT_SETTINGS_STATE_VALUES = {
    reportPeriod: REPORT_PERIOD_OPTIONS_IDS.thisYearToDate,
    dateRange: reportPeriodValuesMap[REPORT_PERIOD_OPTIONS_IDS.thisYearToDate],
    displayColumnView: DISPLAY_COLUMN_STATUSES.totalOnly,
    accountingMethod: ACCOUNTING_METHODS.accrual,
  };

  const [bookkeepingSettings, setBookkeepingSettings] = useUpdateState(
    INIT_SETTINGS_STATE_VALUES
  );
  const [bookkeepingData, setBookkeepingData] = useUpdateState({});

  /**
   * @type {BookkeepingService}
   */
  const bookkeepingService = useService(BookkeepingService);
  const [isLoading, { registerPromise }] = useLoading();

  useEffect(() => {
    setBookkeepingSettings({
      dateRange: reportPeriodValuesMap[bookkeepingSettings.reportPeriod],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookkeepingSettings.reportPeriod]);

  useEffect(() => {
    const formattedParams = {
      startDate: bookkeepingSettings.dateRange.start,
      endDate: bookkeepingSettings.dateRange.end,
      accountingMethod: bookkeepingSettings.accountingMethod,
      groupBy: bookkeepingSettings.displayColumnView,
    };

    registerPromise(bookkeepingService.getBookkeeping(formattedParams))
      .then(({ data }) => setBookkeepingData(data))
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookkeepingSettings.dateRange,
    bookkeepingSettings.accountingMethod,
    bookkeepingSettings.displayColumnView,
    registerPromise,
    bookkeepingService,
  ]);

  return (
    <div className="mt-3">
      <Card>
        <CardBody>
          {isLoading ? (
            <div className="d-flex justify-content-center mb-3">
              <Spinner
                style={{
                  width: "48px",
                  height: "48px",
                  color: "rgba(47, 102, 92, 1)",
                }}
              />
            </div>
          ) : (
            <>
              <BookkeepingHeader
                title="Profit and Loss"
                dateRange={{
                  start: moment(bookkeepingSettings.dateRange.start).format(
                    BOOKKEEPING_DATE_FORMAT
                  ),
                  end: moment(bookkeepingSettings.dateRange.end).format(
                    BOOKKEEPING_DATE_FORMAT
                  ),
                }}
              />
              <BookkeepingSettings
                settings={bookkeepingSettings}
                setSettings={setBookkeepingSettings}
              />
              <BookkeepingTables
                bookkeepingData={bookkeepingData}
                settings={bookkeepingSettings}
              />
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default BookkeepingList;
