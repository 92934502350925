import {TYPES} from "../../../../../../../base/components/Subscription/constant/subscription";

export const EMPLOYEE_STATUSES = {
  inProgress: { code: 1, label: "Pending" },
  active: { code: 2, label: "Active" },
  blocked: { code: 3, label: "Blocked" },
  failed: { code: 4, label: "Failed" },
};

export const SUBSCRIPTIONS_STATUSES = {
  trial: TYPES.TRIAL,
  standard: TYPES.STARTER,
  business: TYPES.BUSINESS,
  unlimited: TYPES.UNLIMITED,
  expired: TYPES.EXPIRED
};
