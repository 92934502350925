import { useEffect } from "react";

const AutoReloadMarketplace = ({ children, reload, onReload }) => {
  useEffect(() => {
    if (reload && onReload) setTimeout(onReload, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return children;
};

export default AutoReloadMarketplace;
