/* eslint-disable react/prop-types */
import styled from "styled-components";

import HeaderFormatter from "../../components/Table/HeaderFormatter";
import ProductPreview from "../../components/ProductPreview";
import { FormatDate, FormatPrice } from "../../helpers/format";
import React, { useCallback, useRef, useState } from "react";
import { ReactComponent as Copy } from "../../components/Icons/Copy.svg";
import { ReactComponent as Delete } from "../../components/Icons/Delete.svg";
import { ReactComponent as OpenTab } from "../../components/Icons/open-tab.svg";
import IconButton from "../../components/IconButton";
import { Tooltip } from "reactstrap";
import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import { TableContext } from "../../contexts/table";
import WithHighlight from "../../components/WithHighlight";
import { useProfile } from "../../../profile/hooks/useProfile";
import { SUBSCRIPTIONS_STATUSES } from "../Settings/page/EmployeeManagement/constants";
import { DRAFT_FIELDS, MAX_DRAFT_COUNT } from "../../constants";
import { getTableFieldValue } from "../../helpers/getTableFieldValue";

const StyledOpenTabIcon = styled(OpenTab)`
  width: 16px;
  width: 16px;
`;

export const IsAcceptCopy = ({ children }) => {
  const [{ activeSubscription: { type } = {} }] = useProfile();
  const [{ used }] = useStatelessProvider(TableContext);

  const isShowCopyBtn = {
    [SUBSCRIPTIONS_STATUSES.trial]: true,
    [SUBSCRIPTIONS_STATUSES.standard]: used < MAX_DRAFT_COUNT.standard,
    [SUBSCRIPTIONS_STATUSES.business]: used < MAX_DRAFT_COUNT.business,
    [SUBSCRIPTIONS_STATUSES.unlimited]: true,
    [SUBSCRIPTIONS_STATUSES.expired]: false,
  };

  return <>{isShowCopyBtn[type] && children}</>;
};

const useTooltipProps = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};

export const ActionDelete = ({ id }) => {
  const ref = useRef();
  const [{ onDelete }] = useStatelessProvider(TableContext);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <>
      <IconButton ref={ref} onClick={handleDelete}>
        <Delete />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Delete
      </Tooltip>
    </>
  );
};

export const ActionOpenInTab = ({ data }) => {
  const {
    id,
    productEbaySpecificFields,
    productPoshmarkSpecificFields,
    productEtsySpecificFields,
    productMercariSpecificFields,
  } = data;
  const ref = useRef();
  let marketplace = "base";

  if (productEbaySpecificFields?.listingId) {
    marketplace = "ebay";
  } else if (productPoshmarkSpecificFields?.listingId) {
    marketplace = "poshmark";
  } else if (productEtsySpecificFields?.listingId) {
    marketplace = "etsy";
  } else if (productMercariSpecificFields?.listingId) {
    marketplace = "mercari";
  }

  return (
    <a
      href={`/app/inventories/${id}/view/${marketplace}`}
      rel="noreferrer"
      target="_blank"
    >
      <IconButton ref={ref}>
        <StyledOpenTabIcon />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Open in a new tab
      </Tooltip>
    </a>
  );
};

export const ActionCopy = ({ id }) => {
  const ref = useRef();
  const [{ onCopy }] = useStatelessProvider(TableContext);

  const handleCopy = useCallback(() => {
    onCopy(id);
  }, [id, onCopy]);

  return (
    <>
      <IconButton ref={ref} onClick={handleCopy}>
        <Copy />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Copy
      </Tooltip>
    </>
  );
};

export function TableStructure() {
  return [
    {
      text: "Title",
      style: {
        width: "31%",
      },
      headerFormatter: HeaderFormatter,
      formatter: (_, model) => {
        const titleValue = getTableFieldValue(model, DRAFT_FIELDS.TITLE, "-");
        return (
          <div className={"drafts-table-cell-title"}>
            <ProductPreview src={model.files[0]?.link} />
            <WithHighlight text={titleValue || "-"} />
          </div>
        );
      },
      sort: true,
      dataField: "title",
    },
    {
      sort: true,
      headerFormatter: HeaderFormatter,
      text: "SKU",
      formatter: (_, model) => {
        const skuValue = getTableFieldValue(model, DRAFT_FIELDS.SKU, "-");
        return (
          <WithHighlight
            text={skuValue || "-"}
            className="cell-semibold"
            exact
          />
        );
      },
      dataField: "sku",
    },
    {
      dataField: "listingPrice",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (_, model) => {
        const priceValue = getTableFieldValue(model, DRAFT_FIELDS.PRICE, 0);
        return FormatPrice(priceValue);
      },
      text: "Price",
    },
    {
      dataField: "createdAt",
      headerFormatter: HeaderFormatter,
      sort: true,
      style: {
        width: "15.5%",
      },
      text: "Creation date",
      formatter: (_, model) => {
        const dateValue = getTableFieldValue(
          model,
          DRAFT_FIELDS.CREATED_AT,
          new Date()
        );
        return FormatDate(dateValue);
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerAlign: "right",
      align: "right",
      style: {
        width: "14%",
      },
      formatter: (_, { id }) => {
        return (
          <div
            className={"table-actions"}
            onClick={(event) => event.stopPropagation()}
          >
            <IsAcceptCopy>
              <ActionCopy id={id} />
            </IsAcceptCopy>
            <ActionDelete id={id} />
          </div>
        );
      },
    },
  ];
}
