import * as yup from "yup";
import {
    PRICE_SOLD,
    RETURN_DESCRIPTION,
    RETURN_ITEM_COST,
    RETURN_MARKETPLACE_TYPE, RETURN_PERSONAL_NOTES,
    RETURN_REASON,
    RETURN_RECEIVED_AT,
    RETURN_RECEIVED_CONDITION,
    RETURN_RETURNED_AT, RETURN_SHIPPING_COST,
    RETURN_SOLD_AT, RETURN_STATUS,
    RETURN_TITLE, SHIPPING_CHARGED_TO_BUYER,
    SHIPPING_COST,
} from "../../../../validation/return";

export const validationSchema = yup.object().shape({
    title: RETURN_TITLE,
    description: RETURN_DESCRIPTION,
    itemCost: RETURN_ITEM_COST,
    shippingCost: SHIPPING_COST,
    shippingChargedToBuyer: SHIPPING_CHARGED_TO_BUYER,
    priceSold: PRICE_SOLD,
    soldAt: RETURN_SOLD_AT,
    marketplaceType: RETURN_MARKETPLACE_TYPE,
    returnedAt: RETURN_RETURNED_AT,
    returnReason: RETURN_REASON,
    receivedAt: RETURN_RECEIVED_AT,
    receivedCondition: RETURN_RECEIVED_CONDITION,
    returnShippingCost: RETURN_SHIPPING_COST,
    notes: RETURN_PERSONAL_NOTES,
    status: RETURN_STATUS
})