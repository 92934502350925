import React, { useState, useCallback, useMemo } from "react";

import Modal from "./components/Modal";
import SoldModal from "./components/SoldModal";

import ProductStatusService from "../../../../services/ProductStatusService";
import { useService } from "../../../../base/hooks/useService";

import {
  ACTION_DELIST_MARKETPLACES,
  ACTION_RELIST_MARKETPLACES,
  ACTION_SOLD_MARKETPLACES,
  MARKETPLACES,
  MARKETPLACES_NAMES,
  PRODUCT_STATUS_METHODS,
  SUCCESS_DELIST_MESSAGE,
  SUCCESS_RELIST_MESSAGE,
  SUCCESS_UNMERGE_MESSAGE,
  UNSUCCESS_DELIST_MESSAGE,
} from "../../constants";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useHistory } from "react-router-dom";
import { DRAFTS_GROUP_LINKS } from "../../config";
import { toNumber } from "../../../sold/helpers";
import { SOLD_GROUP_LINKS } from "../../../sold/config";
import ToasterService from "../../../../services/ToastService";
import { FORM_SPECIFIC_FIELDS_TYPES, FORM_TYPES } from "../../const/form";
import { MARKETPLACE_TYPES } from "./components/SoldModal/constants";
import { INVENTORY_STATUSES } from "../../../inventory/pages/EditInventory/constants";
import { getPostedMarketplacesViaExtension } from "../../../inventory/utils/getPostedMarketplacesViaExtension";
import Bugsnag from "@bugsnag/js";
import { phrases } from "../../../../store/phrases";

const RELIST_MESSAGE = (
  <>
    <p className="mb-0">
      Do you want to relist item at the chosen marketplace?
    </p>
    <p className="mt-1 mb-0 font-size-10 text-gray-gomi">
      Relist option will automatically delist the current item and post the same
      as a new one. It helps to promote long-not-for-sale items.
    </p>
  </>
);

const UNMERGE_MESSAGE = {
  title: "Unmerge item",
  message:
    "Are you sure you want to unmerge this listing? It will be transferred to a separate listing.",
};

const MODAL_STRINGS = {
  ebay: {
    [PRODUCT_STATUS_METHODS.relist]: {
      title: "Relist item",
      message: RELIST_MESSAGE,
    },
    [PRODUCT_STATUS_METHODS.sold]: {
      title: "",
      message: "",
    },
    [PRODUCT_STATUS_METHODS.delist]: {
      title: "Delist item",
      message: "Do you want to delist the item from eBay?",
    },
    [PRODUCT_STATUS_METHODS.unmerge]: UNMERGE_MESSAGE,
  },
  etsy: {
    [PRODUCT_STATUS_METHODS.relist]: {
      title: "Relist item",
      message: RELIST_MESSAGE,
    },
    [PRODUCT_STATUS_METHODS.sold]: {
      title: "",
      message: "",
    },
    [PRODUCT_STATUS_METHODS.delist]: {
      title: "Delist item",
      message: "Do you want to delist the item from Etsy?",
    },
    [PRODUCT_STATUS_METHODS.unmerge]: UNMERGE_MESSAGE,
  },
  poshmark: {
    [PRODUCT_STATUS_METHODS.relist]: {
      title: "Relist item",
      message: RELIST_MESSAGE,
    },
    [PRODUCT_STATUS_METHODS.sold]: {
      title: "",
      message: "",
    },
    [PRODUCT_STATUS_METHODS.delist]: {
      title: "Delist item",
      message: "Do you want to delist the item from Poshmark?",
    },
    [PRODUCT_STATUS_METHODS.unmerge]: UNMERGE_MESSAGE,
  },
  mercari: {
    [PRODUCT_STATUS_METHODS.relist]: {
      title: "Relist item",
      message: RELIST_MESSAGE,
    },
    [PRODUCT_STATUS_METHODS.sold]: {
      title: "",
      message: "",
    },
    [PRODUCT_STATUS_METHODS.delist]: {
      title: "Delist item",
      message: "Do you want to delist the item from Mercari?",
    },
    [PRODUCT_STATUS_METHODS.unmerge]: UNMERGE_MESSAGE,
  },
};

export const useDelistAction = ({
  onDelist = () => {},
  onToggleStatus = () => {},
  methodsMap = ACTION_DELIST_MARKETPLACES,
  isRedirect = true,
} = {}) => {
  const history = useHistory();
  /**
   * @type {ProductStatusService}
   */

  const productStatusService = useService(ProductStatusService);
  /**
   * @type {ToasterService}
   */

  const toastr = useService(ToasterService);

  const navigateToDraftAfterDelist = useCallback(
    ({ draftId, marketplace, countOfActiveListing }) => {
      if (countOfActiveListing > 1) {
        onToggleStatus(marketplace, INVENTORY_STATUSES.delisted);
        // update current status
        return;
      }

      history.push(DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(":draftId", draftId));
    },
    [history, onToggleStatus]
  );

  const delist = useCallback(
    ({
      draftId,
      marketplace,
      listingId,
      fetchedData,
      formValues,
      countOfActiveListing,
    }) => {
      const extensionCall = methodsMap[marketplace];

      let showNotification = () => toastr.success(SUCCESS_DELIST_MESSAGE);

      return extensionCall(
        draftId,
        marketplace,
        listingId,
        fetchedData,
        formValues
      )
        .then(() => productStatusService.delist(draftId, marketplace))
        .then(({ data }) => {
          if (data?.isAlreadyRemoved) return Promise.reject("isAlreadyRemoved");

          onDelist();

          showNotification = () => toastr.success(SUCCESS_DELIST_MESSAGE);

          if (isRedirect) {
            navigateToDraftAfterDelist({
              draftId,
              marketplace,
              listingId,
              countOfActiveListing,
            });
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
          // catch productStatusService.delist
          showNotification = () => toastr.error(UNSUCCESS_DELIST_MESSAGE);
          throw err;
        })
        .finally(() => showNotification());
    },
    [
      methodsMap,
      toastr,
      productStatusService,
      onDelist,
      isRedirect,
      navigateToDraftAfterDelist,
    ]
  );

  return { delist };
};

export const useProductStatus = ({
  marketplace = "",
  id = null,
  soldValues,
  listingId = null,
  onHandleDelete = () => {},
  fetchedData = {},
  formValues = {},
  countOfActiveListing = 0,
  toggleStatus = () => {},
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [statusMethod, setStatusMethod] = useState(() => {});
  const [statusMethodName, setStatusMethodName] = useState("");
  const [modalStrings, setModalStrings] = useState({});

  const history = useHistory();
  const [processing, { registerPromise }] = useLoading();

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  /**
   * @type {ProductStatusService}
   */
  const productStatusService = useService(ProductStatusService);

  /**
   * @type {ToasterService}
   */
  const toaster = useService(ToasterService);

  const navigateToDraftAfterDelist = useCallback(() => {
    onHandleDelete();

    if (countOfActiveListing > 1) {
      toggleStatus(marketplace, INVENTORY_STATUSES.delisted);
      // update current status
      return;
    }

    history.push(DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(":draftId", id));
  }, [
    history,
    countOfActiveListing,
    id,
    onHandleDelete,
    toggleStatus,
    marketplace,
  ]);

  const handleDelist = useCallback(
    (error, formattedData, delistMarketplacesViaExtension) => {
      const isPoshmark = history.location.pathname.includes(
        FORM_TYPES.POSHMARK
      );

      const isMercari = history.location.pathname.includes(FORM_TYPES.MERCARI);

      if (error && (isPoshmark || isMercari)) {
        if (
          (isMercari &&
            error.message.indexOf(
              "Sorry, someone has already purchased this"
            ) !== -1) ||
          (isPoshmark &&
            error.message.errorType === "InsufficientPrivilegesError")
        ) {
          processSoldItem(formattedData, delistMarketplacesViaExtension);
          return;
        }

        toaster.error(UNSUCCESS_DELIST_MESSAGE);
        productStatusService
          .delist(id, marketplace)
          .then(() => {
            navigateToDraftAfterDelist();
          })
          .catch((e) => Bugsnag.notify(e));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      history.location.pathname,
      toaster,
      productStatusService,
      id,
      marketplace,
      navigateToDraftAfterDelist,
    ]
  );

  const onRelist = useCallback(() => {
    onCloseModal();
    onHandleDelete();
    const promise = ACTION_RELIST_MARKETPLACES[marketplace];
    registerPromise(
      promise(id, marketplace, listingId, fetchedData, formValues)
        .then((data) => {
          Bugsnag.leaveBreadcrumb("Mercari relist - saving relist info", data);
          productStatusService.relist(id, marketplace, data).then(() => {
            toaster.success(SUCCESS_RELIST_MESSAGE);
            window.location.reload();
          });
        })
        .catch((error) => {
          Bugsnag.notify(error);
          handleDelist(error);
        })
    );
  }, [
    onCloseModal,
    onHandleDelete,
    marketplace,
    registerPromise,
    id,
    listingId,
    fetchedData,
    formValues,
    productStatusService,
    toaster,
    handleDelist,
  ]);

  const { delist: delistOnMarkAsSold } = useDelistAction({ isRedirect: false });

  const onSold = useCallback(
    (formData) => {
      onCloseModal();

      const promise = ACTION_SOLD_MARKETPLACES[marketplace];
      const data = {
        productId: +id,
        priceSold: toNumber(formData.priceSold),
        itemCost: toNumber(formData.costOfGood),
        marketplaceFees: toNumber(formData.marketplaceFees),
        shippingExpenses: toNumber(formData.shippingCost),
        marketplaceType: formData.marketplaceType,
        paymentMethod:
          formData.marketplaceType !== MARKETPLACE_TYPES.etsy
            ? null
            : formData.paymentMethod,
        transactionFees: toNumber(formData.transactionFees),
        title: formData.title,
        sku: formData.sku,
        color: formData.color,
        buyer: formData.buyer,
        additionalSellingFees: toNumber(formData.additionalSellingFees),
        shippingChargedToBuyer: toNumber(formData.shippingChargedToBuyer),
      };

      const formattedData = Object.entries(data).filter((field) => !!field[1]);
      const postedMarketplaces = getPostedMarketplacesViaExtension(
        fetchedData,
        marketplace
      );

      const delistMarketplacesViaExtension = () =>
        postedMarketplaces.map((marketplaceName) =>
          Promise.resolve()
            .then(() =>
              delistOnMarkAsSold({
                draftId: id,
                marketplace: marketplaceName,
                listingId:
                  fetchedData[FORM_SPECIFIC_FIELDS_TYPES[marketplaceName]]
                    .listingId,
                fetchedData,
                formValues,
                countOfActiveListing: 1,
              })
            )
            .catch((e) => {
              Bugsnag.notify(e);
              toaster.error(
                `Something went wrong. Seems like item was deleted at ${MARKETPLACES_NAMES[marketplaceName]} marketplace`
              );
            })
        );

      registerPromise(
        promise({
          draftId: id,
          marketplace,
          listingId,
          fetchedData,
          formValues,
        })
          .then(() => {
            processSoldItem(formattedData, delistMarketplacesViaExtension);
          })
          .catch((error) => {
            Bugsnag.notify(error);
            handleDelist(error, formattedData, delistMarketplacesViaExtension);
          })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onCloseModal,
      marketplace,
      id,
      fetchedData,
      formValues,
      registerPromise,
      listingId,
      delistOnMarkAsSold,
      toaster,
      productStatusService,
      history,
      handleDelist,
    ]
  );

  const processSoldItem = (formattedData, delistMarketplacesViaExtension) => {
    productStatusService
      .sold(Object.fromEntries(formattedData))
      .then(() => Promise.all(delistMarketplacesViaExtension()))
      .then(() => history.push(SOLD_GROUP_LINKS.BASE))
      .catch((e) => Bugsnag.notify(e));
  };

  const { delist } = useDelistAction({
    onDelist: onHandleDelete,
    onToggleStatus: toggleStatus,
  });

  const onDelist = useCallback(() => {
    onCloseModal();

    toaster.progress(phrases.process);

    registerPromise(
      delist({
        draftId: id,
        marketplace,
        listingId,
        fetchedData,
        formValues,
        countOfActiveListing,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onCloseModal,
    registerPromise,
    delist,
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues,
    countOfActiveListing,
  ]);

  const onUnmerge = useCallback(() => {
    registerPromise(
      productStatusService
        .unmerge({
          splitFromId: id,
          marketplaceTypeToSplit: MARKETPLACES[marketplace],
        })
        .then(() => {
          onCloseModal();
          onHandleDelete();
          toaster.success(SUCCESS_UNMERGE_MESSAGE);
          window.location.reload();
        })
        .catch((baseError) => {
          const { error } = baseError;
          Bugsnag.notify(baseError);
          toaster.error(error?.message);
        })
    );
  }, [
    id,
    registerPromise,
    productStatusService,
    marketplace,
    onCloseModal,
    onHandleDelete,
    toaster,
  ]);

  const onClickMethods = useMemo(
    () => ({
      [PRODUCT_STATUS_METHODS.relist]: () => onRelist,
      [PRODUCT_STATUS_METHODS.sold]: () => onSold,
      [PRODUCT_STATUS_METHODS.delist]: () => onDelist,
      [PRODUCT_STATUS_METHODS.unmerge]: () => onUnmerge,
    }),
    [onRelist, onSold, onDelist, onUnmerge]
  );

  const onClick = useCallback(
    (methodType) => {
      setIsOpenModal(true);
      setModalStrings(MODAL_STRINGS[marketplace][methodType]);
      setStatusMethod(onClickMethods[methodType]);
      setStatusMethodName(methodType);
    },
    [marketplace, onClickMethods]
  );

  if (!marketplace) return [];

  return [
    statusMethodName === PRODUCT_STATUS_METHODS.sold ? (
      <SoldModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        title="Congratulations on selling your item!"
        onConfirm={onSold}
        processing={processing}
        soldValues={soldValues}
      />
    ) : (
      <Modal
        title={modalStrings?.title}
        message={modalStrings?.message}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        processing={processing}
        onConfirm={statusMethod}
        key={id}
      />
    ),
    onClick,
    processing,
  ];
};
