import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import ImportModal from "../../../../../base/components/ImportModal";

import DraftsService from "../../../../../services/DraftsService";
import { useService } from "../../../../../base/hooks/useService";
import ErrorMarketplaceConnection from "../EbayImportModal/ErrorEbayConnection";

import { useImportService } from "../EbayImportModal";
import { ImportPopupEtsyService } from "../../../services/index";

const EtsyImportModal = (props) => {
  const { data } = props;

  const [shopName, setShopName] = useState("Loading...");
  /**
   * @type {DraftsService}
   */
  const draftsService = useService(DraftsService);

  const loadData = useCallback(
    (pagination) => {
      return draftsService
        .getProductEtsy(pagination)
        .then(({ data, pagination, shopName }) => {
          setShopName(shopName);
          return { data, pagination, shopName };
        })
        .catch((e) => Bugsnag.notify(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draftsService, status]
  );

  const [service] = useImportService(ImportPopupEtsyService, loadData);

  if (!data) {
    return <ErrorMarketplaceConnection {...props} name={"Etsy"} />;
  }

  return (
    <ImportModal
      title="Import from Etsy"
      products={service.getVisibleItems()}
      isShopNameComponent
      shopName={shopName}
      service={service}
      {...props}
    />
  );
};

EtsyImportModal.propTypes = {
  data: PropTypes.any,
};

export default EtsyImportModal;
