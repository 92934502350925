import VerticalLayout from "../../base/components/VerticalLayout";
import { Route, Switch, Redirect } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Bugsnag from "@bugsnag/js";

import Profile from "../profile";
import Drafts from "../drafts";
import Inventory from "../inventory";

import ErrorBoundary from "../../base/components/ErrorBoundary";
import { PROFILE_GROUP_LINKS } from "../profile/config";
import {
  BUSINESS_TITLE,
  EMPLOYEE_RESTRICTED_ROUTES,
  sidebarSchema,
} from "./sidebarSchema";
import { DRAFTS_GROUP_LINKS } from "../drafts/config";
import { useLoadProfile } from "../profile/hooks/useProfile";
import Dashboard from "../dashboard";
import { DASHBOARD_GROUP_LINKS } from "../dashboard/config";
import { OAUTH_GROUP_LINKS } from "../oauth/config";
import OAuth from "../oauth";
import Settings from "../drafts/pages/Settings";
import { INVENTORY_GROUP_LINKS } from "../inventory/config";
import Subscription from "../../base/components/Subscription";
import SubscriptionChange from "../../base/components/Subscription/SubscriptionChange";
import { SOLD_GROUP_LINKS } from "../sold/config";
import Sold from "../sold";
import { RETURN_GROUP_LINKS } from "../returns/config";
import Returns from "../returns";
import { TASK_MANAGEMENT_GROUP_LINKS } from "../taskManagement/config";
import TaskManagementComponent from "../taskManagement";
import HelpPage from "../help";
import { HELP_GROUP_LINKS } from "../help/config";
import Expenses from "../expenses";
import { EXPENSES_GROUP_LINKS } from "../expenses/config";
import { ROLES } from "../auth/constants/roles";
import ShippingBase from "../shipping";
import { SHIPPING_GROUP_LINKS } from "../shipping/config";
import Analytics from "../analytics";
import { ANALYTICS_GROUP_LINKS } from "../analytics/config";
import BookkeepingPage from "../bookkeeping";
import { BOOKKEEPING_GROUP_LINKS } from "../bookkeeping/config";

import { SHIPPING_TASK_STATUSES } from "../shipping/constants";

import {
  changeSidebarPickingCounter,
  changeSidebarShippingCounter,
  changeSidebarTasksCounter,
} from "../../store/actions";
import { useService } from "../../base/hooks/useService";
import ShippingService from "../../services/ShippingService";
import TaskManagement from "../../services/TaskManagement";
import { useLoading } from "../../base/hooks/useLoading";
import StorageService from "../../services/StorageService";
import SessionStorage from "../../services/SessionStorage";
import { AUTH_GROUP_LINKS } from "../auth/config";
import ProgressBar from "../sold/components/ProgressBar";
import { useUpdateSoldItems } from "../sold/hooks/useUpdateSoldItems";
import { SoldContext } from "../sold/context/sold";
import { useImportProducts } from "../inventory/hooks/useImportProducts";
import { useMarkToReload } from "../../base/hooks/useMarkToReload";
import {
  ACTIVE_ITEMS_MARK_TO_RELOAD,
  SOLD_ITEMS_MARK_TO_RELOAD,
} from "../../base/constants/markToReload";
import { ImportContext } from "../context/import";
import ChangeSubscriptionEmployeeModal from "../../base/components/Subscription/components/ChangeSubscriptionEmployeeModal";
import { APP_GROUP_LINKS } from "./config";
import PreventClosePage from "../drafts/components/PreventClosePage";
import { SOLD_INVENTORY_LABEL } from "../inventory/constants";
import Logout from "../auth/pages/Logout";
import { useCheckIsNeedToLogout } from "../auth/hooks/useCheckIsNeedToLogout";
import { phrases } from "../../store/phrases";

export const LOAD_COUNTER_LIMIT = 1;
export const LOAD_COUNTER_TASKS_ROLE = 2;

const AppRoutes = () => {
  const { role } = useLoadProfile();

  const schema = useMemo(() => {
    if (role === ROLES.employee) {
      const filteredScheme = sidebarSchema.filter(
        (element) =>
          element.linkTo !== EMPLOYEE_RESTRICTED_ROUTES[element.linkTo] ||
          element.isTitle
      );
      const businessTitleIndex = filteredScheme.findIndex(
        (element) => element.label === BUSINESS_TITLE
      );
      filteredScheme.splice(businessTitleIndex, 1);
      return filteredScheme;
    } else {
      return sidebarSchema;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, sidebarSchema]);

  const dispatch = useDispatch();

  const [_, { registerPromise }] = useLoading();

  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);

  /**
   * @type {TaskManagement}
   */
  const taskManagementService = useService(TaskManagement);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {SessionStorage}
   */
  const sessionStorage = useService(SessionStorage);

  useEffect(() => {
    const getCounters = async () => {
      const {
        pagination: { totalCount: pickingCounter },
      } = await shippingService.getList({
        status: SHIPPING_TASK_STATUSES.PICKING,
        limit: LOAD_COUNTER_LIMIT,
      });

      const {
        pagination: { totalCount: shippingCounter },
      } = await shippingService.getList({
        status: SHIPPING_TASK_STATUSES.SHIPPING,
        limit: LOAD_COUNTER_LIMIT,
      });

      const {
        pagination: { totalCount: tasksCounter },
      } = await taskManagementService.getTasks({
        limit: LOAD_COUNTER_LIMIT,
        role: LOAD_COUNTER_TASKS_ROLE,
      });

      return { pickingCounter, shippingCounter, tasksCounter };
    };

    registerPromise(getCounters())
      .then(({ pickingCounter, shippingCounter, tasksCounter }) => {
        dispatch(changeSidebarPickingCounter(pickingCounter));
        dispatch(changeSidebarShippingCounter(shippingCounter));
        dispatch(changeSidebarTasksCounter(tasksCounter));
      })
      .catch((e) => Bugsnag.notify(e));
  }, [dispatch, shippingService, taskManagementService, registerPromise]);

  useEffect(() => {
    const KEY_SESSION = sessionStorage._getSessionKey();
    return storage.onChangeDataInSiblingTab(KEY_SESSION, (result) => {
      if (!result) {
        history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
      }
    });
  }, [storage, sessionStorage]);

  const { syncAll, isDownloadingItems, isSyncActive, loaderMessage } =
    useUpdateSoldItems();
  const {
    isLoading,
    importing,
    disable,
    resetCounts,
    startPreparing,
    endPreparing,
  } = useImportProducts();
  const { markUpdates } = useMarkToReload(SOLD_ITEMS_MARK_TO_RELOAD);
  const prevIsSyncValue = useRef(false);
  // complete SYNC logic -> mark changes -> reload sold items
  useEffect(() => {
    if (!isSyncActive && prevIsSyncValue.current) {
      markUpdates();
    }

    prevIsSyncValue.current = isSyncActive;
  }, [isSyncActive, prevIsSyncValue, markUpdates]);

  const { markUpdates: markImportingUpdates } = useMarkToReload(
    ACTIVE_ITEMS_MARK_TO_RELOAD
  );

  const syncData = useMemo(() => {
    return {
      syncAll: syncAll,
      isDownloadingItems: isDownloadingItems,
      isSyncActive: isSyncActive,
    };
  }, [isDownloadingItems, isSyncActive, syncAll]);

  const importingData = useMemo(() => {
    return {
      importing: importing,
      isLoading: isLoading,
      disable: disable,
      startPreparing,
      endPreparing,
    };
  }, [isLoading, importing, disable, startPreparing, endPreparing]);

  const importingSoldData = useMemo(() => {
    return {
      importing: importing,
      isLoading: isLoading,
      disable: disable,
      startPreparing,
      endPreparing,
      productStatus: SOLD_INVENTORY_LABEL,
    };
  }, [isLoading, importing, disable, startPreparing, endPreparing]);

  const [isShowUpdateBar, updateIsShowUpdateBar] = useState(false);
  const [isShowImportBar, updateIsShowImportBar] = useState(false);

  useEffect(() => {
    if (isDownloadingItems || isSyncActive) {
      updateIsShowUpdateBar(true);
    }
  }, [updateIsShowUpdateBar, isDownloadingItems, isSyncActive]);

  const onCloseImportProgressBar = useCallback(() => {
    updateIsShowImportBar(false);
  }, [updateIsShowImportBar]);

  useEffect(() => {
    if (isLoading) {
      updateIsShowImportBar(true);
    }
  }, [updateIsShowImportBar, isLoading]);

  const onCloseUpdateProgressBar = useCallback(() => {
    updateIsShowUpdateBar(false);
  }, [updateIsShowUpdateBar]);

  const onCompleteImport = useCallback(() => {
    resetCounts();
    markImportingUpdates();
  }, [resetCounts, markImportingUpdates]);

  return (
    <VerticalLayout schema={schema}>
      <PreventClosePage
        isBlocked={isLoading || isDownloadingItems || isSyncActive}
        allowPath={APP_GROUP_LINKS.BASE}
        message={"Are you sure you want to stop process?"}
      />
      <ErrorBoundary>
        <Switch>
          <Route path={PROFILE_GROUP_LINKS.BASE}>
            <Profile />
          </Route>
          <Route path={OAUTH_GROUP_LINKS.BASE}>
            <OAuth />
          </Route>
          <Route path={DASHBOARD_GROUP_LINKS.BASE}>
            <Dashboard />
          </Route>
          <Route path={DRAFTS_GROUP_LINKS.BASE}>
            <Drafts />
          </Route>
          <Route path={DRAFTS_GROUP_LINKS.SETTINGS}>
            <Settings />
          </Route>
          <Route path={INVENTORY_GROUP_LINKS.BASE}>
            <ImportContext.Provider value={importingData}>
              <Inventory />
            </ImportContext.Provider>
          </Route>
          <Route path={SOLD_GROUP_LINKS.BASE}>
            <ImportContext.Provider value={importingSoldData}>
              <SoldContext.Provider value={syncData}>
                <Sold />
              </SoldContext.Provider>
            </ImportContext.Provider>
          </Route>
          <Route path={RETURN_GROUP_LINKS.BASE}>
            <Returns />
          </Route>
          <Route path={TASK_MANAGEMENT_GROUP_LINKS.BASE}>
            <TaskManagementComponent />
          </Route>
          <Route path={HELP_GROUP_LINKS.BASE}>
            <HelpPage />
          </Route>
          {role !== ROLES.employee && (
            <Route path={ANALYTICS_GROUP_LINKS.BASE}>
              <Analytics />
            </Route>
          )}
          <Route path={EXPENSES_GROUP_LINKS.BASE}>
            <Expenses />
          </Route>
          <Route path={SHIPPING_GROUP_LINKS.base}>
            <ShippingBase />
          </Route>
          <Route path={BOOKKEEPING_GROUP_LINKS.BASE}>
            <BookkeepingPage />
          </Route>
          <Redirect to={PROFILE_GROUP_LINKS.BASE} />
        </Switch>

        <CheckSubscription role={role} />

        {isShowUpdateBar && (
          <ProgressBar
            isActive={isDownloadingItems || isSyncActive}
            processMessage={loaderMessage || phrases.updatingYourSales}
            completedMessage={phrases.updateComplete}
            onClose={onCloseUpdateProgressBar}
          />
        )}

        {isShowImportBar && (
          <ImportProgressBar
            isActive={isLoading}
            onComplete={onCompleteImport}
            onClose={onCloseImportProgressBar}
            isOffset={isShowUpdateBar}
          />
        )}
      </ErrorBoundary>
    </VerticalLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    counts: state.Imports,
  };
};

const CheckSubscription = connect(mapStateToProps)(({ counts, role }) => {
  const [isLogoutEmployee] = useCheckIsNeedToLogout();

  return (
    <>
      {role !== ROLES.employee && (
        <>
          <Subscription />
          <SubscriptionChange importedCount={counts.current} />
        </>
      )}
      {role === ROLES.employee && (
        <>
          {isLogoutEmployee && <Logout />}
          <ChangeSubscriptionEmployeeModal importedCount={counts.current} />
        </>
      )}
    </>
  );
});

const ImportProgressBar = connect(mapStateToProps)(
  ({ counts, isActive, onClose, isOffset, onComplete }) => {
    const { current, total } = counts;

    useEffect(() => {
      if (current === total && current) {
        onComplete();
      }
    }, [current, total, onComplete]);

    return (
      <ProgressBar
        isActive={isActive}
        processMessage={`${current} / ${total} imported`}
        completedMessage={"Importing is completed"}
        onClose={onClose}
        isOffset={isOffset}
      />
    );
  }
);

export default AppRoutes;
