import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import FormikInput from "../../../../../base/components/FormikInput";

import { MAX_LENGTH_SKU } from "../../../../../base/constants/forms";
import { EBAY_DRAFT_HIDDEN_VALIDATION } from "../../../constants";
import { useField } from "formik";
import {
  MAX_TITLE_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../../validation/lengthConstants";

const FormTitleAndSKU = ({
  titleLabel = "Title*",
  skuLabel = "SKU",
  titleMaxLength,
  isPosted = false,
}) => {
  const [{ value: titleValue }, , { setTouched: setTouchedTitle }] = useField({
    name: "title",
  });

  useEffect(() => {
    if (titleValue?.length <= titleMaxLength) return;
    setTouchedTitle(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikInput
        id="title"
        name="title"
        label={titleLabel}
        bottomText="Include details such as brand, color, size, specs,condition,etc."
        styles={{ width: "100%" }}
        containerClassName="mb-3"
        placeholder="Enter item title"
        type="text"
        isShowLengthDescription
        maxLength={MAX_TITLE_LENGTH}
        min={MIN_TITLE_LENGTH}
        maxLengthDescription={EBAY_DRAFT_HIDDEN_VALIDATION.title.maxValue}
      />

      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            id="sku"
            name="sku"
            label={skuLabel}
            containerClassName="mb-3"
            placeholder="Enter SKU number"
            type="text"
            maxLength={MAX_LENGTH_SKU}
          />
        </Col>
      </Row>
    </>
  );
};

FormTitleAndSKU.propTypes = {
  titleLabel: PropTypes.string,
  skuLabel: PropTypes.string,
  titleMaxLength: PropTypes.number,
  isPosted: PropTypes.bool,
};

export default FormTitleAndSKU;
