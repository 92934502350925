/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormikGroupAddon from "../../../GroupAddon";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import {
  INITIAL_DISCOUNT_OBJECT,
  MAX_LISTING_PRICE_FOR_DISCOUNT,
  STATUS_POSHMARK_NOT_CONNECTED,
  STATUS_POSHMARK_NOT_INSTALLED,
} from "../../../../const/poshmark";
import { useService } from "../../../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../../../services/PoshmarkExtension";

import "./index.scss";
import { usePriceValidation } from "../../../../../../base/hooks/usePriceValidation";
import { PRICE_STEP } from "../../../../../../base/constants/forms";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { StoredField } from "../../../../../../base/components/StoredField";

const FormPoshmarkPricing = ({ values, setFieldValue, status }) => {
  const [shippingDiscounts, updateShippingDiscounts] = useState([]);
  const [isDiscountShippingClicked, setIsDiscountShippingClicked] =
    useState(false);
  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);
  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  const handlesDiscountShippingClicked = () => {
    setIsDiscountShippingClicked(true);
  };

  const isNotActive = useMemo(() => {
    return (
      status === STATUS_POSHMARK_NOT_INSTALLED ||
      status === STATUS_POSHMARK_NOT_CONNECTED
    );
  }, [status]);

  const [loading, { registerPromise }] = useLoading();

  useEffect(() => {
    if (values.listingPrice) {
      registerPromise(
        poshmark.getSellerShippingDiscounts(values.listingPrice.toString())
      )
        .then((data) => {
          if (values.listingPrice >= MAX_LISTING_PRICE_FOR_DISCOUNT) {
            updateShippingDiscounts([INITIAL_DISCOUNT_OBJECT]);
            setFieldValue("discountedShipping", undefined);
          } else {
            updateShippingDiscounts([...data]);
          }
        })
        .catch((e) => Bugsnag.notify(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poshmark, values.listingPrice, updateShippingDiscounts, registerPromise]);

  const [loadingEarning, { registerPromise: awaitEarning }] = useLoading();

  useEffect(() => {
    const foundDiscountAmount = shippingDiscounts?.find(
      (discount) => discount.id === values.discountedShipping
    );
    const discountAmount = foundDiscountAmount?.discount_amount?.val;

    if (values.listingPrice) {
      awaitEarning(
        poshmark.getSellerEarnings(
          values.listingPrice.toString(),
          discountAmount
        )
      )
        .then((response) => {
          const { data = null } = response;
          setFieldValue("earning", data?.earnings_amount?.val || "");
        })
        .catch((e) => Bugsnag.notify(e));
    }
  }, [
    poshmark,
    awaitEarning,
    values.listingPrice,
    values.discountedShipping,
    shippingDiscounts,
    setFieldValue,
  ]);

  const isDisabledDiscount =
    values.listingPrice >= MAX_LISTING_PRICE_FOR_DISCOUNT;

  return (
    <Row className="section-wrapper">
      <Col md={4}>
        <h3 className="bold-black mb-1">Pricing</h3>
      </Col>
      <Col md={8}>
        <Row className="mb-3">
          <Col md={6}>
            <StoredField fieldKey="originalPrice" />
            <FormikGroupAddon
              label="Original price*"
              name="originalPrice"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              placeholder="00.00"
              step={PRICE_STEP}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <FormikGroupAddon
              label="Listing price*"
              name="listingPrice"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              description="Price you are selling your item"
              placeholder="00.00"
              isDiscountShippingClicked={isDiscountShippingClicked}
              step={PRICE_STEP}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} onClick={handlesDiscountShippingClicked}>
            <StoredField fieldKey="discountedShipping" />
            <FormikSingleSelect
              name="discountedShipping"
              label="Discount Shipping"
              options={useMemo(
                () => [...shippingDiscounts],
                [shippingDiscounts]
              )}
              loading={loading}
              disabled={isDisabledDiscount}
              customError={
                isDisabledDiscount && "Please enter listing price first"
              }
              subText={
                isNotActive &&
                "You need to have your poshmark connection active to get the options"
              }
              containerClassName="discountedShipping mb-3"
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <FormikGroupAddon
              label="Your Earnings"
              name="earning"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              loading={loadingEarning}
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              placeholder="00.00"
              readOnly
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormPoshmarkPricing;
