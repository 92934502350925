/* eslint-disable react/prop-types */
import Bugsnag from "@bugsnag/js";

import { MercariProductImages } from "../../hooks/useProductImages";
import Divider from "../Divider";
import { Col, Row } from "reactstrap";
import ShippingTemplate from "./partials/ShippingTemplate";
import FormikGroupAddon from "../GroupAddon";
import React, { useEffect, useState } from "react";
import ListingUrl from "../DraftEbayFormInner/partials/ListingUrl";
import Condition from "./partials/Condition";
import FormikInput from "../../../../base/components/FormikInput";
import Tags from "./partials/Tags";
import {
  MAX_LENGTH_SKU,
  STEP_BY_HUNDREDTH,
} from "../../../../base/constants/forms";
import "./index.scss";
import { Categories } from "./partials/Categories";
import { Brands } from "./partials/Brands.jsx";
import { ColorAndSizes } from "./partials/ColorAndSizes";
import { FormTitleAndDesc } from "./partials/FormTitleAndDesc";
import { CalcFees } from "./partials/CalcFees";
import { SmartPricing } from "./partials/SmartPricing";
import { formatInputPrice } from "../../helpers/formatInputPrice";
import { mapShippingToOption, useGetMercariCategories } from "./partials/hooks";
import { useField } from "formik";
import {
  MAX_DESCRIPTION_LENGTH_MERCARI,
  MAX_TITLE_LENGTH_MERCARI,
} from "../../const/mercari";
import { useService } from "../../../../base/hooks/useService.js";
import MercariExtension from "../../../../services/MercariExtension.js";
import { transformLbToOz } from "../../helpers/transformLbtoOz.js";

const DraftMercariFormInner = ({
  values,
  setFieldValue,
  postedInfo,
  formRef,
  errors,
  touched,
  suggestedFields,
  isLoading: setCategoriesLoading,
  initialValues,
}) => {
  const { colors, shippings, sizes, brands, categories, isLoading } =
    useGetMercariCategories();
  const [categoryShipping, setCategoryShipping] = useState([]);
  const [{ value: titleValue }, , { setTouched: setTouchedTitle }] = useField({
    name: "title",
  });
  const [{ value: descriptionValue }, , { setTouched: setTouchedDescription }] =
    useField({
      name: "description",
    });
  const [, , { setValue: setValueShippingPayer }] = useField({
    name: "shippingPayer",
  });
  const [, , { setValue: setValueCostOfItem }] = useField({
    name: "costOfItem",
  });
  const [, , { setValue: setValueListingPrice }] = useField({
    name: "listingPrice",
  });
  const [{ value: categoryId }] = useField({
    name: "categoryId",
  });

  const [{ value: weightLbValue }] = useField({ name: "weightLb" });
  const [{ value: weightOzValue }] = useField({ name: "weightOz" });

  useEffect(() => {
    if (titleValue?.length > MAX_TITLE_LENGTH_MERCARI) {
      setTouchedTitle(true, true);
    }

    if (descriptionValue?.length > MAX_DESCRIPTION_LENGTH_MERCARI) {
      setTouchedDescription(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    if (!values.shippingPayer) {
      setValueShippingPayer(null);
    }
    if (!values.costOfItem) {
      setValueCostOfItem(null);
    }
    if (!values.listingPrice) {
      setValueListingPrice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCategoriesLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const extension = useService(MercariExtension);

  useEffect(() => {
    const totalWeight =
      transformLbToOz(weightLbValue || 0) + (weightOzValue || 0);

    const getShippingData = async () => {
      await extension
        .getShippingClasses(categoryId, totalWeight)
        .then((response) => {
          const {
            data: {
              availableShippingClassesV2: { shippingClasses = [] },
            },
          } = response;

          const mappedShipping = shippingClasses.map((item) =>
            mapShippingToOption(item)
          );

          setCategoryShipping(mappedShipping);
        })
        .catch((e) => {
          Bugsnag.notify(e, (event) => {
            event.addMetadata("getShippingDataError", {
              categoryId,
              totalWeight,
            });
          });
        });
    };

    getShippingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, weightLbValue, weightOzValue]);

  return (
    <div className={"mercari-form"}>
      {/* All images */}
      <MercariProductImages />

      {/* Start from */}

      <h3 className="bold-black mb-4 mt-4">Product Info</h3>
      <Divider />

      <FormTitleAndDesc titleLabel={"Title*"} descLabel={"Description*"} />

      <Tags values={values} />

      <Categories
        categories={categories}
        topCategoryId={values.topCategoryId}
        nestedCategoryId={values.nestedCategoryId}
        categoryId={values.categoryId}
        setFieldValue={setFieldValue}
        loading={isLoading}
        errorMessage={touched.categoryId && errors.categoryId}
        suggested={suggestedFields.categoryName}
        dressOccasion={values.dressOccasion || initialValues.dressOccasion}
        dressStyle={values.dressStyle || initialValues.dressStyle}
      />

      <Brands
        brands={brands}
        loading={isLoading}
        suggested={suggestedFields.brand}
      />

      <Condition />

      <ColorAndSizes
        sizes={sizes}
        colors={colors}
        categoryId={values.categoryId}
        categories={categories}
        loading={isLoading}
      />

      <ShippingTemplate
        loading={isLoading}
        shippings={categoryShipping.length > 0 ? categoryShipping : shippings}
        categoryId={values.categoryId}
        title={values.title}
        description={values.description}
        brandId={values.brandId}
      />

      <h3 className="bold-black mb-4">Pricing</h3>
      <Divider />

      <Row className="mb-4">
        <Col md="6">
          <FormikGroupAddon
            label="Set Price*"
            name="listingPrice"
            groupText="$"
            onChange={({ target: { value } }) => {
              setFieldValue("listingPrice", formatInputPrice(value));
            }}
            placeholder={"00.00"}
            step={0.01}
          />

          <CalcFees
            from={"listingPrice"}
            shippingPayer={"shippingPayer"}
            zipCode={values.shipsFrom}
            floorPrice={values.floorPrice}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6">
          <FormikGroupAddon
            label="Cost of item"
            name="costOfItem"
            groupText="$"
            step={STEP_BY_HUNDREDTH}
            onChange={({ target: { value } }) => {
              setFieldValue("costOfItem", formatInputPrice(value));
            }}
            description={"For internal usage only"}
            placeholder={"00.00"}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            id="sku"
            name="sku"
            label="SKU"
            containerClassName="mb-3"
            placeholder="Enter SKU number"
            type="text"
            maxLength={MAX_LENGTH_SKU}
            isShowLengthDescription
            description={"For internal usage only"}
          />
        </Col>
      </Row>

      <SmartPricing
        setFieldValue={setFieldValue}
        floorPrice={values.floorPrice}
      />

      <ListingUrl
        values={values}
        postedInfo={postedInfo}
        setFieldValue={setFieldValue}
        isDisabledCopyBtn={formRef?.current?.errors?.link}
      />
    </div>
  );
};

export default DraftMercariFormInner;
