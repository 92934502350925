import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

import Checkbox from "./Checkbox";
import { useTriggerFormChanges } from "../../pages/drafts/hooks/useDetectDirtyForm";

const FormikCheckbox = ({
  text,
  name,
  disabled,
  className,
  onChange,
  ...props
}) => {
  const [field, , { setValue }] = useField({ name, type: "checkbox" });
  const { markChanges, containerRef } = useTriggerFormChanges();

  const handleChange = event => {
    markChanges();

    const func = onChange ? () => onChange(event) : () => setValue(!field.checked);

    func();
  };

  return (
    <>
    <Checkbox
      className={`custom-green-checkbox ${className}`}
      {...field}
      {...props}
      text={text}
      value={field.checked}
      disabled={disabled}
      onChange={
      handleChange
      }
    />
    <span ref={containerRef}/>
      </>
  );
};

FormikCheckbox.propTypes = {
  text: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormikCheckbox;
