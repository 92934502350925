import React from "react";
import PropTypes from "prop-types";
import joinClassNames from "../helpers/joinClassNames";
import { ReactComponent as logo } from "../assets/new-logo.svg";

import { ReactComponent as sortAsc } from "../assets/sort-asc.svg";
import { ReactComponent as sortDesc } from "../assets/sort-desc.svg";
import { ReactComponent as sortDefault } from "../assets/sort-default.svg";

import { ReactComponent as tableSortAsc } from "./Table/icons/icon-sort-asc.svg";
import { ReactComponent as tableSortDesc } from "./Table/icons/icon-sort-desc.svg";
import { ReactComponent as tableSortDefault } from "./Table/icons/icon-sort-default.svg";
import { ReactComponent as infoIcon } from "./Table/icons/infoIcon.svg";

import { ReactComponent as deleteIcon } from "../assets/delete.svg";
import { ReactComponent as editIcon } from "../assets/edit.svg";

import { ReactComponent as editPen } from "../../pages/shipping/images/icon-16-edit-small.svg";
import { ReactComponent as saveIcon } from "../../pages/shipping/images/ic-save-gray.svg";
import { ReactComponent as bottomArrow } from "../../pages/bookkeeping/components/BookkeepingSettings/components/ReportPeriod/images/icon-input-shevron-dark-grey.svg";
import { ReactComponent as iconSidebarTaskManagement } from "../../pages/analytics/assets/images/icon-sidebar-task-management.svg";
import { ReactComponent as copyIcon } from "../../pages/drafts/components/Icons/Copy.svg";
import { ReactComponent as arrowDownIcon } from "../../pages/drafts/components/Icons/ArrowDownIcon.svg";

const iconsMap = {
  logo: logo,
  sortAsc: sortAsc,
  sortDesc: sortDesc,
  sortDefault: sortDefault,
  editIcon,
  deleteIcon,
  tableSortAsc,
  tableSortDesc,
  tableSortDefault,
  infoIcon,
  editPen,
  saveIcon,
  bottomArrow,
  iconSidebarTaskManagement,
  copyIcon,
  arrowDownIcon,
};

const Icon = ({ className, icon, ...props }) => {
  let Svg = iconsMap[icon];
  const classes = joinClassNames("icon", className);

  return <Svg className={classes} {...props} />;
};

Icon.defaultProps = {
  className: "",
  icon: logo,
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default Icon;
