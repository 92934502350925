/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";

import styles from "../../index.module.scss";
import { useDebounce } from "use-debounce";
import MergeLeftHeader from "../MergeLeftHeader";
import MergeItemsLeft from "../MergeItemsLeft";
import ClockLoader from "react-spinners/ClipLoader";
import { useService } from "../../../../hooks/useService";
import DraftsService from "../../../../../services/DraftsService";
import { PRODUCT_TYPE } from "../../../../constants/product";
import { CREATED_AT_FIELD } from "../../../../../pages/expenses/components/MileagesHeader/constants";
import { MERGE_OFFSET, SORTING_TYPE } from "../../constants/constants";
import Loader from "../../../InfiniteScrollWrapper/components/Loader";
import { useLastChild } from "../../hooks/useLastChild";

const LeftSide = ({
  marketplacesList,
  onSelectMarketplace,
  onSelectItem,
  selectedItem,
  onGetItems,
  items,
  onGetFullData,
  endItemsList,
  marketplaceType,
  searchTerm,
  onSetSearchTerm,
  searchHighlight,
  offset,
  onSetOffset,
  isMerge,
  onSetIsMerge,
  onSetSelectedItemIndex,
  shouldGetItems,
}) => {
  // TODO: need to fidn a way to get this setIsSearching piece working
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const searchRef = useRef("");
  const searchChanged = debouncedSearchTerm !== searchRef.current;
  searchRef.current = debouncedSearchTerm;

  const marketplaceTypeRef = useRef("");
  const marketplaceTypeChanged = marketplaceType !== marketplaceTypeRef.current;
  marketplaceTypeRef.current = marketplaceType;

  const draftService = useService(DraftsService);
  const types = [];
  types.push(marketplaceType);

  const handleSearchTerm = (e) => {
    onSetSearchTerm(e.target.value);
  };

  const fetchItems = (Offset = offset, Limit = 100) => {
    const requestBody = {
      q: debouncedSearchTerm || "",
      status: PRODUCT_TYPE.ACTIVE,
      orderBy: CREATED_AT_FIELD,
      orderType: SORTING_TYPE,
      marketplaceTypes: types,
      offset: searchChanged || marketplaceTypeChanged ? 0 : Offset,
      limit: Limit,
      isOrderByMarketplaceListed: true,
    };

    return draftService.getListDrafts(requestBody);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (endItemsList && !isMerge && !searchChanged && !marketplaceTypeChanged) {
      onSetOffset(0);
      return;
    }

    setIsLoading(true);
    fetchItems()
      .then((response) => {
        if (searchChanged || marketplaceTypeChanged) {
          onGetItems(response.data);
        } else {
          onGetItems((items) => {
            return [...items, ...response.data];
          });
        }
        onGetFullData(response);
        onSetSelectedItemIndex(undefined);
        onSetIsMerge(false);
      })
      .catch((e) => Bugsnag.notify(e))
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offset,
    shouldGetItems,
    debouncedSearchTerm,
    searchChanged,
    marketplaceType,
  ]);

  const setOffsetFunc = useCallback(() => {
    if (isLoading) {
      return;
    }
    onSetOffset((prevState) => prevState + MERGE_OFFSET);
  }, [isLoading, onSetOffset]);

  const { lastItem } = useLastChild(setOffsetFunc);

  return (
    <div className={styles.halfContainer + " " + styles.left}>
      <MergeLeftHeader
        search={searchTerm}
        onSearchTerm={handleSearchTerm}
        marketplacesList={marketplacesList}
        onSelectMarketplace={onSelectMarketplace}
      />

      {items.length ? (
        <MergeItemsLeft
          items={items}
          lastItem={lastItem}
          onSelectItem={onSelectItem}
          selectedItem={selectedItem}
          searchHighlight={searchHighlight}
        />
      ) : (
        <div
          className={
            styles.cardMergeContainerEmpty +
            " d-flex justify-content-center align-items-center"
          }
        >
          <div className="d-flex justify-content-center align-items-center">
            {isSearching ? (
              <ClockLoader color={"#2f665c"} size={48} />
            ) : (
              <span className="bold-gray">No items found</span>
            )}
          </div>
        </div>
      )}
      {!isSearching && isLoading && !endItemsList && (
        <div className={styles.infinitLoader}>
          <Loader key="loader" />
        </div>
      )}
    </div>
  );
};

export default LeftSide;
