import { useHistory } from "react-router-dom";
import { useCallback, useMemo } from "react";

export const usePathIncludes = (pathname) => {
  const history = useHistory();

  const isIncludes = useMemo(() => {
    return history.location.pathname.includes(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const reloadPage = useCallback(() => {
    if (isIncludes) window.location.reload();
  }, [isIncludes]);

  return { reloadPage };
};
