import React from "react";
import styles from "../../../shipping/common.module.scss";
import FilterDropdown from "../../../../base/components/FilterDropdown";

const FilterInventory = ({
                           className,
                           withAllOption,
                           filterName,
                           filterOptions

                         }) => {
  return (
    <div className={styles.tabChildrenWrapper}>
      <span className={styles.filterTitle}>Filter:</span>
      <FilterDropdown
        className={className}
        withAllOption={withAllOption}
        filterName={filterName}
        filterOptions={filterOptions}
      />
    </div>
  );
};

export default FilterInventory;