/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import CustomButton from "../CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../CustomModal";

import LeftSide from "./components/LeftSide/LeftSide";
import RightSide from "./components/RightSide/RightSide";
import {
  marketMap,
  marketplacesList,
  SUCCESS_MERGE_MESSAGE,
} from "./constants/constants";
import {
  cutFirstFourWords,
  hasSameItem,
  checkIsNotAvailable,
  removeSelectedSuggestedItem,
  getCuttedData,
} from "./utils/utils";
import DraftsService from "../../../services/DraftsService";
import ToasterService from "../../../services/ToastService";
import { useService } from "../../hooks/useService";
import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../../pages/inventory/constants/importInventory";
import Bugsnag from "@bugsnag/js";

const MergeModal = ({ title = "Merge", isOpen = true, onClose }) => {
  const [searchRightTerm, setSearchRightTerm] = useState("");
  const [searchLeftTerm, setSearchLeftTerm] = useState("");
  const [shouldRerenderLeft, setShouldRerenderLeft] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState(
    marketplacesList[0]
  );
  const [items, setItems] = useState([]);
  const [fullDataLeft, setFullDataLeft] = useState([]);
  const [fullDataRight, setFullDataRight] = useState([]);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemIndex, setSelectedItemIndex] = useState(undefined);
  const [mergedItemsIds, setMergedItemsIds] = useState([]);
  const [selectedSuggestedItems, setSelectedSuggestedItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isMerge, setIsMerge] = useState(false);
  const [shouldGetItems, setShouldGetItems] = useState(false);

  const draftService = useService(DraftsService);
  const toast = useService(ToasterService);

  const endItemsListLeft =
    fullDataLeft?.pagination?.nextOffset > fullDataLeft?.pagination?.totalCount;
  const endItemsListRight =
    fullDataRight?.pagination?.nextOffset >
    fullDataRight?.pagination?.totalCount;

  const isDisabledMergeButton = useMemo(() => {
    return selectedItem?.id && selectedSuggestedItems?.length;
  }, [selectedItem, selectedSuggestedItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedMarketplacesArray =
    selectedItem?.marketplaces?.map((item) => {
      return item.marketplaceType;
    }) || [];

  const selectedMarketplacesMap = useMemo(() => {
    const availableMarketplaces = {
      [MARKETPLACE_EBAY_TYPE]: false,
      [MARKETPLACE_ETSY_TYPE]: false,
      [MARKETPLACE_POSHMARK_TYPE]: false,
      [MARKETPLACE_MERCARI_TYPE]: false,
    };
    selectedMarketplacesArray?.forEach((marketplaceType) => {
      availableMarketplaces[marketplaceType] = true;
    });
    selectedSuggestedItems?.forEach((item) => {
      item?.marketplaces?.forEach((marketplace) => {
        availableMarketplaces[marketplace?.marketplaceType] = true;
      });
    });
    return availableMarketplaces;
  }, [selectedMarketplacesArray, selectedSuggestedItems]);

  useEffect(() => {
    setSelectedItem({});
    setSelectedSuggestedItems([]);
    setSuggestedItems([]);
    setSearchRightTerm("");
  }, [items]);

  const getFullDataLeft = (data) => {
    setFullDataLeft(data);
  };

  const getFullDataRight = (data) => {
    setFullDataRight(data);
  };

  const setSuggestedItemsData = (data) => {
    setSuggestedItems(data);
  };

  const handleSelectItem = (item, index) => {
    if (item.id === selectedItem.id) {
      setSelectedItem({});
      setSelectedSuggestedItems([]);
      setSelectedItemIndex(undefined);
      setSearchRightTerm("");
    } else {
      setSelectedSuggestedItems([]);
      setSelectedItemIndex(index);
      setSelectedItem(item);
      setSearchRightTerm(cutFirstFourWords(item?.title));
    }
  };

  const handleSelectSuggestedItem = (item) => {
    const currentSelectedItem = [...selectedSuggestedItems];
    if (hasSameItem(selectedSuggestedItems, item.id)) {
      setSelectedSuggestedItems(
        removeSelectedSuggestedItem(currentSelectedItem, item)
      );
    } else {
      currentSelectedItem.push(item);
      setSelectedSuggestedItems(currentSelectedItem);
    }
  };

  const checkIsNotAvailableToMerge = (item, map) => {
    return checkIsNotAvailable(item, map);
  };

  const handleSelectMarketplace = (item) => {
    setSelectedMarketplace(item);
  };

  const handleCloseModal = () => {
    setSelectedSuggestedItems([]);
    setSelectedMarketplace(marketplacesList[0]);
    setSuggestedItems([]);
    setMergedItemsIds([]);
    setSelectedItem({});
    setSelectedItemIndex(undefined);
    setSearchRightTerm("");
    setSearchLeftTerm("");
    onClose();
  };

  const clearSelectedSuggestedItem = () => {
    setSelectedSuggestedItems([]);
  };

  const handleMergeItems = () => {
    setIsMerge(true);
    const modelData = {};
    selectedSuggestedItems?.map((item) => {
      return item?.marketplaces?.map((marketplace) => {
        modelData[marketMap[marketplace.marketplaceType]?.id] = marketplace?.id;
      });
    });

    const model = {
      mergeToId: selectedItem.id,
      ...modelData,
    };
    const currentMergedItemsIds = mergedItemsIds;
    currentMergedItemsIds.push(selectedItem?.id);
    setMergedItemsIds(currentMergedItemsIds);

    draftService
      .mergeProducts(model)
      .then(() => {
        setShouldRerenderLeft(!shouldRerenderLeft);
        toast.success(SUCCESS_MERGE_MESSAGE);

        if (offset === selectedItemIndex) {
          setShouldGetItems(!shouldGetItems);
        }
        setOffset(selectedItemIndex);

        setItems(getCuttedData(items, selectedItemIndex));
      })
      .catch((error) => {
        Bugsnag.notify(error, (event) => {
          event.addMetadata("handleMergeItemsError", {
            model,
          });
        });
        toast.error(error.message);
      });
  };

  return (
    <>
      <CustomModal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalHeader onClose={handleCloseModal}>{title}</ModalHeader>

        <ModalBody className={classNames("pt-3", "pb-3")}>
          <div className={"d-flex"}>
            <LeftSide
              marketplacesList={marketplacesList}
              onSelectMarketplace={handleSelectMarketplace}
              onSelectItem={handleSelectItem}
              selectedItem={selectedItem}
              marketplaceType={selectedMarketplace.marketplaceType}
              onGetItems={setItems}
              onGetFullData={getFullDataLeft}
              items={items}
              endItemsList={endItemsListLeft}
              searchTerm={searchLeftTerm}
              onSetSearchTerm={setSearchLeftTerm}
              searchHighlight={searchLeftTerm}
              onSetSelectedItemIndex={setSelectedItemIndex}
              offset={offset}
              onSetOffset={setOffset}
              isMerge={isMerge}
              onSetIsMerge={setIsMerge}
              shouldGetItems={shouldGetItems}
            />
            <RightSide
              marketplacesTypesForSuggestion={selectedMarketplacesArray}
              selectedItem={selectedSuggestedItems}
              onSelectItem={handleSelectSuggestedItem}
              onSetSuggestedItems={setSuggestedItemsData}
              suggestedItems={suggestedItems}
              fullData={fullDataRight}
              onGetFullData={getFullDataRight}
              endItemsList={endItemsListRight}
              selectedMarketplacesMap={selectedMarketplacesMap}
              checkIsNotAvailableToMerge={checkIsNotAvailableToMerge}
              isDisabledSearch={!selectedItem?.marketplaces?.length}
              searchTerm={searchRightTerm}
              searchHighlight={searchLeftTerm}
              onSetSearchTerm={setSearchRightTerm}
              onClearSelectedSuggestedItem={clearSelectedSuggestedItem}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex justify-content-end">
            <CustomButton
              className=" filled-primary"
              disabled={!isDisabledMergeButton}
              onClick={handleMergeItems}
            >
              Merge
            </CustomButton>
          </div>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default MergeModal;
