/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from "react";
import Table from "../../../../base/components/Table";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { TableStructure } from "./columns";
import "./index.scss";
import PageTitle from "../../../profile/components/PageName";
import { Container } from "reactstrap";
import { useTable } from "../../../../base/hooks/useTable";
import { useHistory } from "react-router-dom";
import {
  useStatefullProvider,
  useStatelessProvider,
} from "../../../../base/hooks/useProvider";
import PageMeta from "../../../../base/components/MetaTags";
import { useCopyDraft } from "../../../drafts/hooks/useCopyDraft";
import { useDeleteDraft } from "../../../drafts/hooks/useDeleteDraft";
import { TableContext } from "../../../drafts/contexts/table";
import WhiteBox from "../../../drafts/components/WhiteBox";
import HeaderComponent from "../../components/TopTablePanel";
import { useProduct, useTotalProducts } from "../../../drafts/hooks/useProduct";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { useProfile } from "../../../profile/hooks/useProfile";
import { SUBSCRIPTIONS_STATUSES } from "../../../drafts/pages/Settings/page/EmployeeManagement/constants";
import { ACTIVE_INVENTORY_LABEL, MAX_INVENTORY_COUNT } from "../../constants";
import Placeholder from "../../components/Placeholder";
import { useMarkToReload } from "../../../../base/hooks/useMarkToReload";
import { ACTIVE_ITEMS_MARK_TO_RELOAD } from "../../../../base/constants/markToReload";
import {
  INVENTORY_PREV_PATH,
  ORDER_BY,
  ORDER_BY_DATE_LISTED_NAME,
  ORDER_BY_LABEL,
  ORDER_TYPE_LABEL,
} from "../../../drafts/constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import { getActiveInventoryHrefByCondition } from "../../../sold/helpers";
import { INVENTORY_STATUSES } from "../EditInventory/constants";
import emptyInventoryIcon from "../../components/Icons/inventory-empty.png";
import EmptyList from "../../../drafts/components/EmptyList";
import AddDraftButton from "../../../drafts/components/AddDraftButton";
import { DRAFTS_GROUP_LINKS } from "../../../drafts/config";

const LIMIT = 10;

const reverseOrderMap = { statusAt: true };

const popupConfig = {
  title: "Delete",
  message: "Do you sure want to delete this item?",
  btnAccept: "Delete",
  btnClose: "Cancel",
};

const Inventories = ({ load, total }) => {
  const [
    {
      activeSubscription: { type },
    },
  ] = useProfile();

  const [{ total: totalInventory }] = useTotalProducts(PRODUCT_TYPE.DRAFT);
  const [{ active }, updateTableContext] = useStatefullProvider(TableContext);
  const isShowCopyBtn = useMemo(() => {
    return {
      [SUBSCRIPTIONS_STATUSES.trial]: true,
      [SUBSCRIPTIONS_STATUSES.standard]:
        totalInventory < MAX_INVENTORY_COUNT.standard,
      [SUBSCRIPTIONS_STATUSES.business]:
        totalInventory < MAX_INVENTORY_COUNT.business,
      [SUBSCRIPTIONS_STATUSES.unlimited]: true,
      [SUBSCRIPTIONS_STATUSES.expired]: false,
    };
  }, [totalInventory]);

  const history = useHistory();
  const { params, merge } = useLocationQuery();
  const [{ active: totalActiveInventory }] = useStatelessProvider(TableContext);

  const { marker } = useMarkToReload(ACTIVE_ITEMS_MARK_TO_RELOAD);

  const getCollection = useProduct(PRODUCT_TYPE.ACTIVE, LIMIT, marker);

  const [loading, pagination, list, handleTableChange, { refresh }] = useTable({
    searchQueryParam: "query",
    sortTypeQueryParam: "orderType",
    sortByQueryParam: "orderBy",
    isExcludeListedToAll: "isExcludeListedToAll",
    reverseOrderMap,
    getNewDataService: getCollection,
    limit: LIMIT,
  });

  const { onCopy, view } = useCopyDraft(
    useCallback(() => {
      refresh();
    }, [refresh])
  );

  useEffect(() => {
    refresh();
  }, [type]);

  const isActiveSearch = !list?.length && !params.query && !loading;

  const { offset } = params;

  const { onDelete, view: deleteView } = useDeleteDraft(
    useCallback(() => {
      const noData = !(list.length - 1);

      updateTableContext((data) => ({
        ...data,
        active: data.active - 1,
      }));

      if (noData && offset) {
        merge("offset", offset - LIMIT);
        return;
      }

      refresh();
    }, [refresh, list.length, merge, offset, updateTableContext]),
    popupConfig
  );

  useEffect(() => {
    if (!params.orderBy) {
      merge(ORDER_BY_LABEL, ORDER_BY_DATE_LISTED_NAME);
      merge(ORDER_TYPE_LABEL, ORDER_BY.desc);
    }
  }, []);

  useEffect(() => {
    updateTableContext({
      onCopy,
      onDelete,
      active: total,
      isAcceptCopy: isShowCopyBtn[type],
    });
  }, [onCopy, updateTableContext, onDelete, total, isShowCopyBtn, type]);

  const isActiveAndHaveList = !active && list.length === 0;

  const { saveLocation } = usePrevLocation(INVENTORY_PREV_PATH);

  const H = useCallback(
    ({ ...props }) => (
      <HeaderComponent isActiveSearch={isActiveSearch} {...props} />
    ),
    [isActiveSearch]
  );

  return (
    <Container fluid>
      <PageMeta title="Inventory" />
      <PageTitle title="INVENTORY" />
      <div className="drafts-page">
        {load ? (
          <WhiteBox>
            <p>Loading...</p>
          </WhiteBox>
        ) : !totalActiveInventory && list.length === 0 ? (
          <EmptyList
            imgSrc={emptyInventoryIcon}
            importButton={true}
            link={DRAFTS_GROUP_LINKS.ADD_DRAFT}
            inventoryStatusLabel={ACTIVE_INVENTORY_LABEL}
            buttonContent={"+ Add new"}
            addButtonClassName={"ml-3"}
            AddButton={AddDraftButton}
          >
            {
              'To add inventory, either click "+Add new" or import existing inventory from a selected marketplace.'
            }
          </EmptyList>
        ) : (
          <div className="drafts-table">
            <Table
              data={list}
              dataStructure={TableStructure}
              loading={loading}
              HeaderComponent={H}
              isPlaceholder={isActiveAndHaveList}
              PlaceholderComponent={Placeholder}
              limit={LIMIT}
              searchField={"query"}
              offset={params.offset || 0}
              totalCount={pagination.totalCount}
              handleTableChange={handleTableChange}
              onRowClick={(inventory) => {
                saveLocation();
                history.push(
                  getActiveInventoryHrefByCondition(inventory, ({ status }) => {
                    return status === INVENTORY_STATUSES.listed;
                  })
                );
              }}
            />
          </div>
        )}
      </div>

      {/* Copy action */}
      {view}
      {/* Delete action */}
      {deleteView}
    </Container>
  );
};

export default () => {
  const [{ total, load }] = useTotalProducts(PRODUCT_TYPE.ACTIVE);

  const providedData = useMemo(() => {
    return {
      onCopy: () => {},
      onDelete: () => {},
      active: total,
      isAcceptCopy: undefined,
    };
  }, [total]);

  return (
    <TableContext.Provider value={providedData}>
      <Inventories load={load} total={total} />
    </TableContext.Provider>
  );
};
