import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import CountEntries from "../../../../../pages/drafts/components/CountEntries";
import Checkbox from "../../../Checkbox";
import CustomDropdown from "../../../CustomDropdown";
import {
  INDEX_SHOW_ACTIVE_INVENTORY,
  DROPDOWN_IMPORT_LIST_INVENTORY
} from "../../../../constants/importEbay";

const ImportFilters = ({
  count,
  isCheckedAll,
  onChangeCheckedAll,
  onChangeFilter,
  isShowCustomDropdown = false,
}) => {
  return (
    <Row className="mb-3">
      <Col className="d-flex align-items-center">
        <Checkbox
          value={isCheckedAll}
          onChange={onChangeCheckedAll}
          className="custom-green-checkbox ml-3 mr-3"
          text={isCheckedAll ? "Deselect all" : "Select all"}
        />
        <CountEntries count={count}>Items found</CountEntries>
      </Col>
      {/*{isShowCustomDropdown && (*/}
        <Col className="d-flex justify-content-end">
          <CustomDropdown
            defaultSelected={DROPDOWN_IMPORT_LIST_INVENTORY[INDEX_SHOW_ACTIVE_INVENTORY]}
            list={DROPDOWN_IMPORT_LIST_INVENTORY}
            onChange={onChangeFilter}
          />
        </Col>
      {/*)}*/}
    </Row>
  );
};

ImportFilters.propTypes = {
  count: PropTypes.any,
  isCheckedAll: PropTypes.bool,
  onChangeCheckedAll: PropTypes.func,
  onChangeFilter: PropTypes.func,
  isShowCustomDropdown: PropTypes.bool,
};

export default ImportFilters;
