import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import { noop } from "lodash/util";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";
import ProductReturnsService from "../../../services/ProductReturnsService";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";
import FormikCheckbox from "../../../base/components/FormikCheckbox";

const initialValues = {
  isMoveToSold: false,
};

export const useDeleteReturn = (props, onRefresh) => {
  const [returnToDelete, updateReturnToDelete] = useState(null);
  const [, { registerPromise }] = useLoading();
  const returnProperties = props;
  const hasProductId = !!returnProperties.productId;
  /**
   * @type {ProductReturnsService}
   */
  const productReturnsService = useService(ProductReturnsService);
  /**
   * @type {DraftsService}
   */
  const draftsService = useService(DraftsService);

  const onDelete = useCallback((id) => {
    updateReturnToDelete(id);
  }, []);

  const close = useCallback(() => {
    updateReturnToDelete(null);
  }, []);

  const deleteProduct = useCallback(
    (idProduct) => {
      registerPromise(
        draftsService
          .deleteDraft(idProduct)
          .then(() => {
            onRefresh();
            close();
          })
          .catch((e) => Bugsnag.notify(e))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [close, draftsService, registerPromise]
  );

  const deleteReturn = useCallback(() => {
    const idReturn = returnToDelete;
    registerPromise(
      productReturnsService
        .deleteReturnProduct(idReturn)
        .then(() => {
          onRefresh();
          close();
        })
        .catch((e) => Bugsnag.notify(e))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnToDelete, close, productReturnsService, registerPromise]);

  const handleDelete = useCallback(
    ({ values }) => {
      if (values.isMoveToSold || (!values.isMoveToSold && !hasProductId)) {
        deleteReturn();
        return;
      }

      deleteProduct(returnProperties.productId);
    },
    [hasProductId, deleteProduct, deleteReturn, returnProperties]
  );

  const viewDeleteReturn = (
    <CustomModal isOpen={!!returnToDelete} onClose={close}>
      <Formik initialValues={initialValues} onSubmit={noop}>
        {({ values }) => {
          return (
            <>
              <ModalHeader onClose={close}>{"Delete return"}</ModalHeader>
              <ModalBody>
                <p className="ui-regular">
                  {
                    "Are you sure you want to delete this return ticket and all info about the item?"
                  }
                </p>

                <FormikCheckbox
                  name="isMoveToSold"
                  text="Move to sold"
                  disabled={!hasProductId}
                  className={"move-to-sold"}
                />
              </ModalBody>
              <ModalFooter>
                <CustomButton onClick={close} className="outline-primary ">
                  {"No"}
                </CustomButton>

                <CustomButton
                  onClick={() => handleDelete({ values })}
                  className="filled-primary "
                >
                  {"Yes"}
                </CustomButton>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );

  return { viewDeleteReturn, onDelete };
};
