import { useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../../../base/hooks/useLoading";
import { useService } from "../../../../../base/hooks/useService";
import MercariExtension from "../../../../../services/MercariExtension";
import { useError } from "../../../../../base/contexts/ErrorContext";
import { phrases } from "../../../../../store/phrases";

const sortCategoryByDisplayOrder = (first, second) =>
  first.displayOrder - second.displayOrder;

export const findElement = (collection, byId) => {
  return collection.find(({ id }) => {
    return id === byId;
  });
};

export const findElementLabel = (collection, byId) =>
  findElement(collection, byId)?.label || "";

export const useMercaryCategory = (
  categories,
  { topCategoryId, nestedCategoryId, categoryId }
) => {
  const topCategories = useMemo(() => {
    return categories
      .filter(({ parentId }) => !parentId)
      .sort(sortCategoryByDisplayOrder);
  }, [categories]);

  const nestedCategories = useMemo(() => {
    if (!topCategoryId) return [];

    return categories
      .filter(({ parentId }) => parentId === topCategoryId)
      .sort(sortCategoryByDisplayOrder);
  }, [topCategoryId, categories]);

  const subCategories = useMemo(() => {
    if (!nestedCategoryId) return [];

    const allNestedCategories = findElement(nestedCategories, nestedCategoryId);

    const options = categories
      .filter(({ parentId }) => parentId === nestedCategoryId)
      .sort(sortCategoryByDisplayOrder);

    if (allNestedCategories) {
      // Ability to select all
      options.unshift({
        label: "All " + allNestedCategories.name,
        id: allNestedCategories.id,
      });
    }

    return options;
  }, [nestedCategoryId, categories, nestedCategories]);

  const category = useMemo(() => {
    return findElement(subCategories, categoryId);
  }, [categoryId, subCategories]);

  const topCategory = useMemo(() => {
    return findElement(topCategories, topCategoryId);
  }, [topCategoryId, topCategories]);

  const nestedCategory = useMemo(() => {
    return findElement(nestedCategories, nestedCategoryId);
  }, [nestedCategoryId, nestedCategories]);

  const categoryShortName = category?.label || "";

  const categoryName = useMemo(() => {
    return (
      [topCategory?.label, nestedCategory?.label, category?.label]
        .filter(Boolean)
        .join(" / ") || ""
    );
  }, [topCategory, nestedCategory, category]);

  return {
    topCategories,
    nestedCategories,
    subCategories,
    topCategory,
    category,
    nestedCategory,
    categoryShortName,
    categoryName,
  };
};

export const useMercaryColor = (colors, colorId) => {
  const color = useMemo(() => {
    return findElement(colors, colorId);
  }, [colors, colorId]);

  return {
    color,
    colorLabel: color?.label || "",
  };
};

export const useMercarySize = (sizes, sizeId) => {
  const size = useMemo(() => {
    return findElement(sizes, sizeId);
  }, [sizes, sizeId]);

  return {
    size,
    sizeLabel: size?.label || "",
  };
};

export const useMercariBrand = (brands, brandId) => {
  const brand = useMemo(() => {
    return findElement(brands, brandId);
  }, [brands, brandId]);

  return {
    brand,
    brandLabel: brand?.label || null,
  };
};

const mapToOption = (item) => ({ ...item, label: item.name });
export const mapShippingToOption = (item) => ({
  ...item,
  label: `${item.carrierDisplayName}, $${parseFloat(item.fee / 100).toFixed(
    2
  )}, Up to ${item.name}`,
});

export const useGetMercariCategories = () => {
  const [isLoading, { registerPromise }] = useLoading();
  const [categories, updateCategories] = useState([]);
  const [brands, updateBrands] = useState([]);
  const [colors, updateColors] = useState([]);
  const [sizes, updateSizes] = useState([]);
  const [shippings, updateShippings] = useState([]);

  /**
   *
   * @type {MercariExtension}
   */
  const extension = useService(MercariExtension);
  const { setErrorData } = useError();

  useEffect(() => {
    // prefetch
    registerPromise(
      extension
        .getCategories()
        .then((response) => {
          if (!Array.isArray(response)) {
            Bugsnag.notify(new Error("Not an array"), (event) => {
              event.addMetadata("useGetMercariCategoriesError", {
                response,
              });
            });

            return;
          }

          const [masterData, shippingData] = response;

          if (
            masterData.errors?.[0]?.message === "Unauthorized" ||
            shippingData.errors?.[0]?.message === "Unauthorized"
          ) {
            setErrorData(phrases.mercariReauthentication);

            return;
          }

          setErrorData("");

          const {
            data: {
              master: { itemCategories, itemBrands, itemColors, itemSizes },
            },
          } = masterData;

          const {
            data: {
              availableShippingClassesV2: { shippingClasses = [] },
            },
          } = shippingData;

          updateColors(itemColors.map(mapToOption));
          updateBrands(itemBrands.map(mapToOption));
          updateShippings(shippingClasses.map(mapShippingToOption));
          updateSizes(itemSizes.map(mapToOption));
          updateCategories(itemCategories.map(mapToOption));
        })
        .catch((e) => {
          Bugsnag.notify(e);
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extension, registerPromise]);

  return {
    isLoading,
    categories,
    brands,
    sizes,
    shippings,
    colors,
  };
};
