import { useCallback, useRef, useState } from "react";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { usePoshmarkIsConnect } from "../../drafts/hooks/Poshmark/usePoshmarkIsConnect";
import { MARKETPLACES } from "../../../base/constants/extension";
import { useService } from "../../../base/hooks/useService";
import { PoshmarkSoldProduct } from "../../inventory/classes/PoshmarkProduct";
import QueuePromises from "../../../base/classes/QueuePromises";
import { MARKETPLACE_TYPES } from "../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import {
  compareTwoDate,
  getLastSyncedDateForMarketplace,
  toNumber,
} from "../helpers";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";
import SoldService from "../../../services/SoldService";
import PoshmarkService from "../../../services/PoshmarkService";
import TabConnection from "../../../services/importServices/TabConnection";
import ImportPoshmark from "../../../services/importServices/ImportPoshmark";
import { useDelistByMarketplace } from "./useDelistByMarketplace";
import PoshmarkExtension from "../../../services/PoshmarkExtension";
import { createPoshmarkModelForRequest } from "../../drafts/helpers/Poshmark/createPoshmarkModelForRequest";

export const useGetSoldItemsPoshmark = () => {
  /**
   * @type {PoshmarkService}
   */
  const poshmarkService = useService(PoshmarkService);
  /**
   * @type {TabConnection}
   */
  const tabConnection = useRef(new TabConnection(MARKETPLACES.POSHMARK));

  /**
   * @type {ImportPoshmark}
   */
  const importPoshmark = useService(ImportPoshmark);

  /**
   * @type {SoldService}
   */
  const sold = useService(SoldService);

  const poshmarkExtension = new PoshmarkExtension();

  const { isConnected } = usePoshmarkIsConnect();
  const [isProcessing, setFetchProcessing] = useState(false);

  const [delistProduct] = useDelistByMarketplace();

  const itemsRef = useRef([]);
  const updateItems = (callback) => {
    itemsRef.current = callback(itemsRef.current);
  };

  const marketplace = MARKETPLACES.POSHMARK;

  const onComplete = useCallback(
    (activeInventories) => {
      setFetchProcessing(true);
      const soldInventories = itemsRef.current.map(
        (product) => new PoshmarkSoldProduct(product)
      );

      const queue = new QueuePromises();
      const markAsSoldQueue = new QueuePromises();

      soldInventories.forEach((product) => {
        const loadData = () =>
          product?.loadAdditionalFields() || Promise.resolve();
        queue.add(loadData);
      });

      const listToMarkAsSold = [];

      return queue.finally(() => {
        const soldInventoriesWithBundles = soldInventories.map((sold) => {
          if (sold.isBundle) {
            return sold.data.map((bundleProduct) => {
              return {
                listingId: bundleProduct.listingId,
                data: bundleProduct,
              };
            });
          }

          return sold;
        });

        const listToSync = soldInventoriesWithBundles.flat().filter((sold) => {
          return activeInventories[sold.listingId];
        });

        listToSync.forEach((syncItem) => {
          syncItem = {
            ...syncItem,
            productId: activeInventories[syncItem.listingId]?.data?.id,
          };
          listToMarkAsSold.push(syncItem);
        });

        listToMarkAsSold.map((soldItem) => {
          const soldDetails = {
            priceSold: toNumber(soldItem.data?.priceSold),
            itemCost: toNumber(soldItem.data?.costPrice),
            marketplaceFees: toNumber(soldItem.data?.marketplaceFees),
            shippingExpenses: toNumber(soldItem.data?.shippingExpenses),
            transactionFees: toNumber(soldItem.data?.transactionFees),
            listingId: soldItem.listingId,
            buyer: soldItem.data?.buyer,
            dateSold: soldItem.data?.dateSold,
            marketplaceType: MARKETPLACE_TYPES.poshmark,
          };

          const formattedSoldDetails = filterObjectFalsy(soldDetails);
          markAsSoldQueue.add(() =>
            sold.importSold(formattedSoldDetails).finally(async () => {
              let invItem = soldInventoriesWithBundles
                .flat()
                .find((item) => item.listingId === soldItem.listingId);

              if (!invItem) {
                invItem = await poshmarkExtension.getDraft(soldItem.listingId);
              } else {
                invItem = invItem.fullData || invItem;
              }

              if (invItem) {
                try {
                  const { convertedData } = createPoshmarkModelForRequest(
                    {
                      productPoshmarkSpecificFields: invItem,
                    },
                    invItem
                  );
                  convertedData.inventory = {
                    ...convertedData.inventory,
                    size_quantities: [
                      {
                        size_id: "fearn",
                        size_obj: {
                          display: "fearn",
                          display_with_size_set: "fearn",
                          id: "fearn",
                          size_systems: "us",
                        },
                      },
                    ],
                  };
                  convertedData.brand = invItem.brand_id;
                  convertedData.price_amount = invItem.prince_amount;
                  convertedData.original_price_amount =
                    invItem.original_price_amount;
                  convertedData.colors = invItem.colors?.map(
                    (color) => color.name
                  );

                  await poshmarkExtension.editInventory(
                    soldItem.listingId,
                    [],
                    convertedData
                  );
                  await poshmarkService.delayPromise(5000); // delaying this because poshmark takes some time on their end to cancel any offers on items
                  return delistProduct(
                    MARKETPLACES.POSHMARK,
                    soldItem.listingId,
                    activeInventories
                  );
                } catch (e) {
                  Bugsnag.notify(e, (event) => {
                    event.addMetadata("PoshmarkMarkAsSoldError", {
                      model: invItem,
                      id: soldItem.listingId,
                    });
                  });
                  return delistProduct(
                    MARKETPLACES.POSHMARK,
                    soldItem.listingId,
                    activeInventories
                  );
                }
              }
            })
          );
        });

        markAsSoldQueue.finally(() => {
          setFetchProcessing(false);
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [delistProduct, sold, itemsRef]
  );

  const getFilteredItemsByDate = (data, dateForFilter) =>
    data.filter(({ inventory_booked_at }) =>
      compareTwoDate(inventory_booked_at, dateForFilter)
    );

  const sync = useCallback(
    (list, marketplaceData) => {
      const dateForFilter = getLastSyncedDateForMarketplace(marketplaceData);
      const newSyncDate = moment().toISOString();

      setFetchProcessing(true);

      const loadFullSoldData = (tabId, pagination) => {
        return importPoshmark
          .importSoldProducts(tabId, pagination)
          .then((response) => {
            const {
              result: { data, pagination: newPagination },
            } = response;

            const filteredItems = getFilteredItemsByDate(data, dateForFilter);

            if (
              !newPagination?.nextPage ||
              filteredItems.length !== data.length
            ) {
              return updateItems((prevList) => [...prevList, ...filteredItems]);
            }

            updateItems((prevList) => [...prevList, ...data]);

            return loadFullSoldData(tabId, newPagination);
          })
          .catch((e) => Bugsnag.notify(e));
      };

      tabConnection.current
        .connect()
        .then((tabId) => {
          return loadFullSoldData(tabId).finally(() => {
            onComplete(list);
            tabConnection.current.disconnect();
            poshmarkService.updateLastSyncedDate(newSyncDate);
          });
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [importPoshmark, onComplete, poshmarkService]
  );

  return {
    isProcessing,
    sync,
    isConnected,
    marketplace,
  };
};
