import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

import CustomButton from "../../../../base/components/CustomButton";
import FormikInput from "../../../../base/components/FormikInput";
import SendCodeButton from "../../../completeProfile/components/SendCodeButton";
import CustomAlert from "../../../auth/components/CustomAlert";

import ProfileService from "../../../../services/ProfileService";
import StorageService from "../../../../services/StorageService";
import {
  CHANGED_PHONE_KEY,
  CODE_NAME_KEY,
  DEFAULT_START_PHONE_NUMBER,
  EMPTY_STRING,
  ERROR_SMS_CODE,
} from "../../../../base/constants/forms";
import {
  DEV_PHONE_NUMBER_PATTERN,
  PHONE_NUMBER_PATTERN,
} from "../../../../base/constants/patterns";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import {
  ACCESS_CODE_LENGTH,
  DEV_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
} from "../../../../validation/lengthConstants";
import { getValidationSchema } from "../../../completeProfile/components/Secure/form";
import { PROFILE_GROUP_LINKS } from "../../config";
import { KEY_USER } from "../../../../base/constants/storage";
import {
  getPrefixDevPhpne,
  getPrefixPhone,
} from "../../../../base/helpers/getPrefixPhone";
import {
  useCleanCustomErrors,
  useCustomError,
} from "../../../../base/contexts/CustomErrorMessage";
import CustomErrorMessage from "../../../../base/components/ErrorMessage";
import { useIsDev } from "../../../../base/hooks/useIsDev";
import { PHONE_NUMBER_IS_USED } from "../../../completeProfile/components/Secure/constants";

const PhoneNumberForm = () => {
  const { push } = useHistory();

  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const { setError } = useCustomError();
  useCleanCustomErrors();

  const isDev = useIsDev();

  const [, { registerPromise }] = useLoading();

  const [isDisabledConfirmButton, updateIsDisabledConfirmButton] =
    useState(true);
  const [isDisabledButton, updateIsDisabledButton] = useState(true);
  const [isSendedCode, updateIsSendedCode] = useState(false);

  const handleDisabledChange = useCallback((isValid) => {
    updateIsDisabledConfirmButton(!isValid);
  }, []);

  const handleChangePhone = useCallback(
    (values, setFieldValue, setFieldError) => {
      values?.code && setFieldValue(CODE_NAME_KEY, EMPTY_STRING);

      registerPromise(profileService.changePhoneNumber(values))
        .then(() => {
          updateIsDisabledButton(false);
          updateIsSendedCode(true);
        })
        .catch(({ error: { code } }) => {
          if (code === PHONE_NUMBER_IS_USED.ERROR_CODE) {
            setFieldError(
              "phoneNumber",
              PHONE_NUMBER_IS_USED.ERROR_FIELD_MESSAGE
            );
          }
        });
    },
    [registerPromise, profileService]
  );

  const handleSubmit = useCallback(
    (values) => {
      registerPromise(profileService.verifyPhoneNumber(values))
        .then(() => {
          storage.set(CHANGED_PHONE_KEY, values.phoneNumber);
          push(PROFILE_GROUP_LINKS.BASE);
        })
        .catch(() => {
          setError(CODE_NAME_KEY, ERROR_SMS_CODE);
          updateIsDisabledConfirmButton(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerPromise, profileService, storage, push]
  );

  return (
    <Formik
      initialValues={{
        phoneNumber:
          storage.get(CHANGED_PHONE_KEY) ||
          storage.get(KEY_USER)?.phoneNumber ||
          DEFAULT_START_PHONE_NUMBER,
        code: EMPTY_STRING,
      }}
      validationSchema={getValidationSchema(isDev)}
      onSubmit={handleSubmit}
    >
      {({ errors, values, handleSubmit, setFieldValue, setFieldError }) => {
        return (
          <form
            className="form-horizontal p-2 phone-number-form"
            onSubmit={handleSubmit}
          >
            <Row className="mb-4">
              <Col md="6">
                <FormikInput
                  type="phone"
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone number"
                  maxLength={
                    isDev ? DEV_PHONE_NUMBER_LENGTH : MIN_PHONE_NUMBER_LENGTH
                  }
                  placeholder="Enter your phone number"
                  onChange={({ target: { value } }) => {
                    setFieldValue(
                      "phoneNumber",
                      isDev ? getPrefixDevPhpne(value) : getPrefixPhone(value)
                    );
                  }}
                  autoFocus
                />
              </Col>
              <Col md="6">
                <FormikInput
                  type="text"
                  id="code"
                  name={CODE_NAME_KEY}
                  label="Code"
                  disabled={isDisabledButton}
                  ErrorMessage={CustomErrorMessage}
                  maxLength={ACCESS_CODE_LENGTH}
                  placeholder="Enter the 6-digit code"
                  onChange={({ target: { value } }) => {
                    handleDisabledChange(value?.length === ACCESS_CODE_LENGTH);
                    setError(CODE_NAME_KEY, "");
                  }}
                />
              </Col>
            </Row>
            <div>
              <SendCodeButton
                className="mb-3"
                isSendedCode={isSendedCode}
                disabled={
                  errors.phoneNumber || isDev
                    ? !DEV_PHONE_NUMBER_PATTERN.test(values.phoneNumber)
                    : !PHONE_NUMBER_PATTERN.test(values.phoneNumber)
                }
                onClick={() =>
                  handleChangePhone(values, setFieldValue, setFieldError)
                }
              />
            </div>
            {isSendedCode && (
              <CustomAlert className="justify-content-start">
                The verification code was sent to your phone number
              </CustomAlert>
            )}
            <div className="d-flex justify-content-end mt-auto">
              <CustomButton
                className="filled-primary"
                disabled={isDisabledConfirmButton}
                onClick={handleSubmit}
              >
                Confirm
              </CustomButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PhoneNumberForm;
