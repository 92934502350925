/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../CustomButton";
import ImportHeader from "./components/ImportHeader";
import ImportFilters from "./components/ImportFilters";
import ImportItems from "./components/ImportItems";
import { CustomModal, ModalBody, ModalHeader } from "../CustomModal";

import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../../pages/inventory/constants/importInventory";

import { useCheckedAll } from "../../hooks/useCheckedAll";

import { Spinner } from "reactstrap";
import { ImportContext } from "../../../pages/context/import";
import { useStatelessProvider } from "../../hooks/useProvider";
import { MARKETPLACES_NAMES_REQUEST_BY_ID } from "../../../pages/drafts/constants";
import { useDispatch } from "react-redux";
import { changeImportCounters } from "../../../store/import/actions";
import {
  IMPORT_FILTERS_INVENTORY,
  SHOW_SOLD,
} from "../../constants/importEbay";

import { useLoading } from "../../hooks/useLoading";
import DotsLoader from "../DotLoader";
import { useService } from "../../hooks/useService";
import ImportProductService from "../../../services/ImportProductService";
import { useCustomModal } from "../../../pages/drafts/hooks/useCustomModal";
import { SOLD_INVENTORY_LABEL } from "../../../pages/inventory/constants";

const PRODUCT_FIELD_ID_NAME = {
  [MARKETPLACE_EBAY_TYPE]: "listingId",
  [MARKETPLACE_ETSY_TYPE]: "listingId",
  [MARKETPLACE_POSHMARK_TYPE]: "listingId",
  [MARKETPLACE_MERCARI_TYPE]: "listingId",
};

const useImported = (visibleItems, marketplaceType) => {
  const dataRef = useRef({});
  const [, updateMarker] = useState(Date.now());
  const [, { registerPromise }] = useLoading();
  /**
   @type {ImportProductService}
   */

  const setData = useCallback(
    (statuses) => {
      dataRef.current = {
        ...dataRef.current,
        ...statuses,
      };
      updateMarker(Date.now());
    },
    [dataRef]
  );

  const importProductService = useService(ImportProductService);

  useEffect(() => {
    const listingIdArray = visibleItems
      .map((item) => {
        return item.listingId;
      })
      .filter((uuid) => {
        return dataRef.current[uuid] === undefined;
      });

    if (!listingIdArray.length) {
      return;
    }

    registerPromise(
      importProductService.getProductsImportedStatus({
        marketplaceType: marketplaceType,
        listingIds: listingIdArray,
      })
    )
      .then((response) => {
        let statusObject = {};

        const statusesArray = response.data;

        statusesArray?.forEach(({ listingId, isImported }) => {
          statusObject = {
            ...statusObject,
            [listingId]: isImported,
          };
        });

        setData(statusObject);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [
    visibleItems,
    registerPromise,
    setData,
    importProductService,
    marketplaceType,
  ]);

  return [
    useCallback(
      ({ listingId }) => {
        return dataRef.current[listingId];
        //return true
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [dataRef.current]
    ),
    useCallback(() => {
      dataRef.current = {};
    }, [dataRef]),
  ];
};

const useImportCallback = (callback) => {
  const paramsRef = useRef([]);

  const { modal, onShowModal } = useCustomModal({
    title: "Import",
    message:
      "Importing will take place in the background. You can use any part of Fearn, but please do not log out, refresh or close the window. This will cause the import to stop.",
    acceptTitle: "Continue",
    declineTitle: "Back",
    // eslint-disable-next-line no-unsafe-optional-chaining
    onAccept: () => callback(...paramsRef?.current),
  });

  return [
    (...params) => {
      paramsRef.current = params;
      onShowModal();
    },
    modal,
  ];
};

const NO_ADDITIONAL_FILTERS = [];
const NO_PRODUCTS = [];

const ImportModal = ({
  title,
  isShopNameComponent = false,
  isShowAllDropdown,
  isOpen,
  onClose,
  service,
  marketplaceType,
  products = NO_PRODUCTS,
  productFilters = NO_ADDITIONAL_FILTERS,
  shopName = "",
  children,
  isImportToCSV = false,
  onImportToCSV = () => {},
  status,
}) => {
  const [importingModal, updateImportingModal] = useState({
    isOpen: false,
    items: null,
  });

  const [filterByImported, cleanCache] = useImported(
    service.items,
    marketplaceType
  );

  const [search, updateSearch] = useState("");
  const [filter, updateFilter] = useState(null);

  const [importToCSVProducts, setImportToCSVProducts] = useState([]);

  const [isLoading, { registerPromise }] = useLoading();

  const mapFiltered = useRef({});
  const filterDuplicated = useCallback(
    (item, _, __, isFirst) => {
      const key = "listingId";
      const id = item[key];

      // Reset
      if (isFirst) {
        mapFiltered.current = {};
      }

      const isFiltered = !mapFiltered.current[id];

      mapFiltered.current[id] = true;

      return isFiltered;
    },
    [mapFiltered]
  );
  const isSold = status === SOLD_INVENTORY_LABEL;

  const filterByStatus = useCallback(
    (item, _, __, isFirst) => {
      let { isImported } = item;

      if (
        [MARKETPLACE_POSHMARK_TYPE, MARKETPLACE_MERCARI_TYPE].includes(
          marketplaceType
        ) ||
        (marketplaceType === MARKETPLACE_EBAY_TYPE && isSold)
      ) {
        isImported = filterByImported(item, isFirst);
        item.isImported = isImported;
      }

      const currentFilter = IMPORT_FILTERS_INVENTORY[filter];
      // All items
      if (currentFilter === null) return true;
      // Imported
      if (currentFilter) return isImported;
      // Not imported
      return isImported === false;
    },
    [filter, filterByImported, marketplaceType, isSold]
  );

  const filterBySearch = useCallback(
    ({ title }) => {
      if (!search) return true;
      return title.toLowerCase().includes(search.toLowerCase());
    },
    [search]
  );

  const filterByPrice = useCallback(({ price }) => {
    return !!price;
  }, []);

  const filters = useMemo(() => {
    return [
      filterByPrice,
      filterByStatus,
      filterBySearch,
      filterDuplicated,
      ...productFilters,
    ];
  }, [
    filterByStatus,
    filterBySearch,
    filterByPrice,
    filterDuplicated,
    productFilters,
  ]);

  // Set show settings
  useEffect(() => {
    if (!isOpen) return;

    const hasFilters = service.hasFilters();

    service.setFilters(filters);

    if (hasFilters && !service.processing) {
      registerPromise(service.validate());
    }
  }, [filters, service, registerPromise, isOpen]);

  // Load initial data
  useEffect(() => {
    if (isOpen) {
      const request = service.loadMore();
      registerPromise(request);
    } else {
      service.reset();
      cleanCache();
    }
  }, [isOpen, registerPromise, service, cleanCache]);

  // Reset search
  useEffect(() => {
    if (!isOpen && search) {
      updateSearch("");
    }
  }, [isOpen, search]);

  const handleChangeFilters = useCallback((filter) => {
    updateFilter(filter);
  }, []);

  // Sort
  const visibleItems = useMemo(() => {
    // Sort only sold and poshmark
    if (filter === SHOW_SOLD && marketplaceType === MARKETPLACE_POSHMARK_TYPE) {
      return [...products].sort(
        ({ $sort: first = 99_999 }, { $sort: second = 99_999 }) => {
          return first - second;
        }
      );
    }

    return products;
  }, [products, filter, marketplaceType]);

  const { checkAll, isCheckedAll, checkOne, getIsChecked, total } =
    useCheckedAll(visibleItems, PRODUCT_FIELD_ID_NAME[marketplaceType]);

  const handleUpdateLimit = useCallback(() => {
    registerPromise(service.loadMore());
  }, [service, registerPromise]);

  const handleUpdateLimitLoadAll = useCallback(() => {
    registerPromise(service.loadAll());
  }, [service, registerPromise]);

  const handleSearchChange = useCallback(
    (value) => {
      // Load all
      service.updateLoadAllLimit();

      updateSearch(value);
    },
    [service]
  );

  const numbersOfChecked = total;

  const handleStartImporting = useCallback(
    (items) => {
      onClose();
      updateImportingModal((prevState) => ({
        isOpen: !prevState.isOpen,
        items,
      }));
    },
    [onClose]
  );

  const dispatch = useDispatch();

  const updateCounts = useCallback(
    (callback) => {
      dispatch(changeImportCounters((prev) => callback(prev)));
    },
    [dispatch]
  );

  const handleImportToCSV = useCallback(() => {
    const productsTotalCount = importToCSVProducts.reduce(
      (totalCount, product) => {
        const productSize = product.size || 1;
        return totalCount + productSize;
      },
      0
    );

    updateCounts(({ total }) => {
      return {
        total: total + productsTotalCount,
      };
    });

    onImportToCSV(importToCSVProducts);

    updateImportingModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  }, [onImportToCSV, importToCSVProducts, updateCounts]);

  const [importAllFunctions, modalImportAll] = useImportCallback(
    useCallback(() => {
      const transformedItems = visibleItems.map((item) => {
        return {
          listingId: item.listingId.toString(),
          orderId: item.orderId,
        };
      });

      if (isImportToCSV) {
        setImportToCSVProducts(visibleItems);
        return handleStartImporting([]);
      }

      return handleStartImporting([...transformedItems]);
    }, [handleStartImporting, visibleItems, isImportToCSV])
  );

  const [importOneFunctions, modalOneImport] = useImportCallback(
    useCallback(
      (item, valueForCSV) => {
        if (isImportToCSV) {
          setImportToCSVProducts([valueForCSV]);
          return handleStartImporting([]);
        }

        return handleStartImporting([item]);
      },
      [isImportToCSV, handleStartImporting]
    )
  );

  const [importFewFunctions, modalFewImport] = useImportCallback(
    useCallback(
      (filteredItemsBySku, filteredItemsForCSV) => {
        if (isImportToCSV) {
          const filteredItems = filteredItemsForCSV.filter((item) =>
            getIsChecked(item)
          );

          setImportToCSVProducts(filteredItems);
          return handleStartImporting([]);
        }

        return handleStartImporting(filteredItemsBySku);
      },
      [isImportToCSV, handleStartImporting, getIsChecked]
    )
  );

  const [{ importing, productStatus }] = useStatelessProvider(ImportContext);

  const handleImport = useCallback(() => {
    updateCounts(({ total }) => {
      return {
        total: total + importingModal.items.length,
      };
    });

    importing(
      importingModal.items,
      MARKETPLACES_NAMES_REQUEST_BY_ID[marketplaceType],
      productStatus
    );
  }, [
    importing,
    marketplaceType,
    importingModal.items,
    updateCounts,
    productStatus,
  ]);

  const onConfirm = useCallback(() => {
    if (isImportToCSV) {
      handleImportToCSV();
      return;
    }

    updateImportingModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    handleImport();
  }, [isImportToCSV, handleImportToCSV, handleImport]);

  useEffect(() => {
    if (importingModal.isOpen) {
      onConfirm();
    }
  }, [importingModal.isOpen, onConfirm]);

  const disableButtons =
    !service?.hasMore() || isLoading || !visibleItems.length;

  return (
    <>
      <CustomModal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalHeader onClose={onClose}>{title}</ModalHeader>
        {modalImportAll}
        <ModalBody className={classNames("pt-3", "pb-4")}>
          <ImportHeader
            search={search}
            onChangeSearch={handleSearchChange}
            onStartImportAll={() => importAllFunctions()}
            isShopNameComponent={isShopNameComponent}
            shopName={shopName}
          >
            {children}
          </ImportHeader>

          <ImportFilters
            count={visibleItems.length}
            isCheckedAll={!!visibleItems.length && isCheckedAll}
            onChangeCheckedAll={checkAll}
            onChangeFilter={handleChangeFilters}
            isShowCustomDropdown={isShowAllDropdown}
          />
          {modalOneImport}
          {products.length ? ( //changed visibleItems to products
            <ImportItems
              items={isOpen ? products : []}
              marketplaceType={marketplaceType}
              search={search}
              onStartImporting={(items, valueForCSV) =>
                importOneFunctions(items, valueForCSV)
              }
              onChangeChecked={(changedIndex) => {
                checkOne(visibleItems[changedIndex]);
              }}
              checkIsChecked={getIsChecked}
            />
          ) : (
            <div className="card-import-container  d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                {isLoading ? <Spinner color="primary" /> : "No items found"}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {modalFewImport}
              {!!numbersOfChecked && (
                <CustomButton
                  className="filled-primary mr-4"
                  onClick={() => {
                    const filteredItems = visibleItems.filter((item) =>
                      getIsChecked(item)
                    );
                    const transformedItems = filteredItems.map((item) => {
                      return {
                        listingId: item.listingId.toString(),
                        orderId: item.orderId,
                      };
                    });
                    importFewFunctions(transformedItems, visibleItems);
                  }}
                >
                  Import ({numbersOfChecked})
                </CustomButton>
              )}

              {isLoading && (
                <>
                  Loading <DotsLoader />
                </>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <CustomButton
                className="outline-primary w-130 mr-3"
                disabled={disableButtons}
                onClick={handleUpdateLimit}
              >
                Load {service.getLimit()} items
              </CustomButton>
              <CustomButton
                className="outline-primary w-130"
                disabled={disableButtons}
                onClick={handleUpdateLimitLoadAll}
              >
                Load all
              </CustomButton>
            </div>
          </div>
        </ModalBody>
      </CustomModal>
    </>
  );
};

ImportModal.propTypes = {
  title: PropTypes.string,
  isShopNameComponent: PropTypes.bool,
  isShowAllDropdown: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  products: PropTypes.array,
  marketplaceType: PropTypes.number,
  shopName: PropTypes.string,
  isLoading: PropTypes.bool,
  isImportToCSV: PropTypes.bool,
  onImportToCSV: PropTypes.func,
  service: PropTypes.any,
};

export default ImportModal;
