import Bugsnag from "@bugsnag/js";

import Http from "./Http";
import { createCacheAdapter } from "./adapters/cacheAdapter";
import { createFullTextSearch } from "../base/helpers/categories";

const categoriesMap = {
  isEmpty: true,
};

const categoriesForSearch = [];

function fillCategories(
  categories,
  parentId = null,
  prevPath = "",
  prevArr = []
) {
  categories.forEach((category) => {
    const { id, children, name } = category;
    category.id = id;
    category.name = name;
    category.prevPath = prevPath;
    category.prevArr = prevArr;

    categoriesMap[id] = category;
    categoriesForSearch.push(category);

    if (children) {
      return fillCategories(
        children,
        id,
        [category.prevPath, name].filter(Boolean).join("/"),
        [
          ...category.prevArr,
          {
            id: id,
            name: name,
          },
        ]
      );
    }
  });

  categoriesMap.isEmpty = false;
}

const getCategoriesFromSearch = (categoriesForSearch = [], query = "") => {
  const searchIn = createFullTextSearch(query, true);
  return searchIn(
    categoriesForSearch,
    ({ name, prevPath }) => `${prevPath || ""} ${name}`
  )
    .map((data) => {
      data.parent = categoriesMap[data.parent_id];

      if (!data.parent_id) return data;

      data.parentId = data.parent_id;
      data.parent.id = data.parent_id;

      return data;
    })
    .slice(0, 50);
};

class EtsyService extends Http {
  static $displayName = "EtsyService";

  getCachedCategories() {
    return categoriesMap;
  }

  async getCategories(query, parentId) {
    const cacheAdapter = await createCacheAdapter();

    return this.get("/etsy-accounts/taxonomies", {
      adapter: cacheAdapter.adapter,
    })
      .then((data) => {
        const { results } = data;

        if (categoriesMap.isEmpty) {
          fillCategories(results);
        }

        if (query) {
          return getCategoriesFromSearch(categoriesForSearch, query);
        }

        if (parentId && categoriesMap[parentId]) {
          return categoriesMap[parentId]?.children || [];
        }

        return data.results;
      })
      .catch((e) => Bugsnag.notify(e));
  }

  getParentCategories(categoryId) {
    return this.getCategories()
      .then(() => {
        const map = this.getCachedCategories();

        function findAllParentCategories(parentId, collection) {
          const category = map[parentId];

          if (!category) return collection;

          collection.unshift(category);

          return findAllParentCategories(category?.parent_id, collection);
        }

        const parentId = map[categoryId]?.parent_id;

        return {
          onInitBreadcrumbs: () => findAllParentCategories(parentId, []),
          initialValue: map[categoryId],
        };
      })
      .catch((e) => Bugsnag.notify(e));
  }

  getProperties(id) {
    const PRIMARY_COLOR = 200;

    const excludeProperties = {
      [PRIMARY_COLOR]: true,
    };

    return this.get(`/etsy-accounts/taxonomies/${id}/properties`)
      .then(({ data }) => {
        const list = data.filter(
          ({ supportsAttributes, propertyId }) =>
            supportsAttributes && !excludeProperties[propertyId]
        );
        return { data: list };
      })
      .catch((e) => Bugsnag.notify(e));
  }

  getTemplates() {
    return this.get("/etsy-accounts/shipping-templates");
  }

  postProduct(id) {
    return this.post(`/products/${id}/etsy`);
  }

  updateLastSyncedDate(newDate) {
    return this.put("/etsy-accounts/lastSyncedAt", {
      lastSyncedAt: newDate,
    });
  }
}

export default EtsyService;
