/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";

import ChangeSubscriptionModal from "../components/ChangeSubscriptionModal";
import SubscriptionModal from "../components/SubscriptionModal";

import { getCurrentSubscriptionType } from "../../../helpers/getCurrentSubscriptionType";
import { getAvailableListingFromType } from "../../../helpers/getLimitFromSubscriptionType";
import { useLimitationEvent } from "../../../hooks/useSocketLimitationEvent";
import { useGetCounterDraft } from "../../../hooks/useGetCounterDraft";

const SubscriptionChange = ({ importedCount }) => {
  const subscriptionType = getCurrentSubscriptionType();
  const availableListing = getAvailableListingFromType(subscriptionType);
  const handleGetCounter = useGetCounterDraft();

  const [isOpenModal, updateIsOpenModal] = useState(false);
  const [isOpenNextModal, updateIsOpenNextModal] = useState(false);
  const [chosenSubscription, updateChosenSubscription] = useState(null);

  const handleToggleModal = () => updateIsOpenModal((isOpen) => !isOpen);

  const handleChoseSubscribe = useCallback((subscription) => {
    updateChosenSubscription(subscription);
  }, []);

  const handleToogleNextModal = useCallback((toggleClose) => {
    updateIsOpenNextModal((isOpen) => (toggleClose ? false : !isOpen));
  }, []);

  const handleNextStep = useCallback(() => {
    handleToggleModal();
    handleToogleNextModal();
  }, [handleToogleNextModal]);

  const handleBackStep = useCallback(() => {
    handleToggleModal();
    handleToogleNextModal();
  }, [handleToogleNextModal]);

  const handleEvent = useCallback(async () => {
    updateIsOpenModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableListing, handleGetCounter]);

  useLimitationEvent(handleEvent);

  return (
    <>
      <SubscriptionModal
        isOpen={isOpenModal}
        handleToggleModal={handleToggleModal}
        chosenSubscription={chosenSubscription}
        handleChoseSubscribe={handleChoseSubscribe}
        handleNextStep={handleNextStep}
        textButton="Change"
        text={`${importedCount} active inventory items were successfully imported. To import more items, please upgrade your subscription plan.`}
        isAbilityClose={true}
        isLimits={true}
      />
      <ChangeSubscriptionModal
        isOpen={isOpenNextModal}
        onBackStep={handleBackStep}
        chosenSubscription={chosenSubscription}
        onClose={handleToogleNextModal}
      />
    </>
  );
};

export default SubscriptionChange;
