import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import CreditCardService from "../../../../../../../services/CardService";
import { PAYMENT_LINKS } from "../config";
import { useService } from "../../../../../../../base/hooks/useService";
import ToasterService from "../../../../../../../services/ToastService";
import Bugsnag from "@bugsnag/js";

export const useStripeSubmit = (callback) => {
  const creditCardService = useService(CreditCardService);
  const [isLoading, updateIsLoading] = useState(false);

  const toastr = useService(ToasterService);

  const { push } = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const handleCancel = useCallback(() => {
    push(PAYMENT_LINKS.VIEW);
  }, [push]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      updateIsLoading(true);

      if (!stripe || !elements) return;

      const cardNumber = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardNumber, { name });

      if (error) {
        // TODO ERROR
        updateIsLoading(false);
        return;
      }

      const { id: stripeCardId } = token;

      try {
        await creditCardService.addCard({ stripeCardId });
        if (callback || typeof callback === "function") {
          await callback();
        }
        handleCancel();
      } catch (baseError) {
        Bugsnag.notify(baseError);
        const { error } = baseError;
        toastr.error(error.message);
        updateIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, stripe, callback, creditCardService, handleCancel]
  );

  return { handleSubmit, handleCancel, isLoading };
};
