import {SUBSCRIPTIONS_STATUSES} from "../../drafts/pages/Settings/page/EmployeeManagement/constants";
import {MAX_ACTIVE_INVENTORY_COUNT} from "../constants";

export const getAvailabilityCreatingListing = (currentTotalListings) => {
    return {
        [SUBSCRIPTIONS_STATUSES.trial]: true, // 1
        [SUBSCRIPTIONS_STATUSES.standard]:
        currentTotalListings < MAX_ACTIVE_INVENTORY_COUNT.standard, // 2
        [SUBSCRIPTIONS_STATUSES.business]:
        currentTotalListings < MAX_ACTIVE_INVENTORY_COUNT.business, // 3
        [SUBSCRIPTIONS_STATUSES.unlimited]: true, // 4
        [SUBSCRIPTIONS_STATUSES.expired]: false, // -1
    };
}