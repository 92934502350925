import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { PRODUCT_TYPE } from "../../../base/constants/product";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";

export const useProduct = (
  status = PRODUCT_TYPE.DRAFT,
  limit = 10,
  markToUpdate = null
) => {
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  return useCallback(
    ({
      orderType,
      orderBy,
      offset,
      query: q,
      marketplaceType,
      isExcludeListedToAll,
    }) => {
      const marketplaceTypes = [];
      if (marketplaceType) {
        marketplaceTypes.push(marketplaceType);
      }
      if (status === PRODUCT_TYPE.ACTIVE) {
        isExcludeListedToAll = false;
      }

      return draftService.getListDrafts({
        limit,
        orderBy,
        orderType,
        offset,
        q,
        status,
        marketplaceTypes,
        isExcludeListedToAll,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draftService, status, limit, markToUpdate]
  );
};

export const useTotalProducts = (type) => {
  const load = useProduct(type, 1);
  const [state, update] = useState({ total: 0, load: true });

  useEffect(() => {
    load({})
      .then(({ pagination }) => {
        update({ load: false, total: pagination.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [load]);

  return [state, update];
};
