import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import TableHeader from "./components/TableHeader";
import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import AvailableInventoryUtils from "../../utils/utils";
import {
  groupByList,
  orderByList,
  DEFAULT_GROUP_BY,
  DEFAULT_ORDER_BY,
} from "../../constants/analyticsAvailableInventory";
import styles from "./index.module.scss";
import List from "./components/List/List";
import { Spinner } from "reactstrap";

const AvailableInventory = () => {
  const [items, setItems] = useState([]);
  const [groupBy, setGroupBy] = useState(DEFAULT_GROUP_BY);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);
  useEffect(() => {
    analytics
      .getAvailableInventory({ groupBy, orderBy })
      .then((response) => {
        const initialData = response.data;
        const data = AvailableInventoryUtils.completeData(initialData);
        setItems(data);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy, orderBy]);

  const onSelectGroup = (item) => {
    setGroupBy(item);
  };

  const onSelectOrder = (item) => {
    setOrderBy(item);
  };

  return (
    <div className="mt-3">
      <div className="m-0 row">
        <div className="p-0 col-lg-12">
          <div className="card">
            <div className={styles.inventoryCard}>
              <TableHeader
                headerProps={{
                  onSelectGroup,
                  onSelectOrder,
                  groupByList,
                  orderByList,
                }}
              />
              {items.length > 0 ? (
                <List orderBy={orderBy} data={items} />
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner
                    style={{
                      width: "48px",
                      height: "48px",
                      color: "rgba(47, 102, 92, 1)",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableInventory;
