import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import style from "./index.module.scss";
import s from "../../components/TotalOverviewPerPlatform/Overview/index.module.scss";
import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import { LABELS } from "../../constants/analisticsSalesPerPlatform";

import { DounatChartWithLabels } from "../../../../base/components/Charts";
import Utils from "../../utils/utils";
import { templateOfSalesData } from "../../constants/analisticsSalesPerPlatform";
import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
  MARKETPLACE_OTHER_TYPE,
} from "../../../inventory/constants/importInventory";
import styles from "../../../../base/components/Charts/index.module.scss";
import { separateThousandthFloored } from "../../helpers/separateThousandth";
import TitleWrapper from "../TitleWrapper";
import AnalyticRangeDateFilter from "../AnalyticRangeDateFilter";
import AnalyticRangeDateInput from "../AnalyticRangeDateInput";
import OverviewItem from "./OverviewItem";
import { useAnalyticRangeDateFilter } from "../../hooks/useAnalyticRangeDateFilter";

import { MOMENT_MONTH } from "../../../bookkeeping/constants";
import { ANALYTICS_COLORS } from "../../../../base/components/Charts/params";

const WEIGHTS = {
  [MARKETPLACE_OTHER_TYPE]: 4,
  [MARKETPLACE_EBAY_TYPE]: 5,
  [MARKETPLACE_MERCARI_TYPE]: 6,
  [MARKETPLACE_ETSY_TYPE]: 7,
  [MARKETPLACE_POSHMARK_TYPE]: 8,
};

const TotalOverviewPerPlatform = () => {
  const [data, setData] = useState([]);
  const [dateFilter, { onChangeDateFilter, formatDate }, dateFilterRef] =
    useAnalyticRangeDateFilter({
      startDate: moment().startOf(MOMENT_MONTH).toDate(),
      endDate: moment().endOf(MOMENT_MONTH).toDate(),
    });

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  useEffect(() => {
    analytics
      .getSalesPerPlatform({
        startDate: formatDate(dateFilter.dateRange.startDate),
        endDate: formatDate(dateFilter.dateRange.endDate),
      })
      .then((response) => {
        const currentData = response.data.marketplacesSales;
        const concatedData = Utils.concatDataArrays(
          templateOfSalesData,
          currentData
        );
        const sortedData = concatedData.sort((prev, next) =>
          WEIGHTS[prev.marketplaceType] > WEIGHTS[next.marketplaceType] ? -1 : 1
        );
        const dataWithLabels = sortedData.map((item) => {
          item.label = LABELS[item.marketplaceType];
          return item;
        });
        setData(dataWithLabels);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter.dateRange]);

  const total = useMemo(() => {
    return Utils.setTotalSales(data);
  }, [data]);

  const percentsChartData = useMemo(() => {
    return Utils.setFractionsArray(data, total);
  }, [data, total]);

  return (
    <Card>
      <CardBody className={"p-0"}>
        <div className={style.container}>
          <div className={style.chartColumn}>
            {percentsChartData.length > 0 && (
              <DounatChartWithLabels
                data={percentsChartData}
                classNameChart={styles.chart}
                colors={ANALYTICS_COLORS}
              >
                <>
                  <p className={styles.chartInnerLabel}>{"Total:"}</p>
                  <p className={styles.chartTotal}>
                    {separateThousandthFloored(total)}
                  </p>
                </>
              </DounatChartWithLabels>
            )}
          </div>
          <div className={style.overviewColumn}>
            <div className={s.headerContainer}>
              <TitleWrapper title={"Overview"}>
                <div className={s.calendarContainer}>
                  <AnalyticRangeDateFilter
                    dateRange={useMemo(
                      () => [
                        dateFilter.dateRange.startDate,
                        dateFilter.dateRange.endDate,
                      ],
                      [dateFilter]
                    )}
                    dateRangeType={dateFilter.dateRangeType}
                    onChange={onChangeDateFilter}
                    CustomInput={AnalyticRangeDateInput}
                    dateFilterRef={dateFilterRef}
                  />
                </div>
              </TitleWrapper>
              <OverviewItem data={data} />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TotalOverviewPerPlatform;
