/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import ImportModal from "../../../../../base/components/ImportModal";
import { MARKETPLACES } from "../../../../../base/constants/extension";
import { useService } from "../../../../../base/hooks/useService";
import ImportPoshmark from "../../../../../services/importServices/ImportPoshmark";
import PoshmarkExtension from "../../../../../services/PoshmarkExtension";
import { SOLD_INVENTORY_LABEL } from "../../../constants";
import { useImportPoshmarkCSV } from "../../../hooks/useImportPoshmarkCSV";
import { ImportPopupPoshmarkService } from "../../../services";
import { useImportService } from "../EbayImportModal";
import ErrorMarketplaceConnection from "../EbayImportModal/ErrorEbayConnection";

const PoshmarkImportModal = (props) => {
  const { data, isOpen, status } = props;

  const [shopName, setShopName] = useState("Loading...");

  const [{ formatProducts, onImportToCSVFile }] = useImportPoshmarkCSV();

  /**
   * @type {MercariExtension}
   */
  const poshmarkExtension = useService(PoshmarkExtension);

  const loadProfile = useCallback(() => {
    poshmarkExtension
      .getProfile()
      .then((response) => {
        const { username } = response;
        return username;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return "-";
      })
      .then(setShopName);
  }, [poshmarkExtension]);

  useEffect(() => {
    if (!isOpen) return;
    loadProfile();
  }, [isOpen, loadProfile]);

  /**
   * @type {ImportPoshmark}
   */
  const importPoshmark = useService(ImportPoshmark);

  const loadData = useCallback(
    (tabId, pagination) => {
      if (status === SOLD_INVENTORY_LABEL) {
        return importPoshmark
          .importSoldProducts(tabId, pagination)
          .then((response) => {
            const {
              result: { data, pagination },
            } = response;

            return {
              data: formatProducts(data, SOLD_INVENTORY_LABEL),
              pagination,
              hasNextPage: pagination.nextPage,
            };
          })
          .catch((e) => Bugsnag.notify(e));
      }

      return importPoshmark
        .importActiveProducts(tabId, pagination)
        .then(({ result: { data, pagination } }) => ({
          data: formatProducts(data),
          pagination,
          hasNextPage: pagination.nextPage,
        }))
        .catch((e) => Bugsnag.notify(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importPoshmark, formatProducts, status]
  );

  const [service] = useImportService(
    ImportPopupPoshmarkService,
    loadData,
    MARKETPLACES.POSHMARK
  );

  if (!data) {
    return <ErrorMarketplaceConnection {...props} name={"Poshmark"} />;
  }

  return (
    <ImportModal
      title="Import from Poshmark"
      products={service.getVisibleItems()}
      shopName={shopName}
      isImportToCSV={true}
      onImportToCSV={onImportToCSVFile}
      isShopNameComponent
      isShowAllDropdown
      service={service}
      {...props}
    />
  );
};

export default PoshmarkImportModal;
