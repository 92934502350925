import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import crypto from "crypto-js";

import { useService } from "../../../base/hooks/useService";
import StorageService from "../../../services/StorageService";
import SessionStorage from "../../../services/SessionStorage";
import { KEY_USER } from "../../../base/constants/storage";
import { APP_GROUP_LINKS } from "../../app/config";
import { COMPLETE_PROFILE_GROUP_LINKS } from "../../completeProfile/config";
import { ROLES } from "../constants/roles";
import { DASHBOARD_GROUP_LINKS } from "../../dashboard/config";
import { COMPLETED_REGISTRATION_STATUS } from "../constants/registration";

const DEFAULT_COMPLETED_STATUS = 4;

const redirectByStatuses = {
  1: COMPLETE_PROFILE_GROUP_LINKS.STEP_1,
  2: COMPLETE_PROFILE_GROUP_LINKS.STEP_2,
  3: COMPLETE_PROFILE_GROUP_LINKS.STEP_3,
  4: APP_GROUP_LINKS.BASE,
};

const redirectEmployeeByStatuses = {
  1: COMPLETE_PROFILE_GROUP_LINKS.STEP_1,
  2: COMPLETE_PROFILE_GROUP_LINKS.STEP_2,
  3: COMPLETE_PROFILE_GROUP_LINKS.STEP_2,
  4: APP_GROUP_LINKS.BASE,
};

export const getRedirectByRole = (role, step) =>
  role === ROLES.employee
    ? redirectEmployeeByStatuses[step]
    : redirectByStatuses[step];

export const useAfterLogin = () => {
  const { push } = useHistory();
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const redirect = useCallback(
    ({ data: { user, session } }) => {
      const currentStatusId =
        user.registrationStatus ?? DEFAULT_COMPLETED_STATUS;
      const redirectAfterLogin =
        getRedirectByRole(user.role, currentStatusId) || APP_GROUP_LINKS.BASE;

      storage.remove(KEY_USER);
      storage.set(KEY_USER, user);
      storageSession.setRememberMe(true);
      storageSession.setSession(session);

      const secretKey = "SngEtBySW6mrYrhMKYB6hkJrDaFbm-dMXycIwUp9";
      const userIdentifier = user.email;
      const hash = crypto
        .HmacSHA256(userIdentifier, secretKey)
        .toString(crypto.enc.Hex);

      window.Intercom("update", {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        created_at: user.createdAt,
        user_hash: hash,
      });

      if (currentStatusId === COMPLETED_REGISTRATION_STATUS) {
        push(DASHBOARD_GROUP_LINKS.BASE);
      } else {
        push(redirectAfterLogin);
      }
    },
    [push, storage, storageSession]
  );

  return {
    redirect,
  };
};
