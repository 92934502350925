import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import FormikInput from "../../../../base/components/FormikInput";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import FormikSingleSelect from "../../../../base/components/FormikSingleSelect";
import InfoPriceItem from "../../../drafts/hooks/useProductStatus/components/SoldModal/components/InfoPriceItem";
import { useService } from "../../../../base/hooks/useService";
import ExpensesService from "../../../../services/ExpensesService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { toNumber } from "../../../sold/helpers";

import { formFields, initialValues, validationSchema } from "./form";
import styles from "./index.module.scss";
import UploadImage from "../UploadImage";
import { useExpensesImage } from "../../hooks/useUploadExpensesImage";

import { getFormattedCategories } from "../../utils";
import {
  MAX_DESCRIPTION,
  MAX_EXPENSE_PRICE,
  MAX_TITLE,
  MIN_DESCRIPTION,
  MIN_TITLE,
} from "./constants";
import { CategoryOption } from "./components/CategoryOption";
import CategoryInput from "./components/CategoryInput";
import DateField from "../DateField";
import { getCategoryIdFromLabel } from "../../hooks/useExpensesCategories";

const defaultModalStrings = {
  title: "Expense creation",
  closeBtn: "Cancel",
  confirmBtn: "Save",
};

const CreateExpenseModal = ({
  isOpen,
  onClose,
  afterSubmit,
  modalStrings = defaultModalStrings,
  onSubmit,
  categories,
  data,
}) => {
  const [fullCategoryData, setFullCategoryData] = useState({});
  const [loading, { registerPromise }] = useLoading();

  const [
    onUploadImage,
    onDeleteImage,
    selectedImage,
    formRef,
    errorMessage,
    isLoadingImage,
  ] = useExpensesImage({
    formFieldName: formFields.imageId,
    initImage: data?.[formFields.imageId].preview,
  });

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const onCreateExpense = useCallback(() => {
    const values = {
      [formFields.title]: formRef.current?.values[formFields.title],
      [formFields.description]: formRef.current?.values[formFields.description],
      [formFields.category]: fullCategoryData?.label,
      [formFields.price]: toNumber(formRef.current?.values[formFields.price]),
      [formFields.expenseMadeAt]:
        formRef.current?.values[formFields.expenseMadeAt],
      [formFields.imageId]: toNumber(
        formRef.current?.values[formFields.imageId]
      ),
    };

    const formattedValues = Object.entries(values).filter(
      (field) => !!field[1]
    );

    registerPromise(
      expensesService.createExpense(Object.fromEntries(formattedValues))
    )
      .then(() => {
        onClose();
        onDeleteImage();
        afterSubmit();
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerPromise,
    expensesService,
    formRef,
    onClose,
    afterSubmit,
    fullCategoryData,
  ]);

  useEffect(() => {
    const setValues = formRef.current?.setValues;
    if (!setValues) return;

    setFullCategoryData({
      label: data?.[formFields.category],
      id: undefined,
    });

    setValues({
      [formFields.title]: data?.title,
      [formFields.description]: data?.description,
      [formFields.category]: getCategoryIdFromLabel(
        data?.[formFields.category],
        categories
      ),
      [formFields.price]: data?.price,
      [formFields.expenseMadeAt]: data?.expenseMadeAt,
      ...(data?.memo && { [formFields.memo]: data?.memo }),
      [formFields.imageId]: data?.[formFields.imageId].id,
    });
  }, [data, formRef, categories]);

  return (
    <>
      <CustomModal isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={data || initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          innerRef={formRef}
          onSubmit={() => {}}
        >
          {({
            handleSubmit,
            values,
            errors,
            dirty,
            setFieldValue,
            touched,
          }) => {
            const isErrors = !isEmpty(errors);
            const titleIsEmpty = values?.[formFields.title] === "";
            const categoryIsEmpty = values?.[formFields.category] === "";
            const priceIsEmpty = values?.[formFields.price] === undefined;

            const isDisableSave =
              isErrors ||
              titleIsEmpty ||
              categoryIsEmpty ||
              priceIsEmpty ||
              loading ||
              isLoadingImage;

            return (
              <>
                <ModalHeader onClose={onClose}>
                  {modalStrings.title}
                </ModalHeader>
                <ModalBody>
                  <form
                    className="form-horizontal expense-form"
                    onSubmit={handleSubmit}
                  >
                    <FormikInput
                      id={formFields.title}
                      name={formFields.title}
                      label="Title*"
                      containerClassName="mb-4"
                      placeholder="Add title"
                      min={MIN_TITLE}
                      max={MAX_TITLE}
                      maxLength={MAX_TITLE}
                    />
                    <FormikTextarea
                      id={formFields.description}
                      name={formFields.description}
                      label="Description"
                      rows="3"
                      placeholder="Add description"
                      min={MIN_DESCRIPTION}
                      max={MAX_DESCRIPTION}
                      maxLength={MAX_DESCRIPTION}
                    />
                    <FormikSingleSelect
                      name={formFields.category}
                      options={getFormattedCategories(categories)}
                      label="Category*"
                      containerClassName="mb-4"
                      allowCreate
                      placeholder="Choose category"
                      CustomOption={CategoryOption}
                      CustomInput={CategoryInput}
                      onChange={(value) => setFullCategoryData(value)}
                    />
                    <InfoPriceItem
                      title="Price*"
                      value={values[formFields.price]}
                      name={formFields.price}
                      onChange={(value) => {
                        setFieldValue(formFields.price, value);
                      }}
                      isRequired
                      isColumnView
                      max={MAX_EXPENSE_PRICE}
                      placeholder={"00.00"}
                    />
                    <label>Date</label>
                    <div className={"mb-4"}>
                      <DateField
                        name="expenseMadeAt"
                        id="expenseMadeAt"
                        containerClassName={"date-filter"}
                        selected={values[formFields.expenseMadeAt]}
                        maxDate={new Date()}
                      />
                    </div>
                    <UploadImage
                      containerClassName={styles.uploadImageWrapper}
                      onUpload={onUploadImage}
                      onDelete={onDeleteImage}
                      image={selectedImage?.preview}
                    >
                      Upload Image
                    </UploadImage>
                    {errorMessage && (
                      <span className={styles.imageError}>{errorMessage}</span>
                    )}
                  </form>
                </ModalBody>
                <ModalFooter>
                  <CustomButton
                    onClick={(event) => onClose(event, dirty && touched)}
                    className="outline-primary "
                  >
                    {modalStrings.closeBtn}
                  </CustomButton>
                  <CustomButton
                    onClick={(event) =>
                      data
                        ? onSubmit(values, fullCategoryData, event)
                        : onCreateExpense(values, event)
                    }
                    disabled={!!isDisableSave}
                    className="filled-primary "
                  >
                    {modalStrings.confirmBtn}
                  </CustomButton>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </CustomModal>
    </>
  );
};

export default CreateExpenseModal;
