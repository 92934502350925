import { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import EbayService from "../../../services/EbayService";
import { useFormatEbayDomesticShippingServices } from "./useFormatEbayDomesticShippingServices";

export const useEbayFlatDomesticShippingServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [flatShippingServices, setFlatShippingServices] = useState([]);

  const { getFormattedServices, filterDomesticServices } =
    useFormatEbayDomesticShippingServices();

  const getFlatDomesticShippingServices = async () => {
    try {
      setIsLoading(true);

      new EbayService().getShippingData().then((response) => {
        const domesticServices = filterDomesticServices(response);
        const flatServices = filterFlatServices(domesticServices);
        const formattedFlatDomesticServices =
          getFormattedServices(flatServices);

        setFlatShippingServices(formattedFlatDomesticServices);
      });
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterFlatServices = (data) => {
    const flatServices = data.filter(
      (service) => service.serviceType?.indexOf("Flat") !== -1
    );

    return flatServices;
  };

  useEffect(() => {
    getFlatDomesticShippingServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [flatShippingServices, isLoading];
};
