import { useCallback, useEffect, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useExtensionInstall } from "./Poshmark/useExtensionInstall";
import { useService } from "../../../base/hooks/useService";
import {
  profile_MERCARI,
  WINDOW_CONFIG_POSHMARK,
  WINDOW_NAME_POSHMARK,
} from "../constants/connectButton";
import MercariExtension from "../../../services/MercariExtension";
import MercariService from "../../../services/MercariService";

let lastMarketplaceId = "";

export const useMercariIsConnect = () => {
  const [isConnect, updateIsConnect] = useState(false);
  const { isInstalled } = useExtensionInstall();
  const [isPending, updateIsPending] = useState(false);
  const windowRef = useRef();

  /**
   * @type {MercariExtension}
   */
  const mercari = useService(MercariExtension);

  /**
   * @type {MercariService}
   */
  const mercariService = useService(MercariService);

  const saveMarketplaceId = useCallback(() => {
    mercari
      .getProfile()
      .then(({ data }) => {
        if (!data) return;

        const id = data.user.id.toString();

        if (id !== lastMarketplaceId) {
          mercariService.saveMarketplaceAccount({ accountId: id });
        }

        lastMarketplaceId = id;
      })
      .catch((e) => {
        Bugsnag.notify(e);
      });
  }, [mercari, mercariService]);

  const handleSignIn = useCallback(async () => {
    await mercari.logOut();
    const openedWindow = window.open(
      profile_MERCARI,
      WINDOW_NAME_POSHMARK,
      WINDOW_CONFIG_POSHMARK
    );

    windowRef.current = openedWindow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstalled, isConnect, windowRef]);

  useEffect(() => {
    const { unSubscribe } = mercari.onCompletedAuth((data) => {
      if (data?.err) return;
      updateIsConnect(true);
      updateIsPending(true);

      saveMarketplaceId();

      if (windowRef.current) {
        setTimeout(() => windowRef.current.close(), 3000);
      }
    });

    return unSubscribe;
  }, [mercari, windowRef, saveMarketplaceId]);

  useEffect(() => {
    const { unSubscribe } = mercari.onCompletedLogout(() =>
      updateIsConnect(false)
    );
    return unSubscribe;
  }, [mercari]);

  useEffect(() => {
    mercari
      .isConnected()
      .then((resp) => {
        if (!resp.result?.errors) {
          updateIsConnect(true);
          const {
            result: { authToken },
          } = resp;
          localStorage.setItem("mercariAuthToken", authToken);
        } else {
          localStorage.removeItem("mercariAuthToken");
          updateIsConnect(false);

          throw new Error(
            `Mercari connection: ${resp.result.errors[0]?.message}`
          );
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        updateIsConnect(false);
      });
  }, [mercari]);

  return {
    handleSignIn,
    isConnected: isConnect && isInstalled,
    isPending,
    updateIsPending,
    isInstalled,
  };
};
