import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";

export const useCopyDraft = (afterCopy) => {
  const [draftToCopy, updateDraftToCopy] = useState(null);
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  const onCopy = useCallback((id) => {
    updateDraftToCopy(id);
  }, []);

  const cancel = useCallback(() => {
    updateDraftToCopy(null);
  }, []);

  const copyDraft = useCallback(() => {
    const id = draftToCopy;

    draftService
      .copyDraft(id)
      .then(() => {
        afterCopy(id);
      })
      .catch((e) => Bugsnag.notify(e));

    cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftToCopy, cancel, draftService]);

  const view = (
    <CustomModal isOpen={!!draftToCopy} onClose={cancel}>
      <ModalHeader onClose={cancel}>Confirmation</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          Do you want to copy this item? It can influence your current
          subscription plan.
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={cancel} className="outline-primary ">
          No
        </CustomButton>

        <CustomButton onClick={copyDraft} className="filled-primary ">
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return {
    view,
    onCopy,
  };
};
