import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import Table from "../../../../base/components/Table";

import { useService } from "../../../../base/hooks/useService";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import AnalyticsService from "../../../../services/AnalyticsService";
import SoldInventoryUtils from "../../utils/utils";
import {
  groupByList,
  orderByList,
  DEFAULT_GROUP_BY,
  DEFAULT_ORDER_BY,
} from "../../constants/analyticsSoldInventory";

import TableHeader from "./components/TableHeader";
import { TableStructureCount, TableStructurePrice } from "./table";
import styles from "./index.module.scss";
import { Spinner } from "reactstrap";

const SoldInventory = () => {
  const [items, setItems] = useState([]);
  const [groupBy, setGroupBy] = useState(DEFAULT_GROUP_BY);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);

  const {
    params: { startDate, endDate },
  } = useLocationQuery();

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  useEffect(() => {
    analytics
      .getSoldInventory({ groupBy, orderBy, startDate, endDate })
      .then((response) => {
        const initialData = response.data;
        const data = SoldInventoryUtils.completeData(initialData);
        setItems(data);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy, orderBy, endDate]);

  const onSelectGroup = (item) => {
    setGroupBy(item);
  };

  const onSelectOrder = (item) => {
    setOrderBy(item);
  };

  return (
    <div className="mt-3">
      <Table
        classes={styles.inventoryCard}
        data={items}
        HeaderComponent={TableHeader}
        dataStructure={
          orderBy === DEFAULT_ORDER_BY
            ? TableStructureCount
            : TableStructurePrice
        }
        headerProps={{ onSelectGroup, onSelectOrder, groupByList, orderByList }}
        noDataMessage={
          <div className="d-flex justify-content-center mt-3">
            <Spinner
              style={{
                width: "48px",
                height: "48px",
                color: "rgba(47, 102, 92, 1)",
              }}
            />
          </div>
        }
      />
    </div>
  );
};

export default SoldInventory;
