/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

import FormPartials from "../../classes/FormPartials";
import { FORM_TYPES } from "../../const/form";
import { getCrosslistingChanges } from "../SyncFormModels";
import Associations from "../../classes/Associations";

export const DraftFormMonitor = ({ form, updateFullForm }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const formHash = JSON.stringify(form); // using a string hash

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);

      // setting up initial values if it doesn't exist
      if (!form.productEbaySpecificFields) {
        const ebayForm = new FormPartials(FORM_TYPES.EBAY);
        const initialEbayForm = Object.assign({}, ebayForm.initialValues);
        form.productEbaySpecificFields = initialEbayForm;
      }

      if (!form.productPoshmarkSpecificFields) {
        const poshmarkForm = new FormPartials(FORM_TYPES.POSHMARK);
        const initalPoshmarkForm = Object.assign(
          {},
          poshmarkForm.initialValues
        );
        form.productPoshmarkSpecificFields = initalPoshmarkForm;
      }

      if (!form.productEtsySpecificFields) {
        const etsyForm = new FormPartials(FORM_TYPES.ETSY);
        const initialEtsyForm = Object.assign({}, etsyForm.initialValues);
        form.productEtsySpecificFields = initialEtsyForm;
      }

      if (!form.productMercariSpecificFields) {
        const mercariForm = new FormPartials(FORM_TYPES.MERCARI);
        const initialMercariForm = Object.assign({}, mercariForm.initialValues);
        form.productMercariSpecificFields = initialMercariForm;
      }

      updateFullForm(form);

      return;
    }

    const changes = getCrosslistingChanges(form, true);
    Associations.applyChanges(form, changes);

    updateFullForm(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHash]);

  return null;
};
