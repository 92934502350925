/* eslint-disable react/prop-types */
import { useEffect } from "react";

export const MarketplaceFormMonitor = ({
  item,
  formKey,
  localForm,
  updateFullForm,
  touchedFields,
}) => {
  const localFormHash = JSON.stringify(localForm);

  useEffect(() => {
    const itemCopy = JSON.parse(JSON.stringify(item));

    Object.keys(touchedFields).forEach((field) => {
      const newFormValue = localForm[field];

      if (itemCopy?.[formKey]?.[field] !== newFormValue && newFormValue) {
        itemCopy[formKey][field] = newFormValue;
      }

      // because files are handled directly on the basic form
      // all our code references only basic form for images only
      if (field === "files") {
        itemCopy[field] = newFormValue;
      }
    });

    updateFullForm(itemCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchedFields, localFormHash]);

  return null;
};
