/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  memo,
  useRef,
  useLayoutEffect,
  useMemo,
  useEffect,
} from "react";

import { RightCategory } from "./RightCategory";
import { Box } from "./Box";
import { ItemTypes } from "./ItemTypes";

import "./index.css";
import { VirtualTable } from "./VTable";
import Button from "../../../base/components/Button";
import { SearchInput } from "../../drafts/components/Search";
import PreventClosePage from "../../drafts/components/PreventClosePage";
import Checkbox from "../../../base/components/Checkbox";
import Bugsnag from "@bugsnag/js";

function download(content, fileName, contentType) {
  var a = document.createElement("a");
  var file = new Blob([content], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}
const createSearch = (query) => {
  const queryLowerCase = query
    .split(" ")
    .map((word) => word.trim())
    .filter((name) => name)
    .map((name) => `(?=.*${name})`)
    .join("");
  const regQueryLowerCase = new RegExp(queryLowerCase, "gi");
  return regQueryLowerCase;
};
export const Container = memo(function Container({ form, categories, basic }) {
  const [rightCategories, setRightCategories] = useState([]);

  useEffect(() => {
    setRightCategories(
      categories.map((data) => {
        return {
          ...data,
          accepts: [ItemTypes.GLASS],
        };
      })
    );
  }, [categories]);

  const [searchR, updateSearchR] = useState("");
  const [searchL, updateSearchL] = useState("");

  const filterBySearch = useCallback((query) => {
    const regQueryLowerCase = createSearch(query);

    return (item) => {
      if (!query) return true;
      return regQueryLowerCase.test(item.name);
    };
  }, []);

  const matched = useRef({
    form: form,
    saved: true,
  });

  const [forcedValue, _forceUpdate] = useState();
  const forceUpdate = () => _forceUpdate([]);
  const refRight = useRef();
  const refLeft = useRef();

  const leftListRef = useRef(null);
  const allowAutoMerge = window.location.href.includes("auto");

  const [showUnchecked, toggleShowUnchecked] = useState(false);
  const [showUncheckedLeft, toggleShowUncheckedLeft] = useState(false);

  useEffect(() => {
    if (!allowAutoMerge) return;

    const basicMapByName = {};

    basic.forEach(({ name, id }) => {
      basicMapByName[name] = id;
    });

    categories.forEach(({ name, id: containerId }) => {
      const id = basicMapByName[name];
      if (!id) return;

      matched.current[id] = containerId;
    });

    forceUpdate();
    leftListRef.current?.recomputeRowHeights();
  }, [allowAutoMerge, categories, basic]);

  const handleDrop = useCallback((containerId, item) => {
    const { id } = item;

    if (!matched.current[id]) {
      matched.current[id] = containerId;
    } else if (
      typeof matched.current[id] !== "object" &&
      matched.current[id] !== containerId
    ) {
      matched.current[id] = [matched.current[id], containerId];
    } else {
      if (
        typeof matched.current[id] === "object" &&
        matched.current[id]?.indexOf(containerId) === -1
      ) {
        matched.current[id]?.push(containerId);
      }
    }

    matched.current.saved = false;

    setRightCategories((dustbins) => [...dustbins]);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const containers = {};

  const getListRef = useCallback((ref) => {
    leftListRef.current = ref;
  }, []);

  useLayoutEffect(() => {
    forceUpdate();
  }, []);

  const filterLeft = useMemo(
    () => filterBySearch(searchL),
    [searchL, filterBySearch]
  );

  const leftFiltered = useMemo(
    () =>
      basic.filter(({ name, id }) => {
        const isSelected = matched.current[id];

        if (isSelected) {
          const prev = containers[matched.current[id]] || [];

          if (typeof matched.current[id] !== "object") {
            containers[matched.current[id]] = [...prev, { name, id }];
          } else {
            matched.current[id].forEach((categoryType) => {
              if (!containers[categoryType]) {
                containers[categoryType] = [{ name, id }];
              } else {
                containers[categoryType] = [
                  ...containers[categoryType],
                  { name, id },
                ];
              }
            });
          }
        }

        return filterLeft({ name }) && (showUncheckedLeft ? !isSelected : true);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rightCategories, filterLeft, matched, containers, basic, showUncheckedLeft]
  );

  const renderLeft = useCallback(
    ({ index, style }) => {
      const { name, id } = leftFiltered[index];
      return (
        <Box
          name={name}
          isDropped={!!matched.current[id]}
          key={id}
          id={id}
          style={style}
          onQuery={updateSearchR}
        />
      );
    },
    [leftFiltered]
  );

  const onCancel = useCallback((item) => {
    const { id, categoryId } = item;

    if (typeof matched.current[id] !== "object") {
      delete matched.current[id];
    } else {
      const pos = matched.current[id].indexOf(categoryId);

      if (pos !== -1) {
        matched.current[id].splice(pos, 1);
      }
    }

    matched.current.saved = false;

    setRightCategories((dustbins) => [...dustbins]);
  }, []);

  const filterR = useMemo(
    () => filterBySearch(searchR),
    [searchR, filterBySearch]
  );

  const filteredRight = useMemo(() => {
    const result = rightCategories.filter(filterR);

    if (!showUnchecked) return result;

    return result.filter(({ id }) => {
      return !containers[id];
    });
  }, [rightCategories, filterR, showUnchecked, containers]);

  const renderRight = useCallback(
    ({ index, style }) => {
      const { accepts, name, id } = filteredRight[index];

      return (
        <RightCategory
          accept={accepts}
          name={name}
          id={id}
          style={style}
          onCancel={onCancel}
          items={containers[id] || []}
          onQuery={updateSearchL}
          onDrop={(item) => handleDrop(id, item)}
          key={id}
        />
      );
    },
    [handleDrop, filteredRight, onCancel, containers, updateSearchL]
  );

  const calcRightCell = useCallback(
    ({ id }) => {
      return 60 + 40 * (containers[id]?.length || 0);
    },
    [containers]
  );

  const calcLeftCell = useCallback(() => {
    return 68;
  }, []);

  useEffect(() => {
    leftListRef.current?.recomputeRowHeights();
  }, [rightCategories, leftFiltered, forcedValue]);

  const onSave = () => {
    download(
      JSON.stringify(matched.current),
      form + ".config.txt",
      "text/plain"
    );
    matched.current.saved = true;
    forceUpdate();
  };

  const onUpload = () => {
    document.querySelector("input").click();
  };

  const onSelectFile = (event) => {
    const fileBlob = event.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const json = e.target.result;

      try {
        const data = JSON.parse(json);

        if (data.form !== form) {
          return alert(`Expect ${form}.config file`);
        }

        let allowMerge = true;

        if (Object.keys(matched.current).length > 2) {
          allowMerge = confirm(
            "`Yes`- merge to local config, `No` - rewrite config "
          );
        }

        matched.current = allowMerge ? { ...matched.current, ...data } : data;

        setRightCategories((dustbins) => [...dustbins]);
      } catch (e) {
        Bugsnag.notify(e);
        alert("Invalid file format");
      }
    };
    reader.readAsText(fileBlob);
  };

  return (
    <div className={"body"}>
      <input
        type={"file"}
        style={{ display: "none" }}
        onChange={onSelectFile}
      />

      <PreventClosePage
        isBlocked={!matched.current.saved}
        allowPath={[]}
      ></PreventClosePage>

      <div className={"list w-320"} ref={refLeft}>
        <div className={"w-100"}>
          <SearchInput
            placeholder={"Search Basic Categories"}
            value={searchL}
            onChange={(event) => {
              updateSearchL(event.target.value);
            }}
          />
          <Checkbox
            text={"Show unchecked"}
            className={"unchecked"}
            value={showUncheckedLeft}
            onChange={() => toggleShowUncheckedLeft(!showUncheckedLeft)}
          />
          <VirtualTable
            data={leftFiltered}
            renderRow={renderLeft}
            customElement={refLeft.current}
            calcRowHeight={calcLeftCell}
            // eslint-disable-next-line no-console
            getListRef={console.log}
          />
        </div>
      </div>

      <div className={"list"} ref={refRight}>
        <div className={"w-100"}>
          <SearchInput
            placeholder={`Search ${form} Categories`}
            value={searchR}
            onChange={(event) => {
              updateSearchR(event.target.value);
            }}
          />
          <Checkbox
            text={"Show unchecked"}
            className={"unchecked"}
            value={showUnchecked}
            onChange={() => toggleShowUnchecked(!showUnchecked)}
          />
          <VirtualTable
            data={filteredRight}
            renderRow={renderRight}
            customElement={refRight.current}
            calcRowHeight={calcRightCell}
            getListRef={getListRef}
          />
        </div>
      </div>

      <Button className={"filled-primary save"} onClick={onSave}>
        Download Config File
      </Button>

      <Button className={"outline-primary upload"} onClick={onUpload}>
        Upload Config File
      </Button>
    </div>
  );
});
