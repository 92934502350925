import { useCallback, useState } from "react";
import { useField } from "formik";
import Bugsnag from "@bugsnag/js";

import useUploadImagesToS3 from "./useUploadImagesS3";
import { useAccumulateDebounce } from "../../../base/hooks/useDebounce";
import { useGlobalLoading } from "../../../base/contexts/LoadingContext";
import { phrases } from "../../../store/phrases";

class ImageData {
  constructor(file) {
    this.file = file;
    this.preview = URL.createObjectURL(file);
    this.id = null;
  }
}

export const useDraftImages = (maxImagesCount) => {
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [field, , { setValue }] = useField({ name: "files" });
  const selectedImages = field.value;
  const isOpenEditModal = !!selectedImage;
  const { setLoading: setLoadingWithMessage } = useGlobalLoading();

  const uploadFiles = useUploadImagesToS3();

  const setSelectedImages = useCallback(
    (setter) => {
      return setValue(
        typeof setter === "function" ? setter(selectedImages) : setter
      );
    },
    [selectedImages, setValue]
  );

  const updateImagesWithDelay = useAccumulateDebounce(setSelectedImages);

  const onOpenUploadModal = useCallback(() => {
    setIsOpenUploadModal(true);
  }, [setIsOpenUploadModal]);

  const onCloseUploadModal = useCallback(() => {
    setIsOpenUploadModal(false);
  }, [setIsOpenUploadModal]);

  const onSave = useCallback(() => {
    onCloseUploadModal();
  }, [onCloseUploadModal]);

  const setImages = useCallback(
    (file) => {
      updateImagesWithDelay((prevState) => {
        if (prevState.length <= maxImagesCount - 1) {
          onCloseUploadModal();

          const imgData = new ImageData(file);

          uploadFiles([file])
            .then(([id]) => {
              imgData.id = id;
            })
            .catch((e) => Bugsnag.notify(e));
          return [...prevState, imgData];
        } else {
          return [...prevState];
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateImagesWithDelay, onCloseUploadModal]
  );

  const onEditImage = useCallback(
    (index) => {
      setSelectedImage({
        ...selectedImages[index].file,
        preview: selectedImages[index].preview,
        index,
      });
    },
    [setSelectedImage, selectedImages]
  );

  const onCropImage = useCallback(
    (croppedImage) => {
      const selectedImagesArray = [...selectedImages];
      const imgData = new ImageData(croppedImage);
      setLoadingWithMessage(true, phrases.processingImage);

      uploadFiles([croppedImage])
        .then(([id]) => {
          imgData.id = id;
          selectedImagesArray[selectedImage?.index] = imgData;
          setSelectedImages(selectedImagesArray);
          setSelectedImage(null);
        })
        .catch((e) => Bugsnag.notify(e))
        .finally(() => {
          setLoadingWithMessage(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedImages, setSelectedImage, selectedImage, uploadFiles]
  );

  const onCloseEditModal = useCallback(() => {
    setSelectedImage(null);
  }, [setSelectedImage]);

  const onDeleteImage = useCallback(
    (index) => {
      const selectedImagesArray = [...selectedImages];
      selectedImagesArray.splice(index, 1);
      setSelectedImages(selectedImagesArray.slice());
    },
    [setSelectedImages, selectedImages]
  );

  const clearAll = useCallback(() => {
    setSelectedImages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedImages,
    isOpenUploadModal,
    isOpenEditModal,
    onOpenUploadModal,
    onCloseUploadModal,
    onSave,
    setImages,
    onEditImage,
    selectedImage,
    onCropImage,
    onCloseEditModal,
    onDeleteImage,
    setSelectedImages,
    clearAll,
    field,
    setValue,
  };
};

export const useUploadFiles = () => {
  const uploadImagesToS3 = useUploadImagesToS3();

  return useCallback(
    (files) => {
      return uploadImagesToS3(
        files.map(({ file }) => file),
        files
      );
    },
    [uploadImagesToS3]
  );
};
