/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";
import { Formik } from "formik";

import { useService } from "../../../../../../base/hooks/useService";
import CreationDate from "../../../../components/CreationDate";
import CustomButton from "../../../../../../base/components/CustomButton";
import FormPartials from "../../../../classes/FormPartials";
import { FORM_TYPES } from "../../../../const/form";
import ExpiredStatus from "../../../../components/ExpiredStatus";
import { useInitialFormValues } from "../../../../helpers/mapFormValues";
import DraftHeader from "../../../../components/DraftHeader";
import { useHanleSaveDraftErrors } from "../../../../hooks/useSaveSubmit";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useListingUrl } from "../../../../components/DraftEbayFormInner/partials/ListingUrl/hooks/useListingUrl";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MARKETPLACES_NAMES_REQUEST } from "../../../../constants";
import DraftMercariFormInner from "../../../../components/DraftMercariFormInner";
import InstallExtensionPlaceholder from "../../../../components/InstallExtensionPlaceholder";
import { useMercariIsConnect } from "../../../../hooks/useMercariIsConnect";
import ConnectPlaceholder from "../../../../components/ConnectPlaceholder";
import { highlightDraftFields } from "../../../../classes/forms/mercari";
import ImageService from "../../../../../../services/ImageService";
import MercariExtension from "../../../../../../services/MercariExtension";
import MercariService from "../../../../../../services/MercariService";
import { DRAFTS_GROUP_LINKS } from "../../../../config";
import { useDeleteDraft } from "../../../../hooks/useDeleteDraft";
import AutoReloadMarketplace from "../../../../components/AutoReloadMarketplace";
import { useProductStatusLabel } from "../../../../hooks/useProductStatusLabel";
import { EDIT_INVENTORY_LINKS } from "../../../../../inventory/config";
import { generateFormModelWithBool } from "../../../../helpers/generateFormModelWithBool";
import { useCustomModal } from "../../../../hooks/useCustomModal";
import {
  INVENTORY_UPDATE_SUCCESS,
  PRODUCT_LISTED_SUCCESS,
} from "../../../../../../base/constants/messages";
import ToasterService from "../../../../../../services/ToastService";

import { useDelistAction } from "../../../../hooks/useProductStatus";
import { getCountOfActiveItems } from "../../../../helpers/getTableFieldValue";
import "../../../../index.scss";

import { EDIT_DRAFT_LINKS } from "../../../EditDraft/config";
import { PRODUCT_TYPE } from "../../../../../../base/constants/product";
import {
  getTouchedObj,
  scrollToErrors,
} from "../../../../hooks/useFormikScrollToErrors";
import { useDraftConnectionBadget } from "../../../../hooks/useDraftConnectionBadget";
import DraftUtils from "../../../../utils/DraftUtils";
import ProgressBar from "../../../../../sold/components/ProgressBar";
import { useGlobalLoading } from "../../../../../../base/contexts/LoadingContext";
import { phrases } from "../../../../../../store/phrases";
import { MarketplaceFormMonitor } from "../../../../components/MarketplaceFormMonitor";
import { parseMercariImages } from "../../../../helpers/Mercari/parseMercariImages";
import MarketPlaceService from "../../../../../../services/MarketplaceService";

export const ConnectButton = () => {
  const { isConnected, handleSignIn } = useMercariIsConnect();
  const marketplaceService = useService(MarketPlaceService);
  const [isMercariConnectedToFearn, setIsMercariConnectedToFearn] =
    useState(false);

  useEffect(() => {
    marketplaceService
      .getMarketplace()
      .then((response) => {
        if (response?.data?.mercariAccountDto) {
          setIsMercariConnectedToFearn(true);
        } else {
          setIsMercariConnectedToFearn(false);
        }
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <CustomButton className="filled-sm" onClick={handleSignIn}>
      {isConnected && isMercariConnectedToFearn ? "Reconnect" : "Connect"}
    </CustomButton>
  );
};

const CreateDraftMercari = ({
  updateFullForm,
  fullFormData,
  onSave,
  onSaveAndCreateNew,
  fetchedData,
  onHandleDelete,
  toggleStatus,
  isDelistBtnDisabled,
  isDisableCreateDraft,
  isDirty,
  setFormLoading = () => {},
  loadLatest,
}) => {
  /**
   * @type {MercariExtension}
   */
  const mercari = useService(MercariExtension);
  /**
   * @type {MercariService}
   */
  const mercariService = useService(MercariService);
  /**
   * @type {ToasterService}
   */
  const toaster = useService(ToasterService);

  const marketplaceService = useService(MarketPlaceService);

  const [marketplaceData, setMarketplaceData] = useState(null);
  const [processing, { registerPromise }] = useLoading();
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processMessage, setProcessMessage] = useState("");
  const formRef = useRef();
  const history = useHistory();
  const {
    params: { draftId },
  } = useRouteMatch();
  const formPartials = new FormPartials(FORM_TYPES.MERCARI, true);
  const onCatchError = useHanleSaveDraftErrors();
  const { setLoading: setLoadingWithMessage } = useGlobalLoading();

  const showProgressBar = (message) => {
    setLoading(true);
    setProcessMessage(message);
  };

  const hideProgressBar = () => {
    setLoading(false);
    setProcessMessage("");
  };

  const handleSave = useCallback(
    (values, helpers) => {
      const { setErrors, setTouched } = helpers;

      showProgressBar("Saving your changes");

      return registerPromise(
        onSave(values).catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err, (event) => {
              event.addMetadata("mercariHandleSaveError", {
                values,
              });
            });
            setTouched(highlightDraftFields, false);
            setErrors(highlightDraftFields);
          })
        )
      ).finally(hideProgressBar);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSave, registerPromise]
  );

  const handlePost = useCallback(
    (fullFormData, helpers, values) => {
      Object.assign(fullFormData.productMercariSpecificFields, values);

      Bugsnag.leaveBreadcrumb(
        `Mercari - ${phrases.preparingData}`,
        fullFormData
      );
      showProgressBar(phrases.preparingData);
      setLoadingWithMessage(true, phrases.preparingData);

      return registerPromise(
        onSaveAndCreateNew(fullFormData, null, "")
          .then((draftData) => {
            const {
              data: { productMercariSpecificFields, id: newDraftId },
            } = draftData;

            const productId = productMercariSpecificFields.id;
            const { productMercariSpecificFields: values } = fullFormData;

            const picturesBase64 = parseMercariImages(fullFormData.files);

            Bugsnag.leaveBreadcrumb(
              `Mercari - ${phrases.preparingImages}`,
              fullFormData.files
            );
            showProgressBar(phrases.preparingImages);
            setLoadingWithMessage(true, phrases.preparingImages);

            return Promise.all(picturesBase64).then((pictures) => {
              Bugsnag.leaveBreadcrumb(`Mercari - ${phrases.postingToMercari}`, {
                values,
              });
              setLoadingWithMessage(true, phrases.postingToMercari);
              showProgressBar(phrases.postingToMercari);

              return mercari
                .postDraft(pictures, values)
                .then(async (res) => {
                  Bugsnag.leaveBreadcrumb(
                    `Mercari - ${phrases.savingItemInformation}`,
                    res
                  );
                  setLoadingWithMessage(true, phrases.savingItemInformation);
                  showProgressBar(phrases.savingItemInformation);

                  await mercariService
                    .postProduct(productId, {
                      listingId: res.result.data.createListing.id,
                    })
                    .then(() => {
                      Bugsnag.leaveBreadcrumb(
                        "Mercari - Handling navigation",
                        values
                      );
                      toaster.success(PRODUCT_LISTED_SUCCESS);
                      const url = EDIT_INVENTORY_LINKS.MERCARI.replace(
                        ":draftId",
                        productId
                      );
                      const draftUrl = EDIT_DRAFT_LINKS.MERCARI.replace(
                        ":draftId",
                        productId
                      );

                      if (
                        window.location.href.includes(url) ||
                        window.location.href.includes(draftUrl) ||
                        window.location.href.includes(
                          DRAFTS_GROUP_LINKS.ADD_DRAFT
                        )
                      ) {
                        loadLatest?.();
                        history.replace(url);
                      }
                    });
                })
                .catch((err) => {
                  Bugsnag.notify(err, (event) => {
                    event.addMetadata("mercariHandlePostError", {
                      picturesLength: pictures.length,
                      values,
                    });
                  });

                  if (fetchedData?.status === PRODUCT_TYPE.ACTIVE) {
                    history.replace(
                      EDIT_INVENTORY_LINKS.MERCARI.replace(
                        ":draftId",
                        newDraftId
                      )
                    );
                  } else {
                    history.replace(
                      EDIT_DRAFT_LINKS.MERCARI.replace(":draftId", newDraftId)
                    );
                  }

                  return Promise.reject({ error: err });
                });
            });
          })
          .catch((err) => onCatchError(err, () => Bugsnag.notify(err)))
      ).finally(() => {
        hideProgressBar();
        setLoadingWithMessage(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSaveAndCreateNew, registerPromise, history]
  );

  const handleSaveNewDraftSubmit = useCallback(
    (values, { resetForm, setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(
        onSaveAndCreateNew(values)
          .then(() => {
            resetForm();
          })
          .catch((err) =>
            onCatchError(err, () => {
              Bugsnag.notify(err);
              setTouched(highlightDraftFields, false);
              setErrors(highlightDraftFields);
            })
          )
      ).finally(hideProgressBar);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSaveAndCreateNew, registerPromise]
  );

  const initialValues = useInitialFormValues(
    fullFormData,
    formPartials.initialValues,
    formPartials.mapFields,
    formPartials.formKey
  );

  const { isPosted, link } = useListingUrl({ fetchedData });
  const { isConnected, isInstalled } = useMercariIsConnect();

  const handleDelete = () => {
    onHandleDelete();
    onDelete(draftId);
  };

  useEffect(() => {
    marketplaceService.getMarketplace().then((response) => {
      setMarketplaceData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const {
    view: popupView,
    onDelete,
    deleting,
  } = useDeleteDraft(() => {
    history.push(DRAFTS_GROUP_LINKS.BASE);
  });

  const [getProductStatusLabel] = useProductStatusLabel();

  const handleUpdate = useCallback(() => {
    showProgressBar(phrases.preparingData);
    setLoadingWithMessage(true, phrases.preparingData);
    Bugsnag.leaveBreadcrumb(
      `Mercari Revise - ${phrases.preparingData}`,
      fullFormData
    );

    return registerPromise(
      onSave(fullFormData, null, "")
        .then(({ data: { productMercariSpecificFields } }) => {
          const productId = productMercariSpecificFields.id;
          const listingId = productMercariSpecificFields.listingId;
          const { productMercariSpecificFields: values } = fullFormData;

          const picturesBase64 = fullFormData.files.map((picture) => {
            return ImageService.fileToBase64(picture.file, true);
          });

          showProgressBar(phrases.preparingImages);
          setLoadingWithMessage(true, phrases.preparingImages);
          Bugsnag.leaveBreadcrumb(
            `Mercari Revise - ${phrases.preparingImages}`,
            {
              images: fullFormData.files,
            }
          );

          return Promise.all(picturesBase64).then((pictures) => {
            delete values.tags_1;
            delete values.tags_2;
            delete values.tags_3;

            return mercari
              .editInventory(pictures, values, listingId)
              .then((res) => {
                const {
                  result: {
                    data: {
                      editListing: { id: listingId },
                    },
                  },
                } = res;

                showProgressBar(phrases.savingItemInformation);
                setLoadingWithMessage(true, phrases.savingItemInformation);
                Bugsnag.leaveBreadcrumb(
                  `Mercari Revise - ${phrases.savingItemInformation}`,
                  {
                    listingId,
                  }
                );

                mercariService
                  .updatePostedStatusProduct(productId, {
                    listingId,
                  })
                  .then(() => {
                    Bugsnag.leaveBreadcrumb(
                      "Mercari Revise - Handling navigation"
                    );
                    toaster.success(INVENTORY_UPDATE_SUCCESS);
                    history.replace(
                      EDIT_INVENTORY_LINKS.MERCARI.replace(":draftId", draftId)
                    );
                    const model = generateFormModelWithBool(
                      formRef.current?.values
                    );

                    formRef.current?.setTouched(model, false);
                    formRef.current?.setErrors(model);
                  });
              })
              .catch((err) => {
                Bugsnag.notify(err, (event) => {
                  event.addMetadata("mercarihandleUpdateError", {
                    fullFormData,
                  });
                });

                return Promise.reject({ error: err });
              });
          });
        })
        .catch((err) => onCatchError(err, () => Bugsnag.notify(err)))
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerPromise,
    onSave,
    fullFormData,
    formRef,
    generateFormModelWithBool,
    history,
    mercariService,
    mercari,
    ImageService,
  ]);

  const { modal: modalUpdate, onShowModal: onShowModalUpdate } = useCustomModal(
    {
      title: "Update item",
      message:
        "Are you sure you want to apply changes on your Mercari Listing?",
      onAccept: handleUpdate,
    }
  );
  const { delist } = useDelistAction({
    onDelist: onHandleDelete,
    onToggleStatus: toggleStatus,
  });

  const handleDelistAction = () => {
    const countOfActiveListing = getCountOfActiveItems(fetchedData);

    showProgressBar("Delisting");
    setLoadingWithMessage(true, "Delisting item");
    registerPromise(
      delist({
        draftId,
        countOfActiveListing,
        marketplace: MARKETPLACES_NAMES_REQUEST.mercari,
        listingId: fetchedData?.productMercariSpecificFields?.listingId,
      })
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
  };

  const { modal: modalDelist, onShowModal: onShowModalDelist } = useCustomModal(
    {
      title: "Delist item",
      message: "Do you want to delist the item from  Mercari?",
      onAccept: handleDelistAction,
    }
  );
  const { showConnectBadget } = useDraftConnectionBadget();

  useEffect(() => {
    setFormLoading(processing || categoriesLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing, categoriesLoading]);

  useEffect(() => {
    document.getElementById("mercari-form").reset();
  }, []);

  return (
    <Card className="mt-3">
      <CardBody>
        {loading && <ProgressBar isActive processMessage={processMessage} />}
        {popupView}
        {modalUpdate}
        {modalDelist}
        <Formik
          initialValues={initialValues}
          validationSchema={formPartials.validations}
          onSubmit={(values, helpers) =>
            handlePost(fullFormData, helpers, values)
          }
          innerRef={formRef}
          validateOnBlur={true}
          validateOnChange={true}
          isInitialValid={false}
        >
          {(helpers) => {
            const {
              handleSubmit,
              setFieldValue,
              values,
              isValid,
              errors,
              touched,
            } = helpers;

            return (
              <form
                className="form-horizontal p-2"
                onSubmit={handleSubmit}
                id="mercari-form"
              >
                <MarketplaceFormMonitor
                  item={fullFormData}
                  formKey="productMercariSpecificFields"
                  localForm={values}
                  updateFullForm={updateFullForm}
                  touchedFields={touched}
                />

                <DraftHeader
                  showSettings
                  title={"Mercari form"}
                  status={getProductStatusLabel(
                    fetchedData?.productMercariSpecificFields?.status
                  )}
                  marketplace={MARKETPLACES_NAMES_REQUEST.mercari}
                  fetchedData={fetchedData}
                  onHandleDelete={onHandleDelete}
                  formValues={values}
                  toggleStatus={toggleStatus}
                  marketplaceData={marketplaceData}
                  marketplaceAccountKey="mercariAccountDto"
                  isConnected={isConnected}
                >
                  <>
                    {isPosted && (
                      <>
                        <CustomButton
                          className="filled-sm w-80 mr-2"
                          onClick={() => window.open(link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link}
                        >
                          View item
                        </CustomButton>
                        <CustomButton
                          className="filled-sm mr-2"
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  if (!processing) {
                                    helpers.validateForm().then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors)
                                        .catch((e) => Bugsnag.notify(e));
                                    });
                                  }
                                }
                          }
                          disabled={processing || !isConnected || !isDirty}
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                    <ConnectButton />
                  </>
                </DraftHeader>

                <DraftMercariFormInner
                  initialValues={initialValues}
                  setFieldValue={setFieldValue}
                  values={values}
                  postedInfo={{ isPosted, link }}
                  formRef={formRef}
                  errors={errors}
                  touched={touched}
                  isConnected={isConnected}
                  suggestedFields={DraftUtils.extractSuggestedFields(
                    fullFormData
                  )}
                  isLoading={setCategoriesLoading}
                />

                {!isInstalled && showConnectBadget && (
                  <InstallExtensionPlaceholder marketPlace={"Mercari"} />
                )}
                {isInstalled &&
                  (!isConnected || !marketplaceData?.data?.mercariAccountDto) &&
                  showConnectBadget && (
                    <ConnectPlaceholder>
                      {phrases.mercariNotConnectedDrafts}
                    </ConnectPlaceholder>
                  )}

                <Row>
                  <Col className="d-flex">
                    <CreationDate
                      text="Creation date:"
                      date={values.createdAt}
                      className="mr-60"
                    />
                    {!!values.updatedAt && (
                      <CreationDate
                        className="mr-60"
                        text="Last Updated:"
                        date={values.updatedAt}
                      />
                    )}
                    <ExpiredStatus
                      text="Status:"
                      className="mr-60"
                      isActive={isInstalled}
                      isConnected={isConnected}
                    />
                  </Col>

                  <Col
                    ms="7"
                    className="d-flex justify-content-md-end align-items-center"
                  >
                    {!isPosted && (
                      <>
                        {draftId && (
                          <CustomButton
                            className="outline-danger w-80 mr-2"
                            onClick={handleDelete}
                            disabled={deleting || processing}
                          >
                            Delete
                          </CustomButton>
                        )}
                        <CustomButton
                          onClick={() => handleSave(fullFormData, helpers)}
                          disabled={processing}
                          type={"button"}
                          className="filled-primary w-80 mr-2"
                        >
                          Save
                        </CustomButton>
                        <CustomButton
                          type={"button"}
                          disabled={processing || isDisableCreateDraft}
                          onClick={() =>
                            handleSaveNewDraftSubmit(fullFormData, helpers)
                          }
                          className="outline-primary mr-2 save-new-btn"
                        >
                          Save + New draft
                        </CustomButton>

                        <CustomButton
                          className="outline-primary w-80 mr-2"
                          disabled={processing || !isConnected}
                          type={isValid ? "submit" : "button"}
                          onClick={
                            isValid
                              ? null
                              : () => {
                                  if (!processing) {
                                    helpers.validateForm().then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors)
                                        .catch((e) => Bugsnag.notify(e));
                                    });
                                  }
                                }
                          }
                        >
                          Post
                        </CustomButton>
                      </>
                    )}

                    {isPosted && (
                      <>
                        <CustomButton
                          className="outline-danger w-80 mr-2"
                          onClick={onShowModalDelist}
                          disabled={
                            processing ||
                            isDelistBtnDisabled ||
                            !isConnected ||
                            !isInstalled ||
                            !marketplaceData?.data?.mercariAccountDto
                          }
                        >
                          Delist
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80 mr-2"
                          onClick={() => window.open(link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link}
                        >
                          View item
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80 "
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  if (!processing) {
                                    helpers.validateForm().then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors)
                                        .catch((e) => Bugsnag.notify(e));
                                    });
                                  }
                                }
                          }
                          disabled={
                            processing ||
                            !isConnected ||
                            !isDirty ||
                            !isInstalled ||
                            !marketplaceData?.data?.mercariAccountDto
                          }
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default (props) => {
  const { isPending, updateIsPending } = useMercariIsConnect();
  const reload = isPending;

  return (
    <AutoReloadMarketplace
      reload={reload}
      onReload={() => updateIsPending(false)}
    >
      <CreateDraftMercari {...props} key={reload} />
    </AutoReloadMarketplace>
  );
};
