/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import { PRODUCT_TYPE } from "../../../../constants/product";
import { marketplacesList } from "../../constants/constants";
import { isSelectedItem } from "../../utils/utils";
import classnames from "classnames";
import { useHighlight } from "../../../../hooks/useHighlight";

const CardMergeItem = ({
  item,
  key,
  index,
  onSelectItem = () => {},
  selectedItem,
  searchHighlight,
  selectedMarketplacesMap,
  checkIsNotAvailableToMerge = () => {},
  lastItem = null,
}) => {
  const itemImages = item?.files || [];
  const imageThumbnail = itemImages.sort((a, b) => a.id - b.id)?.[0];
  const getMarketplaces = useCallback((product) => {
    let marketplaces = [];
    const productData = {};

    marketplacesList.forEach(({ key, label, marketplaceType }) => {
      if (product[key] && product[key].status === PRODUCT_TYPE.ACTIVE) {
        marketplaces.push({ label, marketplaceType, id: product.id });
        productData.title = product[key].title;
        productData.sku = product[key]?.sku ?? product?.sku;
        productData.size = product[key].sizeName;
        productData.price =
          product[key].listingPrice ||
          product[key].price ||
          product[key].buyItNowPrice ||
          product[key].startingPrice;
        productData.marketplaces = marketplaces;
        productData.id = product.id;
      }
    });
    return productData;
  }, []);

  const productForCard = useMemo(() => {
    return getMarketplaces(item);
  }, [getMarketplaces, item]);

  const checkIsDisabled = useMemo(() => {
    return checkIsNotAvailableToMerge(productForCard, selectedMarketplacesMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarketplacesMap]);

  const isCardDisabled =
    checkIsDisabled && !isSelectedItem(selectedItem, productForCard?.id);

  const handleClickItem = () => {
    onSelectItem(productForCard, index);
  };

  const { decorateText } = useHighlight(searchHighlight);

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperActive]: isSelectedItem(
          selectedItem,
          productForCard?.id
        ),
        [styles.disabled]: isCardDisabled,
      })}
      onClick={isCardDisabled ? undefined : handleClickItem}
      key={key}
      ref={lastItem}
    >
      <div>
        <img className={styles.image} src={imageThumbnail?.link} />
      </div>
      <div className={styles.contentContainer}>
        <h3
          className={styles.title}
          {...decorateText(productForCard.title || "-")}
        />
        <div className={styles.container}>
          <p className={styles.box}>
            <span className={styles.main}>Size</span>
            <span>{productForCard.size || "-"}</span>
          </p>
          <p className={styles.box}>
            <span className={styles.main}>SKU</span>
            <span {...decorateText(productForCard.sku || "-")} />
          </p>
        </div>
        <div className={styles.container}>
          <p className={styles.box}>
            <span className={styles.main}>Price</span>
            <span>{productForCard.price || "-"}</span>
          </p>
          {!!productForCard.marketplaces?.length && (
            <div className={styles.marketplaceContainer}>
              {productForCard.marketplaces.map((i, idx) => {
                return (
                  <p className={styles.main} key={idx}>
                    {i.label}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CardMergeItem);
