import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { MOMENT_DAY } from "../../../bookkeeping/constants";
import {
  CHART_LABELS,
  COLORS,
} from "../../../../base/components/Charts/params";
import { MARKETPLACE_TYPES as MARKETPLACE_TYPES_LABELS } from "../../../sold/constants";
import { MARKETPLACE_TYPES } from "../../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import { ANALYTICS_GROUP_LINKS } from "../../../analytics/config";
import styles from "../../../../base/components/Charts/index.module.scss";
import AnalyticsService from "../../../../services/AnalyticsService";
import Utils from "../../../analytics/utils/utils";
import { DounatChartBase } from "../../../../base/components/Charts";
import { useService } from "../../../../base/hooks/useService";
import { useAnalyticRangeDateFilter } from "../../../analytics/hooks/useAnalyticRangeDateFilter";
import { separateThousandthFloored } from "../../../analytics/helpers/separateThousandth";
import AnalyticsTodayPerformance from "../AnalyticsTodayPerformance/AnalyticsTodayPerformance";

import "./index.scss";

const COUNT_ROUNDING = 2;

export const INIT_DATE_FILTER = {
  startDate: moment().startOf(MOMENT_DAY).toDate(),
  endDate: moment().endOf(MOMENT_DAY).toDate(),
};

const AnalyticsToday = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [dateFilter, { formatDate }] =
    useAnalyticRangeDateFilter(INIT_DATE_FILTER);

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  const getAnalytics = useCallback(() => {
    analytics
      .getSalesPerPlatform({
        // Take data for the day
        startDate: formatDate(dateFilter.dateRange.startDate),
        endDate: formatDate(dateFilter.dateRange.endDate),
      })
      .then(({ data }) => {
        const formattedData = Object.values(MARKETPLACE_TYPES).map(
          (marketplaceType) => {
            const value = data?.marketplacesSales?.find(
              (marketplacesSalesItem) =>
                marketplacesSalesItem.marketplaceType === marketplaceType
            );
            return { marketplaceType, priceSoldSum: value?.priceSoldSum || 0 };
          }
        );
        setData(formattedData);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, setData]);

  function getSum() {
    return data.map((item) => {
      return item.priceSoldSum;
    });
  }

  useEffect(() => {
    getAnalytics();
  }, [getAnalytics]);

  const chartData = useMemo(() => {
    const sumData = getSum();
    const splicedSumDate = sumData.splice(0, 2);
    return sumData.concat(splicedSumDate.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getSum]);

  const total = Utils.setTotalSales(data).toFixed(COUNT_ROUNDING);

  const customChartData = useMemo(
    () => ({
      labels: ["Poshmark", "Mercari", "Other", "Etsy", "Ebay"],
      datasets: [
        {
          backgroundColor: COLORS,
          data: chartData,
        },
      ],
    }),
    [chartData]
  );

  const labels = useMemo(
    () => ({
      percents: CHART_LABELS.HIDDEN,
    }),
    []
  );

  return (
    <Card className={"h-100"}>
      <CardBody
        className="py-3 external-event"
        onClick={() => history.push(ANALYTICS_GROUP_LINKS.SALES_PLATFORM)}
      >
        <h3 className="font-size-19 font-weight-semibold title-color">
          Today So Far
        </h3>

        <AnalyticsTodayPerformance />

        <div className="chart__wrapper">
          {data.map((item, i) => {
            return (
              <div key={i} className="chart__marker">
                <span className="marker" />
                <div className="marker__text">
                  <div className="marker__title">
                    {MARKETPLACE_TYPES_LABELS[item.marketplaceType]}
                  </div>
                  <div className="marker__sum">
                    {separateThousandthFloored(item.priceSoldSum)}
                  </div>
                </div>
              </div>
            );
          })}

          <DounatChartBase
            data={chartData}
            customLabels={labels}
            customChartData={customChartData}
          >
            <span className={styles.chartTotal}>
              {separateThousandthFloored(total)}
            </span>
          </DounatChartBase>
        </div>
      </CardBody>
    </Card>
  );
};

export default AnalyticsToday;
