import { useCallback, useRef, useState } from "react";
import moment from "moment";

import EtsyExtension from "../../../services/EtsyExtension";

import { useService } from "../../../base/hooks/useService";
import { useEtsyIsConnect } from "../../drafts/hooks/useEtsyIsConnect";

import { MARKETPLACES } from "../../../base/constants/extension";
import SoldService from "../../../services/SoldService";
import QueuePromises from "../../../base/classes/QueuePromises";
import EtsyProduct from "../../inventory/classes/EtsyProduct";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";

import EtsyService from "../../../services/EtsyService";
import TabConnection from "../../../services/importServices/TabConnection";
import { compareTwoDate, getLastSyncedDateForMarketplace } from "../helpers";

import { useDelistByMarketplace } from "./useDelistByMarketplace";

export const useGetSoldItemsEtsy = () => {
  /**
   * @type {EtsyExtension}
   */
  const etsy = useService(EtsyExtension);

  /**
   * @type {SoldService}
   */
  const sold = useService(SoldService);

  /**
   * @type {EtsyService}
   */
  const etsyService = useService(EtsyService);

  /**
   * @type {TabConnection}
   */
  const tabConnection = useRef(new TabConnection(MARKETPLACES.ETSY));

  const { isConnected } = useEtsyIsConnect();
  const [isProcessing, setFetchProcessing] = useState(false);
  const itemsRef = useRef([]);
  const newSyncDate = useRef();
  const dateForFilter = useRef();

  const [delistProduct] = useDelistByMarketplace();

  const items = itemsRef.current;
  const updateItems = (callback) => {
    itemsRef.current = callback(itemsRef.current);
  };

  const marketplace = MARKETPLACES.ETSY;

  const onComplete = useCallback(
    (activeInventories) => {
      setFetchProcessing(true);

      const soldInventories = itemsRef.current.map(
        (product) => new EtsyProduct(product)
      );

      const queue = new QueuePromises();
      const markAsSoldQueue = new QueuePromises();

      soldInventories.forEach((product) => {
        const loadData = () =>
          product?.loadAdditionalFields() || Promise.resolve();
        queue.add(loadData);
      });

      return queue.finally(() => {
        soldInventories
          .filter((sold) => activeInventories[sold.data.listingId])
          .forEach((product) => {
            const model = filterObjectFalsy(product.data);
            markAsSoldQueue.add(() =>
              sold
                .importSold(model)
                .finally(() =>
                  delistProduct(
                    MARKETPLACES.ETSY,
                    product.data.listingId,
                    activeInventories
                  )
                )
            );
          });

        markAsSoldQueue.finally(() => setFetchProcessing(false));

        queue.finally(() => {
          etsyService.updateLastSyncedDate(newSyncDate.current);
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, setFetchProcessing, delistProduct]
  );

  const getFilteredItemsByDate = (data, dateForFilter) =>
    data.filter(({ dateSold }) => compareTwoDate(dateSold, dateForFilter));

  const sync = useCallback(
    (activeProductsMap, marketplaceData) => {
      dateForFilter.current = getLastSyncedDateForMarketplace(marketplaceData);
      newSyncDate.current = moment().toISOString();

      setFetchProcessing(true);
      itemsRef.current = [];

      const { unSubscribe } = etsy.importSoldProducts((response) => {
        const { data: responseData, err, marketplaceTabId } = response;

        if (err) {
          unSubscribe();
          setFetchProcessing(false);
          onComplete(activeProductsMap);
          return;
        }

        const {
          result: { data, processing },
        } = responseData;

        const filteredItems = getFilteredItemsByDate(
          data,
          dateForFilter.current
        );

        if (filteredItems.length !== data.length) {
          updateItems((prevList) => [...prevList, ...filteredItems]);

          unSubscribe();

          onComplete(activeProductsMap);
          tabConnection.current.disconnectById(marketplaceTabId);

          return unSubscribe;
        }

        updateItems((prevList) => [...prevList, ...data]);

        if (!processing) {
          unSubscribe();
          onComplete(activeProductsMap);
        }
      });

      return unSubscribe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [etsy, itemsRef, etsyService]
  );

  return {
    isProcessing,
    sync,
    marketplace,
    isConnected,
  };
};
