import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../services/PoshmarkExtension";
import {
  authUrl_POSHMARK,
  WINDOW_CONFIG_POSHMARK,
  WINDOW_NAME_POSHMARK,
} from "../../constants/connectButton";
import { useExtensionInstall } from "./useExtensionInstall";
import PoshmarkService from "../../../../services/PoshmarkService";

let lastMarketplaceId = "";

export const usePoshmarkIsConnect = () => {
  const [isConnect, updateIsConnect] = useState(false);
  const [isPending, updateIsPending] = useState(false);
  const { isInstalled } = useExtensionInstall();

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  /**
   * @type {PoshmarkService}
   */
  const poshmarkService = useService(PoshmarkService);

  const saveMarketplaceId = useCallback(() => {
    poshmark
      .getProfile()
      .then((data) => {
        const id = data?.username;

        if (id !== lastMarketplaceId) {
          poshmarkService.savePoshmarkMarketplaceAccount({ accountId: id });
        }

        lastMarketplaceId = id;
      })
      .catch((e) => Bugsnag.notify(e));
  }, [poshmark, poshmarkService]);

  useEffect(() => {
    const { unSubscribe } = poshmark.onCompletedAuth((data) => {
      if (data?.err) return;
      updateIsConnect(true);
      updateIsPending(true);

      saveMarketplaceId();
    });

    return unSubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poshmark]);

  useEffect(() => {
    const { unSubscribe } = poshmark.onCompletedLogout(() =>
      updateIsConnect(false)
    );

    return unSubscribe;
  }, [poshmark]);

  const handleSignIn = useCallback(() => {
    const openedWindow = open(
      authUrl_POSHMARK,
      WINDOW_NAME_POSHMARK,
      WINDOW_CONFIG_POSHMARK
    );
    if (isConnect && isInstalled && openedWindow) {
      setTimeout(() => openedWindow.close(), 3000);
    }
  }, [isConnect, isInstalled]);

  useEffect(() => {
    poshmark
      .isConnected()
      .then(() => {
        updateIsConnect(true);
      })
      .catch(() => {
        updateIsConnect(false);
      });
  }, [poshmark]);

  return {
    handleSignIn,
    isConnected: isConnect && isInstalled,
    isPending,
    updateIsPending,
    isInstalled,
  };
};
