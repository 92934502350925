/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import ImportModal from "../../../../../base/components/ImportModal";
import { useService } from "../../../../../base/hooks/useService";
import { useImportMercariCSV } from "../../../hooks/useImportPoshmarkCSV";
import ErrorMarketplaceConnection from "../EbayImportModal/ErrorEbayConnection";
import MercariExtension from "../../../../../services/MercariExtension";
import { SOLD_INVENTORY_LABEL } from "../../../constants";
import ImportMercari from "../../../../../services/importServices/ImportMercari";
import { useImportService } from "../EbayImportModal";
import { ImportPopupMercariService } from "../../../services";
import { MARKETPLACES } from "../../../../../base/constants/extension";

const MercariImportModal = (props) => {
  const { data, isOpen } = props;
  const [shopName, setShopName] = useState("Loading...");
  const [{ formatProducts, onImportToCSVFile }] = useImportMercariCSV();

  /**
   * @type {MercariExtension}
   */
  const mercariExtension = useService(MercariExtension);

  const loadProfile = useCallback(() => {
    mercariExtension
      .getProfile()
      .then(
        ({
          data: {
            user: { name },
          },
        }) => name
      )
      .catch((e) => {
        Bugsnag.notify(e);

        return "-";
      })
      .then(setShopName);
  }, [mercariExtension]);

  useEffect(() => {
    if (!isOpen) return;
    loadProfile();
  }, [isOpen, loadProfile]);

  const { status } = props;

  /**
   * @type {ImportMercari}
   */
  const importMercari = useService(ImportMercari);

  const loadData = useCallback(
    (tabId, pagination) => {
      if (status === SOLD_INVENTORY_LABEL) {
        return importMercari
          .importSoldProducts(tabId, pagination)
          .then((resp) => {
            const {
              result: {
                data: { sold, inProgressSold },
              },
            } = resp;

            const data = [...sold.data, ...inProgressSold.data];
            data.sort((itemA, itemB) => itemB.updated - itemA.updated);

            return {
              data: formatProducts(data),
              pagination: {
                sold: sold.pagination,
                inProgressSold: inProgressSold.pagination,
              },
              hasNextPage:
                sold.pagination.nextPage || inProgressSold.pagination.nextPage,
            };
          })
          .catch((e) => Bugsnag.notify(e));
      }

      return importMercari
        .importActiveProducts(tabId, pagination)
        .then((resp) => {
          const {
            data: {
              userItems: { items, pagination },
            },
          } = resp;

          return {
            data: formatProducts(items ?? []),
            pagination,
            hasNextPage: pagination?.hasNext
              ? pagination.currentPage + 1
              : null,
          };
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [importMercari, formatProducts, status]
  );

  const [service] = useImportService(
    ImportPopupMercariService,
    loadData,
    MARKETPLACES.MERKARI
  );

  if (!data) {
    return <ErrorMarketplaceConnection {...props} name={"Mercari"} />;
  }

  return (
    <ImportModal
      title="Import from Mercari"
      products={service.getVisibleItems()}
      shopName={shopName}
      isImportToCSV={true}
      onImportToCSV={onImportToCSVFile}
      isShopNameComponent
      isShowAllDropdown
      service={service}
      {...props}
    />
  );
};

export default MercariImportModal;
