import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import ProfileService from "../../../services/ProfileService";
import StorageService from "../../../services/StorageService";
import { KEY_USER } from "../../../base/constants/storage";
import { TYPES } from "../../../base/components/Subscription/constant/subscription";

export const useProfile = () => {
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const getFromStorage = useCallback(() => {
    return storage.get(KEY_USER, {
      firstName: "",
      lastName: "",
      email: "",
    });
  }, [storage]);

  const [profileInfo, setProfileInfo] = useState(() => {
    return getFromStorage();
  });

  useEffect(() => {
    return storage.onChange(KEY_USER, () => {
      setProfileInfo(getFromStorage());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFromStorage]);

  useEffect(() => {
    return storage.onChangeDataInSiblingTab(KEY_USER, () => {
      setProfileInfo(getFromStorage());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFromStorage]);

  const updateProfile = useCallback(
    (profile) => {
      // Expired subscription
      if (!profile.activeSubscription) {
        profile.activeSubscription = {
          type: TYPES.EXPIRED,
        };
      }

      if (profile.activeSubscription) {
        const {
          activeSubscription: { expiresAt },
        } = profile;

        const expiryDate = new Date(expiresAt);
        const date = new Date();

        if (date.getTime() >= expiryDate.getTime()) {
          profile.activeSubscription = {
            ...profile.activeSubscription,
            type: TYPES.EXPIRED,
          };
        }
      }

      storage.set(KEY_USER, profile);
    },
    [storage]
  );

  return [profileInfo, updateProfile];
};

export const useLoadProfile = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [profileInfo, update] = useProfile();

  useEffect(() => {
    profileService
      .getProfileInfo()
      .then(({ data }) => update(data))
      .catch((e) => Bugsnag.notify(e));
  }, [profileService, update]);

  return profileInfo;
};

export const useUpdateProfile = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [, update] = useProfile();

  return useCallback(
    // eslint-disable-next-line no-unused-vars
    async (formatProfile) => {
      profileService
        .getProfileInfo()
        .then(({ data }) => update(data))
        .catch((e) => Bugsnag.notify(e));
    },
    [profileService, update]
  );
};
