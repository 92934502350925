/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import Bugsnag from "@bugsnag/js";

import ToasterService from "../../../services/ToastService";
import { validateFile } from "./utils/validateFile";
import { useService } from "../../hooks/useService";
import "./index.scss";
import CustomButton from "../CustomButton";
import { ERROR_MESSAGE } from "./constants/dropzoneConstants";

export const DropZoneCard = ({
  onDrop,
  errorMessage,
  isDropContainer = true,
  className,
  children = ERROR_MESSAGE,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      className={classnames(
        { [className]: !!className },
        { "dropzone-file": !className },
        { "drag-active": isDragActive }
      )}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} className={className} />
        {isDropContainer && (
          <div className="upload-container">
            <i className="bx bxs-cloud-upload cloud" />
            <p
              className={classnames("upload-container__text", {
                "error-message": errorMessage,
              })}
            >
              {errorMessage || children}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const DropzoneFile = ({
  onValidate = validateFile,
  onReceiveFile,
  className,
  isDropContainer = true,
  onCloseModal,
  onSave,
}) => {
  const toastr = useService(ToasterService);
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  const handleAcceptedFile = useCallback((file) => {
    try {
      const error = onValidate(file);

      if (error) {
        setErrorMessage(`${file.name}: ${error}`);
        const errorCopy = errorMessages.slice();
        errorCopy.push(`${file.name}: ${error}`);
        setErrorMessages(errorCopy);

        return;
      }
    } catch (error) {
      Bugsnag.notify(error);

      const { message } = error;
      toastr.error(message);
      return;
    }

    setIsDisabledSave(false);
    onReceiveFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles.length) return;

      setErrorMessages([]);

      acceptedFiles.forEach((file) => handleAcceptedFile(file));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleErrors = () => {
    if (errorMessages.length > 0) {
      errorMessages.forEach((error) => {
        toastr.error(error);
      });
    }
  };

  useEffect(() => {
    handleErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages]);

  return (
    <>
      <DropZoneCard
        onDrop={onDrop}
        errorMessage={errorMessage}
        isDropContainer={isDropContainer}
        className={className}
      />
      <div className="modal-buttons-wrapper">
        <CustomButton className="outline-primary" onClick={onCloseModal}>
          Cancel
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onSave}
          disabled={isDisabledSave}
        >
          Save
        </CustomButton>
      </div>
    </>
  );
};

DropzoneFile.propTypes = {
  onValidate: PropTypes.func,
  onReceiveFile: PropTypes.func,
  className: PropTypes.string,
  isDropContainer: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSave: PropTypes.func,
};

export default DropzoneFile;
