import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import App from "./App";
import Context from "./Context";
import { ErrorProvider } from "./base/contexts/ErrorContext";
import { LoadingProvider } from "./base/contexts/LoadingContext";

Bugsnag.start({
  apiKey: "77af100b7b6e535317f210bbac3057f3",
  plugins: [new BugsnagPluginReact()],
  sourceMapsEnabled: true,
  appVersion: "1.0.81",
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const Application = (
  <ErrorBoundary>
    <Context>
      <ErrorProvider>
        <LoadingProvider>
          <App />
        </LoadingProvider>
      </ErrorProvider>
    </Context>
  </ErrorBoundary>
);

ReactDOM.render(Application, document.getElementById("root"));
