/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

import { SaveIcon } from "../../constants/icons";
import { ReactComponent as CheckMark } from "../../../assets/images/toastr/bx-check.svg";
import { useField } from "formik";
import { useTooltip } from "../../hooks/useTooltip";

const CheckIcon = styled(CheckMark)`
  width: 16px;
`;

const Field = styled.div`
  position: absolute;
  right: 0;
  margin-right: 16px;
  z-index: 1;
`;

const FieldButton = styled.button`
  background-color: #efefef;
  outline: 0;
  border: 0;
  border-radius: 20px;
`;

const excludedFieldKeys = ["shippingLocation", "shipsFrom"];

// Note: if being used with options that are fetched via a request,
// ensure that this is rendered only AFTER the options are loaded
export const StoredField = ({ fieldKey = null, options = [] }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [{ value }, , helpers] = useField({ name: fieldKey });

  const tooltipRef = useRef(null);

  const { setValue } = helpers;

  const saveField = () => {
    if (value && fieldKey) {
      localStorage.setItem(fieldKey, value);
      setIsEmpty(false);
    }
  };

  const unsaveField = () => {
    if (fieldKey) {
      localStorage.removeItem(fieldKey);
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    if (fieldKey && localStorage.getItem(fieldKey)) {
      const fieldValue = localStorage.getItem(fieldKey);
      setIsEmpty(false);

      if (options.length > 0) {
        const optionValue = options.find(
          ({ id }) => `${id}` === `${fieldValue}`
        );

        if (optionValue) {
          const { value } = optionValue;

          // if an explicit value property, we set it
          if (value) {
            setValue(value);

            return;
          }

          // if no value property, `id` is used
          setValue(isNaN(fieldValue) ? fieldValue : parseInt(fieldValue, 10));
        }
      } else {
        if (!isNaN(fieldValue) && excludedFieldKeys.indexOf(fieldKey) === -1) {
          setValue(+fieldValue);
          return;
        }

        setValue(fieldValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tooltipProps = useTooltip(tooltipRef);

  return (
    <Field>
      {isEmpty ? (
        <>
          <FieldButton type="button" onClick={saveField} ref={tooltipRef}>
            <SaveIcon />
          </FieldButton>
          <Tooltip placement="right" {...tooltipProps}>
            Autofill this field with this value
          </Tooltip>
        </>
      ) : (
        <>
          <FieldButton type="button" onClick={unsaveField} ref={tooltipRef}>
            <CheckIcon />
          </FieldButton>
          <Tooltip placement="right" {...tooltipProps}>
            Clear autofill value for this field
          </Tooltip>
        </>
      )}
    </Field>
  );
};
