/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import FormikInput from "../../../../base/components/FormikInput";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import FormikAsyncSelect from "../../../../base/components/FormikAsyncSelect";
import { useService } from "../../../../base/hooks/useService";
import EmployeesService from "../../../../services/EmployeesService";
import { listToOptions } from "../../helpers/listToOptions";
import {
  MAX_TASK_DESCRIPTION_LENGTH,
  MAX_TASK_TITLE_LENGTH,
} from "../../../../validation/lengthConstants";
import StorageService from "../../../../services/StorageService";
import { KEY_USER } from "../../../../base/constants/storage";
import { useField } from "formik";

const TaskForm = ({ defaultValueSelect, isCreateMode }) => {
  const [currentUserSelectOption, setCurrentUserSelectOption] = useState();

  const [, , { setValue, setTouched }] = useField("assigneeId");
  /**
   * @type {EmployeesService}
   */
  const employeeService = useService(EmployeesService);
  /**
   * type {StorageService}
   */
  const storage = useService(StorageService);

  const { id, firstName, lastName } = storage.get(KEY_USER, {});

  const currentUser = { id, firstName, lastName };

  const getEmployeeOptions = useCallback(
    () =>
      employeeService
        .getEmployees({})
        .then((data) => listToOptions(data, currentUser))
        .catch((e) => Bugsnag.notify(e)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    employeeService
      .getEmployees({})
      .then(({ data }) => {
        if (!data?.length && isCreateMode) {
          setValue(id);
          setTouched(true);
          setCurrentUserSelectOption({
            value: {
              label: `${firstName} ${lastName}`,
              value: id,
            },
          });
        }
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formikAsyncSelectProps = useMemo(
    () => currentUserSelectOption,
    [currentUserSelectOption]
  );

  return (
    <>
      <FormikInput
        containerClassName="mb-3"
        name="title"
        label="Task*"
        placeholder="Enter Task"
        maxLength={MAX_TASK_TITLE_LENGTH}
      />
      <FormikTextarea
        name="description"
        label="Task description"
        placeholder="Enter task description"
        maxLength={MAX_TASK_DESCRIPTION_LENGTH}
      />

      <FormikAsyncSelect
        name="assigneeId"
        label="To*"
        placeholder="Choose employee"
        onChange={(value) => value.id}
        defaultValue={defaultValueSelect}
        onRequestAPI={getEmployeeOptions}
        isSearchable={false}
        {...formikAsyncSelectProps}
      />
    </>
  );
};

export default TaskForm;
