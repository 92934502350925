export const ACTIVE = "ACTIVE";
export const ENDED = "ENDED"; // or EBAY_ENDED;

const SHOW_ALL = "Show All";
export const SHOW_SOLD = "Show Sold";
const SHOW_ACTIVE = "Show Active";

export const IMPORT_FILTERS = {
  [SHOW_ALL]: null,
  [SHOW_SOLD]: ENDED,
  [SHOW_ACTIVE]: ACTIVE,
};

export const DROPDOWN_IMPORT_LIST = [
  SHOW_ACTIVE, SHOW_SOLD, SHOW_ALL,
];

export const INDEX_SHOW_ACTIVE = DROPDOWN_IMPORT_LIST.findIndex(item => item === SHOW_ACTIVE);

// new functionality

const SHOW_ALL_INVENTORY = "Show All";
export const ALREADY_IMPORTED_INVENTORY = "Already Imported";
const NOT_IMPORTED_INVENTORY = "Not Imported";

export const DROPDOWN_IMPORT_LIST_INVENTORY = [
  SHOW_ALL_INVENTORY, NOT_IMPORTED_INVENTORY, ALREADY_IMPORTED_INVENTORY,
];

export const IMPORT_FILTERS_INVENTORY = {
  [SHOW_ALL_INVENTORY]: null,
  [ALREADY_IMPORTED_INVENTORY]: true,
  [NOT_IMPORTED_INVENTORY]: false,
};

export const INDEX_SHOW_ACTIVE_INVENTORY = DROPDOWN_IMPORT_LIST_INVENTORY.findIndex(item => item === SHOW_ALL_INVENTORY);

