/* eslint-disable react/prop-types */
import FormikInput from "../../../../../base/components/FormikInput";
import { MAX_LENGTH_TAGS } from "../../../../../base/constants/forms";
import React, { useEffect } from "react";
import { useField } from "formik";

const FormTags = ({
  isHideLabel = false,
  description = "Separate tags with a comma `,`",
}) => {
  const [, , { setTouched, setError }] = useField({ name: "tags" });

  useEffect(() => {
    setError("Error");
    setTouched(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormikInput
        id="tags"
        name="tags"
        label="Tags"
        maxLength={MAX_LENGTH_TAGS}
        placeholder="Enter tags"
        isHideLabel={isHideLabel}
      />
      <span className="regular-caption-gray">{description}</span>
    </>
  );
};

export default FormTags;
