import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";
import { useLoading } from "../../../base/hooks/useLoading";

const defaultConfig = {
  title: "Delete draft",
  message: "Do you want to delete the draft?",
  btnAccept: "Yes",
  btnClose: "No",
};

export const useDeleteDraft = (
  afterDelete,
  { title, message, btnAccept, btnClose } = defaultConfig
) => {
  const [draftToDelete, updateDraftToDelete] = useState(null);
  const [deleting, { registerPromise }] = useLoading();
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  const onDelete = useCallback((id) => {
    updateDraftToDelete(id);
  }, []);

  const close = useCallback(() => {
    updateDraftToDelete(null);
  }, []);

  const deleteDraft = useCallback(() => {
    const id = draftToDelete;

    registerPromise(
      draftService
        .deleteDraft(id)
        .then(() => {
          afterDelete(id);
        })
        .catch((e) => Bugsnag.notify(e))
    );

    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftToDelete, close, draftService, registerPromise]);

  const view = (
    <CustomModal isOpen={!!draftToDelete} onClose={close}>
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalBody>
        <p className="ui-regular">{message}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={close} className="outline-primary ">
          {btnClose}
        </CustomButton>

        <CustomButton onClick={deleteDraft} className="filled-primary ">
          {btnAccept}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return {
    view,
    deleting,
    onDelete,
  };
};
