/* eslint-disable react/prop-types */
import React, { Fragment, useMemo, useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useLocationQuery } from "../../hooks/useQueryString";

import "./index.scss";
import Icon from "../Icon";

const FilterSortingDropdown = ({
  initialOpen,
  filterName,
  filterOptions,
  defaultItem,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const { params, merge } = useLocationQuery();

  const options = [...filterOptions];

  const currentItem = useMemo(() => {
    return (
      options.find((element) => element?.value === params?.[filterName]) ||
      defaultItem
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const toggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);

  const onFilterClick = (value) => {
    merge(filterName, value);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="filter-sorting-dropdown-wrapper" tag="button">
        {currentItem?.name || defaultItem.name}
        <Icon icon="bottomArrow" className="filter-sorting-dropdown-icon" />
      </DropdownToggle>
      <DropdownMenu persist className="filter-sorting-dropdown-menu__menu">
        {options?.map(({ name, value }) => (
          <Fragment key={value}>
            <DropdownItem
              className="filter-sorting-dropdown-item-wrapper"
              disabled={currentItem?.value === value}
              onClick={() => onFilterClick(value)}
            >
              <div>{name}</div>
            </DropdownItem>
          </Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterSortingDropdown;
